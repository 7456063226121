import React from 'react'
import { Typography } from '@mui/material'

import { OldMan } from 'assets/picture'
import * as SVG from 'assets/svg'
import { urls } from 'settings'

interface IconComponentProps {
  icon: React.ReactNode
  title: string
}

function IconComponent({ icon, title }: IconComponentProps) {
  return (
    <div className="flex flex-col items-center">
      <div className="cursor-pointer mb-[20px] laptop:mb-[30px]">{icon}</div>
      <Typography variant="subtitle2" className="text-white-main tetx-center">
        {title}
      </Typography>
    </div>
  )
}

function Comunity() {
  return (
    <div
      id="comunity"
      className="text-center text-white-main px-[52px] pt-[64px] pb-[119px] relative flex flex-col justify-center"
    >
      <div className="z-10 flex flex-col items-center">
        <div className="mb-[30px]">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            ชุมชนใกล้หมอ
          </Typography>
        </div>
        <div className="flex items-center justify-center flex-wrap gap-[5px]">
          <Typography variant="subtitle2">
            ร่วมพูดคุยปรึกษาปัญหาสุขภาพด้านต่างๆกับเพื่อนๆในชุมชนง่ายๆ ผ่าน
          </Typography>
          <a
            href={urls.LINE_GENERAL_CONSULT}
            target="_blank"
            className="w-[30px] h-[30px]"
          >
            <SVG.LineIcon2SVG className="cursor-pointer w-full h-full" />
          </a>
          <Typography variant="subtitle2">
            ที่มีคุณหมอคอยดูแลอย่างใกล้ชิด
          </Typography>
        </div>

        <div className="flex flex-col laptop:flex-row justify-center items-center mt-[88px] gap-[45px] laptop:gap-[90px]">
          <a href={urls.LINE_DIABETES_CONSULT} target="_blank">
            <IconComponent
              icon={<SVG.DiabetesSVG />}
              title="เบาหวาน ไขมัน ความดัน"
            />
          </a>
          <a href={urls.LINE_BONE_CONSULT} target="_blank">
            <IconComponent
              icon={<SVG.MuscleSVG />}
              title="กระดูกและกล้ามเนื้อ"
            />
          </a>
          <a href={urls.LINE_GENERAL_CONSULT} target="_blank">
            <IconComponent
              icon={<SVG.CommonDiseaseSVG />}
              title="โรคทั่วไป - ใช้ยา"
            />
          </a>
        </div>
      </div>
      <div
        className="h-full w-full top-0 left-0 absolute bg-cover brightness-[.3] z-0"
        style={{
          backgroundImage: `url(${OldMan})`,
        }}
      />
    </div>
  )
}

export default Comunity

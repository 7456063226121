import React from 'react'
import { Skeleton } from '@mui/material'

export default function UserProfile() {
  return (
    <div>
      <div className="flex justify-center mb-[36px]">
        <Skeleton variant="circular" width={150} height={150} />
      </div>

      <div>
        {Array(4)
          .fill(0)
          .map((_, index: number) => (
            <div
              key={`person-input-skeleton-${index}`}
              className="flex gap-[10px]"
            >
              <Skeleton variant="text" width={100} height={35} />
              <Skeleton variant="text" width="100%" height={35} />
            </div>
          ))}
      </div>

      <hr className="border-t-[1px] border-dashed border-gray-dark mt-[30px] mb-[42px]" />

      <div>
        <Skeleton variant="text" width={150} height={45} />
        {Array(2)
          .fill(0)
          .map((_, index: number) => (
            <div
              key={`person-input-skeleton-${index}`}
              className="flex gap-[10px]"
            >
              <Skeleton variant="text" width={100} height={35} />
              <Skeleton variant="text" width="100%" height={35} />
            </div>
          ))}
      </div>
    </div>
  )
}

import React from 'react'
import { Modal } from '@mui/material'
import { Icon } from '@iconify/react'

interface ModalProps {
  open: boolean
  onClose: Function
  children: React.ReactChild | React.ReactChild[]
}

function ModalFormV2({ open, onClose, children }: ModalProps) {
  return (
    <Modal open={open} onClose={() => onClose} style={{ overflowY: 'auto' }}>
      <div
        style={{ transform: 'translate(-50%, -50%)' }}
        className="absolute top-1/2 left-1/2 z-[1400]"
      >
        <div
          className="absolute right-[10%] top-[-1%]"
          style={{
            padding: '2px',
            backgroundColor: '#0D5CDD',
            borderRadius: '15px',
            borderWidth: '1px',
          }}
        >
          <Icon
            icon="fluent-mdl2:status-circle-error-x"
            className="text-[25px] cursor-pointer"
            style={{ color: '#FFF' }}
            onClick={() => onClose()}
          />
        </div>

        {children}
      </div>
    </Modal>
  )
}

export default ModalFormV2

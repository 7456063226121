import React from 'react'

import { ElementComponent } from 'components'
import { RegisterType } from 'types'

export default function PasswordForm({
  form,
  isLoading,
}: RegisterType.FormProps) {
  return (
    <div>
      <p className="text-[18px] font-bold mb-[25px]">ข้อมูลเพิ่มเติม</p>
      <div className="flex flex-col gap-[13px]">
        <ElementComponent.PasswordBar
          label="รหัสผ่าน"
          name="password"
          isLoading={isLoading}
          height="50px"
          placeholder="รหัสผ่าน"
          value={form.values.password}
          errorText={form.errors.password}
          error={form.touched.password && Boolean(form.errors.password)}
          onChange={(text: string) => form.setFieldValue('password', text)}
        />

        <p className="mt-[10px]">รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัว</p>

        <ElementComponent.PasswordBar
          label="ยืนยันรหัสผ่าน"
          name="confirmPassword"
          isLoading={isLoading}
          height="50px"
          placeholder="ยืนยันรหัสผ่าน"
          value={form.values.confirmPassword}
          errorText={form.errors.confirmPassword}
          error={
            form.touched.confirmPassword && Boolean(form.errors.confirmPassword)
          }
          onChange={(text: string) =>
            form.setFieldValue('confirmPassword', text)
          }
        />
      </div>
    </div>
  )
}

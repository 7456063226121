import React from 'react'
import { Typography, Stack } from '@mui/material'
import * as Pics from 'assets/picture'
import { ElementComponent } from 'components'
import { useResponsive } from 'hooks'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
const supportList: { imgName: string }[] = [
  {
    imgName: Pics.Supporter1,
  },
  {
    imgName: Pics.Supporter2,
  },
  {
    imgName: Pics.Supporter3,
  },
  {
    imgName: Pics.Supporter4,
  },
  {
    imgName: Pics.Supporter5,
  },
  {
    imgName: Pics.Supporter6,
  },
  {
    imgName: Pics.Supporter7,
  },
  {
    imgName: Pics.Supporter8,
  },
  {
    imgName: Pics.Supporter9,
  },
  {
    imgName: Pics.Supporter10,
  },
  {
    imgName: Pics.Supporter11,
  },
  {
    imgName: Pics.Supporter12,
  },
]

const preFooterList: { imgName: string }[] = [
  {
    imgName: Pics.CustomerReview,
  },
  {
    imgName: Pics.PreFooter,
  },
]
function Supporter() {
  const { isLaptop } = useResponsive()
  return (
    <div className="text-center h-auto  mb-[-20px] bg-white-main">
      <div className="flex justify-center p-[20px]">
        <div className="w-full max-w-[700px] laptop:max-w-[900px]">
          <Typography variant="subtitle1">
            <p className="font-bold mb-[20px]" style={{ color: '#0D5CDD' }}>
              Supporter
            </p>
          </Typography>
        </div>
      </div>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px]   justify-center items-center"
      >
        {isLaptop ? (
          supportList.map((item: { imgName: string }) => (
            <div className="w-[50%] laptop:w-[6%]  bg-cover">
              <ElementComponent.Image
                fullImage={true}
                src={item.imgName}
                className=" w-full h-full cursor-default"
              />
            </div>
          ))
        ) : (
          <Swiper
            className="w-[350px] laptop:w-[450px]  bg-cover"
            slidesPerView={4}
            spaceBetween={20}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Navigation, Pagination]}
          >
            {supportList.map((item: { imgName: string }) => (
              <SwiperSlide>
                <ElementComponent.Image
                  fullImage={false}
                  src={item.imgName}
                  className=" w-30 h-30 cursor-default"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Stack>

      <Stack
        direction={{ mobile: 'column', laptop: 'column' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px]  justify-center items-center"
      >
        {preFooterList.map((item: { imgName: string }) => (
          <div className="w-[100%] laptop:w-[100%]  bg-cover">
            <ElementComponent.Image
              fullImage={true}
              src={item.imgName}
              disableClick
              className="rounded-[15px] w-full h-full cursor-move"
            />
          </div>
        ))}
      </Stack>
    </div>
  )
}

export default Supporter

import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import * as yup from 'yup'

import { SelectBar } from 'components/element'
import { date as Date } from 'utils'

import 'dayjs/locale/th' // import locale
import 'dayjs/locale/en' // import locale
dayjs.extend(localizedFormat) // use plugin
dayjs.extend(buddhistEra)
dayjs.locale('th')

interface DatePickerProps {
  isLoading?: boolean
  dateValue: Date | null
  label?: InputLabel
  name?: string
  isSubmitting: boolean
  height?: string
  yearLength?: number
  error?: boolean
  errorText?: string
  onChange: Function
}

type InputLabel = {
  day: string
  month: string
  year: string
}

type FromInterface = {
  day: number
  month: number
  year: number
}

function DatePicker({
  isLoading = false,
  dateValue,
  yearLength = 100,
  name = 'date-picker',
  isSubmitting,
  height,
  label,
  error = false,
  errorText,
  onChange,
}: DatePickerProps) {
  const [day, setDay] = useState<number | null>(null)
  const [month, setMonth] = useState<number | null>(null)
  const [year, setYear] = useState<number | null>(null)

  const [click, setClicked] = useState<boolean>(false)

  const validationSchema = yup.object({
    day: yup.number(),
    month: yup.number(),
    year: yup.number(),
  })

  const initialValues = (): FromInterface => {
    if (dateValue) {
      return {
        day: day,
        month: month,
        year: year,
      }
    } else {
      return {
        day: null,
        month: null,
        year: null,
      }
    }
  }

  useEffect(() => {
    if (dateValue) {
      setDay(dayjs(dateValue).date())
      setMonth(dayjs(dateValue).month())
      setYear(dayjs(dateValue).year())
    }
  }, [dateValue])

  useEffect(() => {
    if (error && isSubmitting) {
      const element = document.getElementById(`error-${name}`)
      if (element) {
        element.scrollIntoView({ block: 'center' })
        element.focus({ preventScroll: true })
      }
    }
  }, [error, isSubmitting])

  const onChangeValue = (type: string, value) => {
    const time = dayjs().format('HH:mm:ss')

    if (dateValue) {
      if (type === 'year') {
        onChange(dayjs(`${value}-${month + 1}-${day} ${time}`).toDate())
      }
      if (type === 'month') {
        onChange(dayjs(`${year}-${value + 1}-${1} ${time}`).toDate())
      }
      if (type === 'day') {
        onChange(dayjs(`${year}-${month + 1}-${value} ${time}`).toDate())
      }
    }

    if (!dateValue) {
      if (type === 'day') {
        onChange(dayjs(`${year}-${month + 1}-${value} ${time}`).toDate())
      }
    }
  }

  const handleMonthList = (): SelectBar.OptionSelectType[] => {
    if (!year) return [{ key: '', value: '' }]

    const monthList = Date.months.filter((item) => {
      return dayjs(dayjs()).isAfter(
        dayjs(
          `${year}-${item.key}-${dayjs(
            `${year}-${item.key}-${1}`,
          ).daysInMonth()}`,
        ),
      )
    })

    return monthList.map((item) => ({
      key: String(item.key),
      value: item.value,
    }))
  }

  const handleDayList = (): SelectBar.OptionSelectType[] => {
    if (!year || month === null) return [{ key: '', value: '' }]

    const dayList = Array.from(
      Array(
        dayjs()
          .month(
            Date.months.filter((monthItem) => monthItem.key === month)[0]?.key,
          )
          .daysInMonth(),
      ),
      (_, i) => String(i + 1),
    )

    const dayOption = dayList.filter((item) => {
      return dayjs(dayjs()).isAfter(dayjs(`${year}-${month + 1}-${item}`))
    })

    return dayOption.map((day: string) => ({
      key: day,
      value: day,
    }))
  }

  return (
    <div className={year !== null || click ? '' : '-mt-[25px]'}>
      <Formik
        initialValues={initialValues()}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {(formik) => (
          <div>
            <Grid
              container
              justifyContent="end"
              alignItems="end"
              spacing="8px"
              sx={{ marginTop: '20px' }}
            >
              <Grid item mobile={4} desktop={3}>
                <SelectBar.SelectBar
                  isLoading={isLoading}
                  focusSpacing={false}
                  name="year"
                  label={label?.year || 'ปี'}
                  placeholder={label?.year || 'ปี'}
                  width="w-full"
                  height={height ? `h-[${height}]` : undefined}
                  data={formik.values.year || ''}
                  option={Array.from({ length: yearLength }, (_, i) => ({
                    key: (Number(dayjs().format('YYYY')) - i).toString(),
                    value: (Number(dayjs().format('BBBB')) - i).toString(),
                  }))}
                  error={error}
                  onClick={() => setClicked(true)}
                  onBlur={() => setClicked(false)}
                  onChange={(value: string) => {
                    setYear(Number(value))
                    onChangeValue('year', Number(value))
                    formik.setFieldValue('year', Number(value))
                  }}
                />
              </Grid>
              <Grid item mobile={5} desktop={6}>
                <SelectBar.SelectBar
                  focusSpacing={false}
                  isLoading={isLoading}
                  name="month"
                  label={label?.month || 'เดือน'}
                  disable={year === null}
                  placeholder={label?.month || 'เดือน'}
                  width="w-full"
                  height={height ? `h-[${height}]` : undefined}
                  data={formik.values.month ?? ''}
                  option={handleMonthList()}
                  error={error}
                  onClick={() => setClicked(true)}
                  onBlur={() => setClicked(false)}
                  onChange={(value: string) => {
                    setMonth(Number(value))
                    formik.setFieldValue('month', Number(value))
                    onChangeValue('month', Number(value))
                  }}
                />
              </Grid>
              <Grid item mobile={3} desktop={3}>
                <SelectBar.SelectBar
                  focusSpacing={false}
                  isLoading={isLoading}
                  name="day"
                  label={label?.day || 'วัน'}
                  disable={month === null}
                  placeholder={label?.day || 'วัน'}
                  width="w-full"
                  height={height ? `h-[${height}]` : undefined}
                  data={formik.values.day || ''}
                  option={handleDayList()}
                  error={error}
                  onClick={() => setClicked(true)}
                  onBlur={() => setClicked(false)}
                  onChange={(value: string) => {
                    setDay(Number(value))
                    formik.setFieldValue('day', Number(value))
                    onChangeValue('day', Number(value))
                  }}
                />
              </Grid>
            </Grid>
            {error && (
              <p
                id={`error-${name}`}
                className="text-[14px] text-red-main mt-[6px] text-start"
              >
                {errorText}
              </p>
            )}
          </div>
        )}
      </Formik>
    </div>
  )
}

export default DatePicker

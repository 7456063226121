import React from 'react'
import { Icon } from '@iconify/react'

import { ModalForm } from 'components/element'

interface ResetPassModalProps {
  open: boolean
  onClose: Function
}

function ResetPassModal({ open, onClose }: ResetPassModalProps) {
  return (
    <ModalForm open={open} onClose={() => onClose()}>
      <div className="flex flex-col items-center text-[18px] py-[52px] w-screen laptop:max-w-[600px] max-w-[450px]">
        <Icon
          icon="material-symbols:check-circle-rounded"
          className="w-[70px] h-[70px] mb-[20px] text-[#3BB54A]"
        />
        <p className="mb-[15px] text-[20px] font-[600]">
          เปลี่ยนรหัสผ่านสำเร็จ
        </p>
      </div>
    </ModalForm>
  )
}

export default ResetPassModal

import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { useMutation, useQuery } from 'urql'
import * as Pics from 'assets/picture'
import { AlertComponent, ElementComponent } from 'components'
import Calendar from 'react-calendar'
import { Grid } from '@mui/material'
import 'react-calendar/dist/Calendar.css'
import { OverviewMutation, OverviewQuery } from 'services/graphql/overview'
import { useState } from 'react'
import { ConsultType } from 'types'
import { Formik, Form } from 'formik'
import dayjs from 'dayjs'
import { general } from 'utils'
interface PlanModalProps {
  open: boolean
  onClose: Function
}

interface IConsultSubmit {
  data: {
    placeName: string
    dateConsult: string
    time: string
    ctId: string
    textConsult: string
    uid: string
    temp: string
    bp_sys: string
    bp_dia: string
    hr: string
    rr: string
    doctorUid: string
    isDiagnose: boolean
    isPayedDrug: boolean
    typeConsult: string
  }
}
type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]
function PlanUI() {
  const [timesMorningList, setTimesMorningList] = useState([
    {
      title: '8.00-8.30',
      active: false,
    },
    {
      title: '8.30-9.00',
      active: false,
    },
    {
      title: '9.00-9.30',
      active: false,
    },
    {
      title: '9.30-10.00',
      active: false,
    },
    {
      title: '10.00-10.30',
      active: false,
    },
    {
      title: '10.30-11.00',
      active: false,
    },
    {
      title: '11.00-11.30',
      active: false,
    },
    {
      title: '11.30-12.00',
      active: false,
    },
  ])
  const [timesEveningList, setTimesEveningList] = useState([
    {
      title: '13.00-13.30',
      active: false,
    },
    {
      title: '13.30-14.00',
      active: false,
    },
    {
      title: '14.00-14.30',
      active: false,
    },
    {
      title: '14.30-15.00',
      active: false,
    },
    {
      title: '15.00-15.30',
      active: false,
    },
    {
      title: '15.30-16.00',
      active: false,
    },
    {
      title: '16.00-16.30',
      active: false,
    },
    {
      title: '16.30-17.00',
      active: false,
    },
  ])
  const [timesNightList, setTimesNightList] = useState([
    {
      title: '18.00-18.30',
      active: false,
    },
    {
      title: '18.30-19.00',
      active: false,
    },
    {
      title: '19.00-19.30',
      active: false,
    },
    {
      title: '19.30-20.00',
      active: false,
    },
  ])
  const [consultTypeData] = useQuery(OverviewQuery.getConsultType())
  const [dateValue, onDateChange] = useState<Value>(new Date())
  const [placeName, onPlaceNameChange] = useState<string>('')
  const [time, onTimeChange] = useState<string>('')
  const [cId, onCidChange] = useState<string>('')
  const [textConsult, onTextChange] = useState<string>('')
  const initialValues: ConsultType.ConsultType = {
    placeName: placeName,
    dateConsult: dateValue.toString(),
    time: time,
    ctId: cId,
    textConsult: textConsult,
    uid: '',
  }
  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })
  const [createConsultMutation, executeMutationConsult] = useMutation(
    OverviewMutation.createConsult,
  )
  const handleInputPlaceNameChange = (event) => {
    onPlaceNameChange(event.target.value)
    //console.debug(`${event.target.name}: ${event.target.value}`)
  }
  const onTextConsultChange = (event) => {
    onTextChange(event.target.value)
  }
  function handleInputTimeChange(time) {
    let iNight = []

    timesNightList.map((item) => {
      const itemCurrent = item
      if (item.title === time) {
        itemCurrent.active = true
      } else {
        itemCurrent.active = false
      }
      iNight.push(itemCurrent)
    })
    setTimesNightList(iNight)

    let iEvening = []

    timesEveningList.map((item) => {
      const itemCurrent = item
      if (item.title === time) {
        itemCurrent.active = true
      } else {
        itemCurrent.active = false
      }
      iEvening.push(itemCurrent)
    })
    setTimesEveningList(iEvening)

    let iMorning = []
    timesMorningList.map((item) => {
      const itemCurrent = item
      if (item.title === time) {
        itemCurrent.active = true
      } else {
        itemCurrent.active = false
      }
      iMorning.push(itemCurrent)
    })
    setTimesMorningList(iMorning)

    onTimeChange(time)
  }
  const handleConsultTypeChange = (event: SelectChangeEvent) => {
    onCidChange(event.target.value)
  }
  const createConsult = async (values: ConsultType.ConsultType) => {
    let payload: IConsultSubmit = {
      data: {
        placeName: values.placeName,
        dateConsult: dayjs(values.dateConsult).format('DD/MM/YYYY'),
        time: values.time,
        ctId: values.ctId,
        textConsult: values.textConsult,
        uid: values.uid,
        temp: '-',
        bp_sys: '-',
        bp_dia: '-',
        hr: '-',
        rr: '-',
        doctorUid: '-',
        isDiagnose: false,
        isPayedDrug: false,
        typeConsult: 'online',
      },
    }
    const result = await executeMutationConsult(payload)
    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: 'กรุณาลองใหม่อีกครั้ง',
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: 'กรุณารอวิเคราะห์จากคุณหมอ',
      onClose: () => {
        general.http.goto('/overview')
      },
    })

    return
  }
  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'column' }}
      className="gap-5 bg-gray-main rounded-[15px] p-[8px] items-center"
      alignItems={'top'}
    >
      <ElementComponent.Image
        fullImage={true}
        src={Pics.HeaderPlan}
        disableClick
        className="w-full h-full cursor-default "
      />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={createConsult}
      >
        {(formik) => (
          <Form>
            <Stack
              direction={{ mobile: 'column', laptop: 'row' }}
              alignItems={'top'}
              className="gap-5 mt-9 w-[350px] laptop:w-[92%] p-3 laptop:p-[25px] border border-blue-main rounded-xl bg-white-main"
            >
              <div className="flex  flex-row -mt-9">
                <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
                  <h5 className="text-white-main mx-3.5">สถานที่ตรวจ</h5>
                </div>
              </div>
              {/* Disease attribute */}
              <div className="gap-[18px] w-[100%] laptop:w-[50%]">
                <FormControl>
                  <RadioGroup
                    row
                    name="placeName"
                    onChange={handleInputPlaceNameChange}
                    defaultValue="online"
                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label="ออนไลน์"
                    />
                    <FormControlLabel
                      value="clinic"
                      control={<Radio />}
                      label="ที่คลินิก"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Stack>
            <Calendar
              onChange={onDateChange}
              value={dateValue}
              className="bg-white-main rounded-[20px] border-blue-main mt-4"
            />

            <Stack
              direction={{ mobile: 'column', laptop: 'row' }}
              alignItems={'top'}
              className="gap-5 mt-9 w-[350px] laptop:w-[92%] p-3 laptop:p-[25px] border border-blue-main rounded-xl bg-white-main"
            >
              <div className="flex  flex-row -mt-9">
                <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
                  <h5 className="text-white-main mx-3.5">เวลา</h5>
                </div>
              </div>
              <h5 className="text-grey-main ">ช่วงเช้า</h5>
              <Grid
                container
                justifyContent="center"
                columns={{ mobile: 4.4, desktop: 4 }}
                gap="8px"
                className="mb-[16px]"
              >
                {timesMorningList.map((item: any) => (
                  <Grid item mobile={1} desktop={1}>
                    <div>
                      <ElementComponent.ButtonAppointment
                        id="submit-health-form"
                        style="w-full"
                        shape="outlined"
                        backgroundColor="#fff"
                        textColor="#898989"
                        height="28px"
                        text={item.title}
                        onAction={handleInputTimeChange}
                        width="100%"
                        active={item.active}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
              <h5 className="text-grey-main ">ช่วงบ่าย</h5>
              <Grid
                container
                justifyContent="center"
                columns={{ mobile: 4.4, desktop: 4 }}
                gap="8px"
                className="mb-[16px]"
              >
                {timesEveningList.map((item: any) => (
                  <Grid item mobile={1} desktop={1}>
                    <div>
                      <ElementComponent.ButtonAppointment
                        id="submit-health-form"
                        style="w-full"
                        shape="outlined"
                        backgroundColor="#fff"
                        textColor="#898989"
                        height="28px"
                        text={item.title}
                        onAction={handleInputTimeChange}
                        width="100%"
                        active={item.active}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
              <h5 className="text-grey-main ">ช่วงเย็น</h5>
              <Grid
                container
                justifyContent="center"
                columns={{ mobile: 4.4, desktop: 4 }}
                gap="8px"
                className="mb-[16px]"
              >
                {timesNightList.map((item: any) => (
                  <Grid item mobile={1} desktop={1}>
                    <div>
                      <ElementComponent.ButtonAppointment
                        id="submit-health-form"
                        style="w-full"
                        shape="outlined"
                        backgroundColor="#fff"
                        textColor="#898989"
                        height="28px"
                        text={item.title}
                        onAction={handleInputTimeChange}
                        width="100%"
                        active={item.active}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Stack>
            <Stack
              direction={{ mobile: 'column', laptop: 'row' }}
              alignItems={'top'}
              className="gap-5 mt-9 w-[350px] laptop:w-[92%] p-3 laptop:p-[25px] border border-blue-main rounded-xl bg-white-main"
            >
              <div className="flex  flex-row -mt-9">
                <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
                  <h5 className="text-white-main mx-3.5">
                    ต้องการปรึกษาเรื่องอะไร
                  </h5>
                </div>
              </div>
              <div className="gap-[18px] w-[100%] laptop:w-[50%]">
                <FormControl className="w-[100%]" size="small">
                  <Select
                    placeholder="เลือกข้อมูลที่ต้องการปรึกษา"
                    name="ctId"
                    onChange={handleConsultTypeChange}
                  >
                    {!consultTypeData.fetching &&
                      !consultTypeData.error &&
                      consultTypeData.data.getConsultType.map(
                        (item: { ctId: string; typeName: string }) => (
                          <MenuItem value={item.ctId}>{item.typeName}</MenuItem>
                        ),
                      )}
                  </Select>
                  {/*<FormHelperText>Without label</FormHelperText>*/}
                </FormControl>
              </div>
            </Stack>
            <Stack
              direction={{ mobile: 'column', laptop: 'row' }}
              alignItems={'top'}
              className="gap-5 mt-9 w-[350px] laptop:w-[92%] p-3 laptop:p-[25px] border border-blue-main rounded-xl bg-white-main"
            >
              <div className="flex  flex-row -mt-9">
                <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
                  <h5 className="text-white-main mx-3.5">
                    เล่าอาการให้หมอฟังหน่อย(ไม่บังคับ)
                  </h5>
                </div>
              </div>
              {/* Disease attribute */}
              <div className="gap-[18px] w-[100%] laptop:w-[50%]">
                <TextField
                  className="w-[100%]"
                  id="outlined-multiline-static"
                  defaultValue=""
                  multiline
                  rows={4}
                  onChange={onTextConsultChange}
                />
              </div>
            </Stack>
            <div className="items-center mt-4">
              <ElementComponent.ButtonCustom
                id="submit-health-form"
                style="w-full"
                backgroundColor="#06C755"
                //submit
                loading={createConsultMutation.fetching}
                text="ยืนยันการจองเวลา "
                width="100%"
                onAction={() => {
                  if (time === '') {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาเลือกเวลา',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else if (cId === '') {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาเลือกเรื่องที่ต้องการปรึกษา',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else {
                    formik.submitForm()
                  }
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
      {submitPopupData.type === 'success' ? (
        <AlertComponent.SuccessModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      ) : (
        <AlertComponent.ErrorModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      )}
    </Stack>
  )
}
function PlanModal({ open, onClose }: PlanModalProps) {
  return (
    <ElementComponent.ModalFormV2 open={open} onClose={() => onClose()}>
      <div className="flex flex-col items-center w-screen max-h-[600px] mt-[10px]">
        <PlanUI />
      </div>
    </ElementComponent.ModalFormV2>
  )
}

export default PlanModal

import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { NoDiseaseDataSVG } from 'assets/svg'
import { Grid } from '@mui/material'
import { useQuery } from 'urql'
import { Formik } from 'formik'
import { Icon } from '@iconify/react'

import {
  ElementComponent,
  FormComponent,
  MenuComponent,
  SkeletonComponent,
} from 'components'
import { MedicalQuery } from 'services/graphql/medicalService'
import { route } from 'settings'
import { date } from 'utils'

interface UserHealthDataPageProps {
  diagnosis?: boolean
}

interface DiseaseDataGeneral {
  disease: {
    daId: string
    thaiName: string
    medName: string
  }
  value: string | string[]
}

interface DiseaseDataSpeicific {
  disease: {
    dId: string
    thaiName: string
  }
  date: Date
}

interface DiagosisData {
  topic: string
  value: string
}

function UserHealthDataPage({ diagnosis = false }: UserHealthDataPageProps) {
  const { umdId } = useParams()

  const [userMedical, setUserMedical] = useState<any>({})
  const [diagnosisData, setDiagnosisData] = useState<DiagosisData[]>([])
  const [diseaseGeneral, setDiseaseGeneral] = useState<any[]>([])
  const [diseaseSpecific, setDiseaseSpecific] = useState<any[]>([])

  const [diseaseData] = useQuery(MedicalQuery.getDisease({}))
  const [userMedicalData] = useQuery(
    MedicalQuery.getMedicalDataById({ AND: [{ umdId: { equals: umdId } }] }),
  )

  const menuList = [
    { title: 'ข้อมูลสุขภาพ', link: route.HEALTH_PAGE_URL },
    {
      title: diagnosis ? 'ผลวินิจฉัยของข้อมูลสุขภาพ' : 'ดูรายละเอียด',
      link: '',
    },
  ]

  const handleImageValue = (value: string): string[] | null => {
    if (value.split(',').length === 0) return null
    if (value.split(',').length === 1 && value.split(',')[0] === '') return null

    return value.split(',')
  }

  const handleMapValue = (dId: string, daId?: string): string | string[] => {
    let result = null

    if (userMedical) {
      const objectData = userMedical[dId]
      objectData?.forEach((item) => {
        if (item.diseaseAttibute.daId === daId) {
          result =
            item.value || item.desciption || handleImageValue(item.imagePath)
        }
      })
    }

    return result
  }

  const handleDieaseGeneral = (datas) => {
    const result: DiseaseDataGeneral[] = []
    datas.map((data) => {
      data.diseaseAttibute.map((da) => {
        const value: string | string[] = handleMapValue(
          data.dId as string,
          da.daId as string,
        )
        result.push({
          disease: {
            daId: da.daId,
            thaiName: da.thaiName,
            medName: da.medName,
          },
          value: value,
        })
      })
    })
    return result
  }

  const handleMapDiseaseValue = (dId: string): any => {
    let result = {}

    const objectData = userMedical[dId]
    objectData.forEach((item) => {
      result[item?.diseaseAttibute.medName] = {
        type: item?.diseaseAttibute.type,
        daId: item?.diseaseAttibute.daId,
        value: item.value || item.desciption || item.imagePath.split(','),
      }
    })
    return result
  }

  const handleFormAttribute = (): any[] => {
    if (userMedical.length === 0) return []

    const result = []

    Object.keys(userMedical).forEach((key: string) => {
      const diseaseData = diseaseSpecific.filter(
        (disease) => disease.dId === key,
      )

      if (diseaseData.length) {
        result.push({
          disease: {
            dId: key,
            thaiName: diseaseData[0].thaiName,
          },
          date: userMedical[key][0].createdAt,
        })
      }
    })

    return result
  }

  const renderSpecificDiease = () => {
    const data = [...handleFormAttribute()]

    if (data.length === 0)
      return (
        <>
          <div className="flex flex-col items-center">
            <NoDiseaseDataSVG />
            <p className="mt-[30px]">ตอนนี้คุณยังไม่มีข้อมูลโรคแสดง</p>
          </div>
        </>
      )

    return data.map((item: DiseaseDataSpeicific, index: number) => (
      <Formik
        key={`form-disease-${item.disease.dId}`}
        initialValues={handleMapDiseaseValue(item.disease.dId)}
        onSubmit={() => {}}
      >
        {(formik) => (
          <div className={`mt-[${index !== 0 ? '15px' : '0px'}]`}>
            <FormComponent.HealthForm.SpecificDiseaseForm
              disabled
              data={item}
              formData={formik}
            />
          </div>
        )}
      </Formik>
    ))
  }

  useEffect(() => {
    ;(async () => {
      if (!userMedicalData.fetching && !userMedicalData.error) {
        const { getMedicalData } = await userMedicalData.data
        const groupList = await getMedicalData.userMedTrasaction.reduce(
          (groups: any, item: any) => {
            const group = groups[item.diseaseAttibute.dId] || []
            group.push(item)
            groups[item.diseaseAttibute.dId] = group
            return groups
          },
          {},
        )

        setUserMedical(groupList)

        // -> Format diagnosis data.
        setDiagnosisData([
          {
            topic: 'วันที่วินิจฉัยข้อมูล',
            value: getMedicalData.diagnosisData?.[0]?.createdAt
              ? date.convertToShowDateCommonEra(
                  getMedicalData.diagnosisData?.[0]?.createdAt,
                )
              : '-',
          },
          {
            topic: 'ผลลัพธ์การวินิจฉัย',
            value: getMedicalData.diagnosisData?.[0]?.diagnosisResult ?? '-',
          },
          {
            topic: 'คำแนะนำสำหรับคนไข้',
            value: getMedicalData.diagnosisData?.[0]?.suggestion ?? '-',
          },
        ])
      }
    })()
  }, [userMedicalData])

  useEffect(() => {
    ;(async () => {
      if (!diseaseData.fetching && !diseaseData.error) {
        const { getDiseases } = await diseaseData.data

        setDiseaseGeneral(getDiseases.filter((item) => item.type === 'GENERAL'))
        setDiseaseSpecific(
          getDiseases.filter((item) => item.type === 'SPECIFIC'),
        )
      } else {
        setDiseaseGeneral([])
        setDiseaseSpecific([])
      }
    })()
  }, [diseaseData])

  return (
    <div>
      <MenuComponent.MenuHeaderBar menuList={menuList} />

      <div className="flex flex-col laptop:flex-row laptop:justify-between mb-[18px] mt-[20px]">
        <div className="flex items-center gap-[16px] order-2 laptop:order-1">
          <Icon
            icon={
              diagnosis
                ? 'fluent:document-pill-24-filled'
                : 'mdi:drive-document'
            }
            className="text-[24px]"
          />
          <p className="text-[18px] font-bold">
            {diagnosis
              ? 'ผลวินิจฉัยของข้อมูลสุขภาพ'
              : 'ดูรายละเอียดข้อมูลสุขภาพ'}
          </p>
        </div>

        <Link
          to={route.HEALTH_PAGE_URL}
          className="mb-[30px] laptop:mb-0 order-1 laptop:order-2"
        >
          <ElementComponent.Button
            id="back-to-health-data"
            shape="outlined"
            text="กลับสู่หน้าข้อมูลสุขภาพ"
            width="100%"
            height="52px"
            buttonTextSize="16px"
          />
        </Link>
      </div>

      <hr className="my-[25px]" />

      <div className="flex flex-col gap-[25px]">
        {diagnosis && (
          <ElementComponent.Paper title="ผลวินิจฉัยของข้อมูลสุขภาพ">
            <div className="ml-[12px]">
              <Grid container rowGap="5px">
                {diagnosisData.map((item: DiagosisData) => (
                  <Grid key={`diagosis-data-${item.topic}`} container>
                    <Grid item mobile={4} laptop={3}>
                      <p className="font-bold">{item.topic}</p>
                    </Grid>
                    <Grid item>
                      <p>{item.value}</p>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
          </ElementComponent.Paper>
        )}

        <ElementComponent.Paper title="ข้อมูลทั่วไป">
          {!diseaseData.fetching ? (
            <div className="ml-[12px]">
              <Grid container rowGap="15px">
                {handleDieaseGeneral(diseaseGeneral).map((item) => {
                  if (item.disease.medName === 'Additional image') {
                    return (
                      <div
                        key={`transaction-${item.disease.daId}`}
                        className="my-[20px] w-full"
                      >
                        <p className="font-bold">{item?.disease.thaiName}</p>
                        <hr className="my-[18px]" />
                        <div className="flex w-full h-[170px] gap-[5px]">
                          {item.value &&
                            (item.value as string[]).map(
                              (image: string, index: number) => (
                                <div key={`image-addtional-${index}`}>
                                  <ElementComponent.Image
                                    className="h-full w-full object-cover bg-center rounded-[10px] border"
                                    src={image}
                                    alt=""
                                  />
                                </div>
                              ),
                            )}
                        </div>
                      </div>
                    )
                  }
                  if (item.disease.medName === 'Desciption') {
                    return (
                      <Grid
                        key={`transaction-${item.disease.daId}`}
                        container
                        rowGap="5px"
                        columnGap="10px"
                      >
                        <Grid item mobile={12} laptop={3}>
                          <p className="font-bold">{item.disease.thaiName}</p>
                        </Grid>
                        <Grid item laptop={6}>
                          <p className="break-all">
                            {item.value ? item.value : '-'}
                          </p>
                        </Grid>
                      </Grid>
                    )
                  }

                  return (
                    <Grid
                      key={`transaction-${item.disease.daId}`}
                      container
                      rowGap="5px"
                      columnGap="10px"
                    >
                      <Grid item mobile={12} laptop={3}>
                        <p className="font-bold">{item.disease.thaiName}</p>
                      </Grid>
                      <Grid item>
                        <p>{item.value ? item.value : '-'}</p>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          ) : (
            <SkeletonComponent.GeneralDataSkeleton />
          )}
        </ElementComponent.Paper>

        <ElementComponent.Paper title="ข้อมูลเฉพาะทาง">
          {!diseaseData.fetching ? (
            <div className="flex flex-col gap-[25px]">
              {renderSpecificDiease()}
            </div>
          ) : (
            <SkeletonComponent.SpecificDataSkeleton />
          )}
        </ElementComponent.Paper>
      </div>
    </div>
  )
}

export default UserHealthDataPage

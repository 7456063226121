import React from 'react'
import { Stack } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import * as Pics from 'assets/picture'
import { ElementComponent } from 'components'

const newsAraList: {
  header: string
  img: string
  title: string
  desc: string
}[] = [
  {
    header: 'อาสาสุขภาพ',
    img: Pics.News1,
    title: 'เทศบาลคูคต จ.ปทุมธานี',
    desc: 'วิทยากรรับเชิญให้ความรู้เรื่อง NCDs กับอาสาสมัครประจำหมู่บ้าน (อสม) และแนะการใช้งานDR.ASA เพื่อการ ดูแลตัวเอง',
  },
  {
    header: 'อาสาสุขภาพ',
    img: Pics.News1_2,
    title: 'เทศบาลหนองใหญ่ จ.ชลบุรี',
    desc: 'ทยากรรับเชิญให้ความรู้เรื่อง NCDs ให้กับ ประชาชนผู้ป่วยเป็นโรค เบาหวาน ความดัน ไขมัน และแนะการใช้งานื DR.ASA เพื่อการ ดูแลตัวเอง ร่วมกับ CSR ของ GPO',
  },
  {
    header: 'อาสาสุขภาพ',
    img: Pics.News1_3,
    title: 'เทศบาลทับกวาง จ.สระบุรี',
    desc: 'วิทยากรรับเชิญให้ความรู้เรื่อง NCDs ให้กับ ประชาชนผู้ป่วยเป็นโรค เบาหวาน ความดัน ไขมัน และแนะการใช้งานื DR.ASA เพื่อการ ดูแลตัวเอง ร่วมกับ CSR ของ GPO',
  },
]

const newsAwardList: {
  header: string
  img: string
  title: string
  desc: string
}[] = [
  {
    header: 'Award',
    img: Pics.News2,
    title: '88 sandbox by Thammasart',
    desc: 'ได้รับรางวัลการประกวดคัดเลือก Start up จาก มหาวิทยาลัยธรรมศาสตร์ ปี 2022',
  },
  {
    header: 'Award',
    img: Pics.News2_2,
    title: 'Start up Thailand league',
    desc: 'ผ่านเข้ารอบ 10 ทีมสุดท้ายและเงินรางวันจาก start up Thailand league',
  },
  {
    header: 'Award',
    img: Pics.News2_3,
    title: 'Health tech X by สสส',
    desc: 'ได้รับเงินทุนสนับสนุนและผ่านการคัดเลือก เข้าบ่มเพาะ start up ด้านสุขภาพ เพื่อสังคม ปี 2022-2023',
  },
]

const newsExperienceList: {
  header: string
  img: string
  title: string
  desc: string
}[] = [
  {
    header: 'Experience',
    img: Pics.News3,
    title: 'Bangkok Smart city 2022',
    desc: 'ได้เชิญไปเป็น speaker เรื่องการใช้เทคโนโลยี มาช่วยแก้ปัญหาเรื่องสาธารณสุข และจัดตั้งบูธ ในงาน smart city 2022',
  },
  {
    header: 'Experience',
    img: Pics.News3_2,
    title: 'Taiwan Smart city 2023',
    desc: 'ได้รับโอกาส ไปจัดบูธเข้าร่วมงานแลกเปลี่ยน ประสบการณ์ด้านเทคโนโลยี ในงาน smart city Taiwan 2023',
  },
  {
    header: 'Experience',
    img: Pics.News3_3,
    title: 'Next rise start up Korea 2023',
    desc: 'ได้รับเงินทุนสนับสนุนและผ่านการคัดเลือก เข้าบ่มเพาะ start up ด้านสุขภาพ เพื่อสังคม ปี 2022-2023',
  },
]

function Howto() {
  return (
    <div className="text-center h-auto  pb-[100px] bg-white-main">
      <div className="flex justify-center "></div>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px]  justify-center items-center"
      >
        <div id="howto" className="w-[80%] laptop:w-[40%]  bg-cover">
          <ElementComponent.Image
            fullImage={true}
            src={Pics.ImgHowToVideo}
            disableClick
            className="rounded-[15px] w-full h-full cursor-default"
          />
        </div>
        <div className="w-[80%] laptop:w-[40%]  bg-cover">
          <ElementComponent.Image
            fullImage={true}
            disableClick
            src={Pics.ImgHowToRight}
            className="rounded-[15px] w-full h-full cursor-default"
          />
        </div>
      </Stack>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px]  justify-center items-center"
      >
        <Swiper
          className="w-[350px] laptop:w-[450px]  bg-cover"
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Navigation, Pagination]}
        >
          {newsAraList.map(
            (item: {
              header: string
              img: string
              title: string
              desc: string
            }) => (
              <SwiperSlide>
                <Stack
                  direction={{ mobile: 'column', laptop: 'column' }}
                  className="justify-center items-center"
                  style={{
                    height: '500px',
                    backgroundImage:
                      'linear-gradient(0deg,rgba(227, 64, 69, .2), rgba(128, 116, 135, .2), rgba(0, 178, 234, .2))',
                    borderRadius: '35px',
                    padding: '35px',
                  }}
                >
                  <p
                    className="font-bold mt-[0px]"
                    style={{ color: '#0D5CDD', fontSize: 20 }}
                  >
                    {item.header}
                  </p>
                  <ElementComponent.Image
                    fullImage={true}
                    src={item.img}
                    disableClick
                    className="rounded-[35px] mt-[20px] cursor-move w-full"
                  />
                  <p
                    className="font-bold mt-[20px]"
                    style={{ color: '#0D5CDD' }}
                  >
                    {item.title}
                  </p>
                  <p style={{ textAlign: 'start', marginTop: '8px' }}>
                    {item.desc}
                  </p>
                </Stack>
              </SwiperSlide>
            ),
          )}
        </Swiper>

        <Swiper
          className="w-[350px] laptop:w-[450px]  bg-cover"
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Navigation, Pagination]}
        >
          {newsAwardList.map(
            (item: {
              header: string
              img: string
              title: string
              desc: string
            }) => (
              <SwiperSlide>
                <Stack
                  direction={{ mobile: 'column', laptop: 'column' }}
                  className="justify-center items-center"
                  style={{
                    height: '500px',
                    backgroundImage:
                      'linear-gradient(0deg,rgba(227, 64, 69, .2), rgba(128, 116, 135, .2), rgba(0, 178, 234, .2))',
                    borderRadius: '35px',
                    padding: '35px',
                  }}
                >
                  <p
                    className="font-bold mt-[0px]"
                    style={{ color: '#0D5CDD', fontSize: 20 }}
                  >
                    {item.header}
                  </p>
                  <ElementComponent.Image
                    fullImage={true}
                    src={item.img}
                    disableClick
                    className="rounded-[35px] mt-[20px] cursor-move w-full"
                  />
                  <p
                    className="font-bold mt-[20px]"
                    style={{ color: '#0D5CDD' }}
                  >
                    {item.title}
                  </p>
                  <p style={{ textAlign: 'start', marginTop: '8px' }}>
                    {item.desc}
                  </p>
                </Stack>
              </SwiperSlide>
            ),
          )}
        </Swiper>
        <Swiper
          className="w-[350px] laptop:w-[450px]  bg-cover"
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Navigation, Pagination]}
        >
          {newsExperienceList.map(
            (item: {
              header: string
              img: string
              title: string
              desc: string
            }) => (
              <SwiperSlide>
                <Stack
                  direction={{ mobile: 'column', laptop: 'column' }}
                  className="justify-center items-center"
                  style={{
                    height: '500px',
                    backgroundImage:
                      'linear-gradient(0deg,rgba(227, 64, 69, .2), rgba(128, 116, 135, .2), rgba(0, 178, 234, .2))',
                    borderRadius: '35px',
                    padding: '35px',
                  }}
                >
                  <p
                    className="font-bold mt-[0px]"
                    style={{ color: '#0D5CDD', fontSize: 20 }}
                  >
                    {item.header}
                  </p>
                  <ElementComponent.Image
                    fullImage={true}
                    src={item.img}
                    disableClick
                    className="rounded-[35px] mt-[20px] cursor-move w-full"
                  />
                  <p
                    className="font-bold mt-[20px]"
                    style={{ color: '#0D5CDD' }}
                  >
                    {item.title}
                  </p>
                  <p style={{ textAlign: 'start', marginTop: '8px' }}>
                    {item.desc}
                  </p>
                </Stack>
              </SwiperSlide>
            ),
          )}
        </Swiper>
      </Stack>
    </div>
  )
}

export default Howto

import { Stack } from '@mui/material'
import { ChartCompoent, ElementComponent } from 'components'
interface BmiModalProps {
  open: boolean
  onClose: Function
  weightTrasactionData
  heightTrasactionData
}
interface DefaultProps {
  weightTrasactionData
  heightTrasactionData
}
function HealthCard({
  weightTrasactionData,
  heightTrasactionData,
}: DefaultProps) {
  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'row' }}
      alignItems={'top'}
      className="gap-5 mt-9 w-full laptop:w-[92%] p-3 laptop:p-[25px] border border-blue-main rounded-xl bg-white-main"
    >
      <div className="flex justify-center flex-row -mt-9">
        <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
          <h5 className="text-white-main mx-3.5">ประวัติ BMI</h5>
        </div>
      </div>
      {/* Disease attribute */}
      <div className="gap-[18px] w-[100%] laptop:w-[50%]">
        <ChartCompoent.BarChartBmi
          weightTrasactionData={weightTrasactionData}
          heightTrasactionData={heightTrasactionData}
        />
      </div>
    </Stack>
  )
}

function BmiHistoryModal({
  open,
  onClose,
  weightTrasactionData,
  heightTrasactionData,
}: BmiModalProps) {
  return (
    <ElementComponent.ModalFormV2 open={open} onClose={() => onClose()}>
      <div className="flex flex-col items-center w-screen max-h-[600px] -mt-[30px]">
        <HealthCard
          weightTrasactionData={weightTrasactionData}
          heightTrasactionData={heightTrasactionData}
        />
      </div>
    </ElementComponent.ModalFormV2>
  )
}

export default BmiHistoryModal

export enum ProfileOption {
  PROFILE = 'PROFILE',
  RESETPASSWORD = 'RESETPASSWORD',
}

export type ResetPasswordDataType = {
  password: string
  newPassword: string
  confirmPassword: string
}

export type UserInfoType = {
  image: string
  prefix: string
  firstname: string
  lastname: string
  gender: string
  birthdate: Date | null
  phone: string
  district: string
  province: string
}

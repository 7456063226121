/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@mui/material'
import * as Pics from 'assets/picture'
import * as SVG from 'assets/svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { useState } from 'react'
import { AlertComponent, ElementComponent, ModalComponent } from 'components'
import { MedicalMutate } from 'services/graphql/medicalService'
import { useMutation } from 'urql'
import { FoodType } from 'types'
import { general } from 'utils'
import { Form, Formik } from 'formik'

interface IFoodSubmit {
  data: {
    foodMorningData: string
    foodLunchData: string
    foodDinnerData: string
  }
}

function Food() {
  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })
  const [createFoodMutation, executeMutationFood] = useMutation(
    MedicalMutate.creatFoodData,
  )
  const [foodMorning, setFoodMorning] = useState<any>([
    {
      index: 0,
      title: '',
      kcal: 0,
      imgName: Pics.ImgAddFood,
    },
    {
      index: 1,
      title: '',
      kcal: 0,
      imgName: Pics.ImgAddFood,
    },
    {
      index: 2,
      title: '',
      kcal: 0,
      imgName: Pics.ImgAddFood,
    },
  ])

  const [foodLunch, setFoodLunch] = useState<any>([
    {
      index: 0,
      title: '',
      kcal: 0,
      imgName: Pics.ImgAddFood,
    },
    {
      index: 1,
      title: '',
      kcal: 0,
      imgName: Pics.ImgAddFood,
    },
    {
      index: 2,
      title: '',
      kcal: 0,
      imgName: Pics.ImgAddFood,
    },
  ])

  const [foodDinner, setFoodDinner] = useState<any>([
    {
      index: 0,
      title: '',
      kcal: 0,
      imgName: Pics.ImgAddFood,
    },
    {
      index: 1,
      title: '',
      kcal: 0,
      imgName: Pics.ImgAddFood,
    },
    {
      index: 2,
      title: '',
      kcal: 0,
      imgName: Pics.ImgAddFood,
    },
  ])
  const [isAdd, setIsAdd] = useState<boolean>(false)
  const [time, setTime] = useState<string>('')
  const [timeIndex, setTimeIndex] = useState<number>(0)
  const [kcalUsed, setKcalUsed] = useState<number>(0)
  const foodList: {
    index: number
    title: string
    kcal: number
    imgName: string
  }[] = [
    {
      index: 0,
      title: 'ข้าวไข่เจียว',
      kcal: 310,
      imgName: Pics.SampleFood,
    },
    {
      index: 1,
      title: 'นมเย็น',
      kcal: 425,
      imgName: Pics.drink_3,
    },
    {
      index: 2,
      title: 'เครปเย็น',
      kcal: 450,
      imgName: Pics.des_2,
    },
    {
      index: 3,
      title: 'ข้าวไข่ข้น',
      kcal: 300,
      imgName: Pics.food_2,
    },
    {
      index: 4,
      title: 'ข้าวต้มกุ้ง',
      kcal: 203,
      imgName: Pics.food_3,
    },
  ]
  const initialValues: FoodType.FoodType = {
    foodMorningData: '[{}]',
    foodLunchData: '[{}]',
    foodDinnerData: '[{}]',
  }
  const createFood = async () => {
    let payload: IFoodSubmit = {
      data: {
        foodMorningData: JSON.stringify(foodMorning),
        foodLunchData: JSON.stringify(foodLunch),
        foodDinnerData: JSON.stringify(foodDinner),
      },
    }
    const result = await executeMutationFood(payload)
    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: 'กรุณาลองใหม่อีกครั้ง : ' + result.error.message,
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: 'อาหารของคุณถูกบันทึกเข้าระบบเรียบร้อยแล้ว',
      onClose: () => {
        general.http.goto('/health-data/recommend')
      },
    })

    return
  }
  return (
    <>
      <Stack
        direction={{ mobile: 'row', laptop: 'row' }}
        justifyContent={'space-around'}
      >
        <div
          className="mt-[16px] text-center "
          style={{
            padding: '2px 12px',
            backgroundColor: '#0D5CDD',
            borderRadius: '15px',
            borderWidth: '1px',
          }}
        >
          <h5 style={{ color: '#FFF' }}>แคลอรี่ต่อวันที่แนะนำ</h5>
        </div>
        <div
          className="mt-[16px] text-center ml-[16px]"
          style={{
            padding: '2px 12px',
            borderRadius: '15px',
            borderColor: '#aaa',
            borderWidth: '1px',
          }}
        >
          <b style={{ color: '#000' }}>1,850 </b>kcal
        </div>
      </Stack>
      <div
        style={{ color: '#aaa' }}
        className="text-[14px] mt-[16px]  text-center"
      >
        คำนวณโดยใช้สูตรคำนวณสากลเเละได้มาตรฐานทางการเเพทย์
      </div>
      <Stack
        direction={{ mobile: 'row', laptop: 'row' }}
        className="w-[70%] items-center mt-2"
      >
        <SVG.ImgHdrFood className="w-[48px] h-[48px] cursor-default z-[1]" />
        <div
          className=" text-center ml-[-18px]"
          style={{
            padding: '2px 20px',
            backgroundColor: '#0D5CDD',
            borderRadius: '15px',
            borderWidth: '1px',
            height: '32px',
          }}
        >
          <h5 style={{ color: '#FFF' }}>อาหารที่แนะนำสำหรับคุณ</h5>
        </div>
      </Stack>
      <div className="max-w-[350px]">
        <Swiper
          slidesPerView={3}
          spaceBetween={1}
          observer={true}
          observeParents={true}
          navigation={true}
          modules={[Navigation, Pagination]}
          pagination={{
            dynamicBullets: true,
          }}
        >
          {foodList.map(
            (item: {
              index: number
              title: string
              kcal: number
              imgName: string
            }) => (
              <SwiperSlide className="p-[8px]">
                <img
                  src={item.imgName}
                  className="h-[80px] w-[100%]"
                  style={{ objectFit: 'cover', borderRadius: '15px' }}
                />
                <p className="text-center mt-1">{item.title}</p>
                <p className="text-center" style={{ color: '#aaa' }}>
                  {item.kcal} kcal
                </p>
              </SwiperSlide>
            ),
          )}
        </Swiper>
      </div>
      <div
        className="mt-[16px] text-center w-[50%]"
        style={{
          padding: '2px 12px',
          backgroundColor: '#0D5CDD',
          borderRadius: '15px',
          borderWidth: '1px',
        }}
      >
        <h5 style={{ color: '#FFF' }}>อาหารที่ทานวันนี้</h5>
      </div>
      <>
        <Stack
          direction={{ mobile: 'row', laptop: 'row' }}
          justifySelf={'left'}
        >
          <div
            className="mt-[16px] text-center"
            style={{
              padding: '2px 12px',
              borderColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
            }}
          >
            <h5 style={{ color: '#000' }}>มื้อเช้า</h5>
          </div>
        </Stack>
        <div className="w-[350px]">
          <Swiper
            slidesPerView={3}
            spaceBetween={1}
            observer={true}
            observeParents={true}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              dynamicBullets: true,
            }}
          >
            {foodMorning.map(
              (item: {
                index: number
                title: string
                kcal: number
                imgName: string
              }) => (
                <SwiperSlide
                  className="p-[8px]"
                  onClick={() => {
                    setTime('เช้า')
                    setTimeIndex(item.index)
                    setIsAdd(true)
                  }}
                >
                  <img
                    src={item.imgName}
                    className="h-[89px] w-[100%]"
                    style={{ objectFit: 'cover', borderRadius: '15px' }}
                  />
                  <p className="text-center mt-1">{item.title}</p>
                  <p className="text-center" style={{ color: '#aaa' }}>
                    {item.kcal} kcal
                  </p>
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </div>
      </>
      <>
        <Stack
          direction={{ mobile: 'row', laptop: 'row' }}
          justifySelf={'left'}
        >
          <div
            className="mt-[16px] text-center"
            style={{
              padding: '2px 12px',
              borderColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
            }}
          >
            <h5 style={{ color: '#000' }}>มื้อกลางวัน</h5>
          </div>
        </Stack>
        <div className="w-[350px]">
          <Swiper
            slidesPerView={3}
            spaceBetween={1}
            observer={true}
            observeParents={true}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              dynamicBullets: true,
            }}
          >
            {foodLunch.map(
              (item: {
                index: number
                title: string
                kcal: number
                imgName: string
              }) => (
                <SwiperSlide
                  className="p-[8px]"
                  onClick={() => {
                    setTime('กลางวัน')
                    setTimeIndex(item.index)
                    setIsAdd(true)
                  }}
                >
                  <img
                    src={item.imgName}
                    className="h-[89px] w-[100%]"
                    style={{ objectFit: 'cover', borderRadius: '15px' }}
                  />
                  <p className="text-center mt-1">{item.title}</p>
                  <p className="text-center" style={{ color: '#aaa' }}>
                    {item.kcal} kcal
                  </p>
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </div>
      </>
      <>
        <Stack
          direction={{ mobile: 'row', laptop: 'row' }}
          justifySelf={'left'}
        >
          <div
            className="mt-[16px] text-center"
            style={{
              padding: '2px 12px',
              borderColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
            }}
          >
            <h5 style={{ color: '#000' }}>มื้อเย็น</h5>
          </div>
        </Stack>
        <div className="w-[350px]">
          <Swiper
            slidesPerView={3}
            spaceBetween={1}
            observer={true}
            observeParents={true}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              dynamicBullets: true,
            }}
          >
            {foodDinner.map(
              (item: {
                index: number
                title: string
                kcal: number
                imgName: string
              }) => (
                <SwiperSlide
                  className="p-[8px]"
                  onClick={() => {
                    setTime('เย็น')
                    setTimeIndex(item.index)
                    setIsAdd(true)
                  }}
                >
                  <img
                    src={item.imgName}
                    className="h-[89px] w-[100%]"
                    style={{ objectFit: 'cover', borderRadius: '15px' }}
                  />
                  <p className="text-center mt-1">{item.title}</p>
                  <p className="text-center" style={{ color: '#aaa' }}>
                    {item.kcal} kcal
                  </p>
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </div>
      </>
      <Stack direction={{ mobile: 'row', laptop: 'row' }} justifySelf={'left'}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={createFood}
        >
          {(formik) => (
            <Form>
              <ElementComponent.ButtonCustom
                id="submit-health-form"
                style="w-full"
                backgroundColor="#06C755"
                submit
                loading={createFoodMutation.fetching}
                text="บันทึก"
                width="150px"
              />
            </Form>
          )}
        </Formik>
      </Stack>

      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] p-[16px] mt-2"
        alignItems={'center'}
      >
        <div className="w-[100%] laptop:w-[40%]  bg-cover ">
          <Stack
            direction={{ mobile: 'column', laptop: 'column' }}
            alignItems={'top'}
            className="gap-[8px]"
            style={{
              borderColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
            }}
          >
            <div>
              <Stack
                direction={{ mobile: 'column', laptop: 'column' }}
                className="gap-[18px] items-center"
              >
                <div
                  className="mt-[-15px]"
                  style={{
                    padding: '2px 12px',
                    backgroundColor: '#0D5CDD',
                    borderRadius: '15px',
                    borderWidth: '1px',
                  }}
                >
                  <h5 style={{ color: '#FFF' }}>ปริมาณเเคลอรี่ที่ทาน</h5>
                </div>
              </Stack>
            </div>
            <Stack
              direction={{ mobile: 'row' }}
              className="gap-[8px] p-[8px] text-[14px]"
              justifyContent="space-between"
              alignItems="center"
            >
              <div
                style={{
                  padding: '2px 12px',
                  backgroundColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  width: '33%',
                }}
              >
                <h5 style={{ color: '#FFF' }} className="text-center">
                  แคลอรี่ต่อวัน ที่แนะนำ
                </h5>
              </div>
              <div
                style={{
                  padding: '2px 12px',
                  borderColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  width: '33%',
                }}
              >
                <h5 style={{ color: '#000' }} className="text-center">
                  แคลอรี่ที่ทาน
                </h5>
              </div>
              <div
                style={{
                  padding: '2px 12px',
                  borderColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  width: '33%',
                }}
              >
                <h5 style={{ color: '#000' }} className="text-center">
                  คงเหลือ
                </h5>
              </div>
            </Stack>
            <Stack
              direction={{ mobile: 'row' }}
              className="gap-[8px] p-[8px] text-[14px]"
              justifyContent="space-between"
              alignItems="center"
            >
              <div
                style={{
                  padding: '2px 12px',
                  borderColor: '#aaa',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  width: '33%',
                }}
              >
                <h5 style={{ color: '#aaa' }} className="text-center">
                  <b
                    style={{ color: '#000' }}
                    className="text-center text-[16px]"
                  >
                    1,850
                  </b>{' '}
                  kcal
                </h5>
              </div>
              -
              <div
                style={{
                  padding: '2px 12px',
                  borderColor: '#aaa',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  width: '33%',
                }}
              >
                <h5 style={{ color: '#aaa' }} className="text-center">
                  <b
                    className="text-center text-[16px]"
                    style={{ color: '#000' }}
                  >
                    {kcalUsed}
                  </b>{' '}
                  kcal
                </h5>
              </div>
              =
              <div
                style={{
                  padding: '2px 12px',
                  borderColor: '#aaa',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  width: '33%',
                }}
              >
                <h5 style={{ color: '#aaa' }} className="text-center">
                  <b
                    className="text-center text-[16px]"
                    style={{ color: '#000' }}
                  >
                    {1850 - kcalUsed}
                  </b>{' '}
                  kcal
                </h5>
              </div>
            </Stack>
          </Stack>
        </div>
      </Stack>
      {submitPopupData.type === 'success' ? (
        <AlertComponent.SuccessModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      ) : (
        <AlertComponent.ErrorModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      )}

      <ModalComponent.FoodModal
        open={isAdd}
        onChoose={(item) => {
          const foodClick = item
          foodClick.index = timeIndex

          if (time === 'เช้า') {
            const allFoodCurrent = foodMorning
            allFoodCurrent[timeIndex] = foodClick
            setFoodMorning(allFoodCurrent)
          }
          if (time === 'กลางวัน') {
            const allFoodCurrent = foodLunch
            allFoodCurrent[timeIndex] = foodClick
            setFoodLunch(allFoodCurrent)
          }
          if (time === 'เย็น') {
            const allFoodCurrent = foodDinner
            allFoodCurrent[timeIndex] = foodClick
            setFoodDinner(allFoodCurrent)
          }
          setTimeIndex(timeIndex)
          setIsAdd(false)

          let kcal = 0
          foodMorning.map((item) => {
            kcal += item.kcal
          })
          foodLunch.map((item) => {
            kcal += item.kcal
          })
          foodDinner.map((item) => {
            kcal += item.kcal
          })
          setKcalUsed(kcal)
        }}
        onClose={() => setIsAdd(false)}
      />
    </>
  )
}

export default Food

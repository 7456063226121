import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { useQuery, useMutation } from 'urql'
import { ElementComponent, AlertComponent } from 'components'
import { general } from 'utils'
import { Form, Formik } from 'formik'
import dayjs from 'dayjs'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'
import * as yup from 'yup'
import BmiForm from 'components/form/healthForm/BmiForm'

interface BmiModalProps {
  open: boolean
  onClose: Function
}

function HealthCard() {
  const [medicalMutate, executeMedical] = useMutation(
    MedicalMutate.creatMedicalData,
  )
  const [medicalQueryData] = useQuery(
    MedicalQuery.getDisease({
      AND: [
        { type: { equals: 'GENERAL' } },
        { diseaseAttibute: { every: { inputFlag: { equals: true } } } },
      ],
    }),
  )
  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })
  const [formData, setFormData] = useState({})
  const [validationForm, setValidationForm] = useState<any>({})

  const [diseaseList, setDiseaseList] = useState([])

  const handleSubmit = async (values?: any) => {
    const data = Object.keys(values).flatMap((key: any) => {
      if (
        values[key].value !== '' &&
        values[key].value !== null &&
        values[key].value.length !== 0
      ) {
        let result = {
          daId: values[key].daId,
          createdAt: values[key]?.createdAt ?? dayjs().toISOString(),
        }

        if (values[key].type === 'FLOAT')
          result['value'] = Number(values[key].value)
        else if (values[key].type === 'IMAGE' && values[key].value.length > 0)
          result['file'] = values[key].value
        else if (values[key].type === 'TEXT' && values[key].value !== '')
          result['desciption'] = values[key].value

        return result
      }

      return null
    })

    await executeMedical({
      data: {
        patientUser: {
          connect: {
            uId: '',
          },
        },
        submitUser: {
          connect: {
            uId: '',
          },
        },
        dataInputFlag: true,
        userMedTrasaction: {
          createMany: {
            data: data.filter((item) => item !== null),
          },
        },
      },
    })
    if (medicalMutate.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: 'กรุณาลองใหม่อีกครั้ง',
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }

    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: 'กรุณารอวิเคราะห์ผลสักครู่',
      onClose: () => {
        general.http.goto('/overview')
      },
    })
  }
  useEffect(() => {
    ;(async () => {
      if (!medicalQueryData.fetching) {
        const { getDiseases } = await medicalQueryData.data
        const form = getDiseases.reduce((diseaseList, disease) => {
          disease.diseaseAttibute.forEach((data) => {
            diseaseList[data.medName] = {
              type: data.type,
              daId: data.daId,
              value: data.type === 'IMAGE' ? [] : '',
            }
          })
          return diseaseList
        }, {})
        const validation = await getDiseases.reduce((diseaseList, disease) => {
          disease.diseaseAttibute.forEach((data) => {
            if (data.mandatoryFlag) {
              diseaseList[data.medName] = yup.object().shape({
                value: yup
                  .number()
                  .required(`กรุณากรอก${data.thaiName}`)
                  .min(
                    data.minValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .max(
                    data.maxValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .typeError(
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                  )
                  .test(
                    'check-2-precision',
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                    (value) => {
                      if (value) {
                        return value.toString().match(/^\d+(\.\d{0,2})?$/)
                          ? true
                          : false
                      }
                      return true
                    },
                  ),
              })
            }

            if (data.type === 'FLOAT' && !data.mandatoryFlag) {
              diseaseList[data.medName] = yup.object().shape({
                value: yup
                  .number()
                  .min(
                    data.minValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .max(
                    data.maxValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .typeError(
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                  )
                  .test(
                    'check-2-precision',
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                    (value) => {
                      if (value) {
                        return value.toString().match(/^\d+(\.\d{0,2})?$/)
                          ? true
                          : false
                      }
                      return true
                    },
                  ),
              })
            }
          })
          return diseaseList
        }, {})

        setFormData(form)
        setDiseaseList(getDiseases)
        setValidationForm(yup.object().shape({ ...validation }))
      }
    })()
  }, [medicalQueryData])

  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'column' }}
      className="gap-5 bg-white-main rounded-[15px] p-[8px]"
      alignItems={'top'}
    >
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-5"
        alignItems={'top'}
      >
        <div className="w-[100%] laptop:w-[40%]  bg-cover ">
          <Formik
            initialValues={formData}
            enableReinitialize={true}
            validationSchema={validationForm}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <BmiForm
                  loading={medicalMutate.fetching}
                  formData={formik}
                  diseaseList={diseaseList}
                />

                <Stack
                  direction={{ mobile: 'column', laptop: 'column' }}
                  className="items-center pl-[38%] "
                >
                  <ElementComponent.ButtonCustom
                    id="submit-health-form"
                    style="w-full m-[16px]"
                    backgroundColor="#06C755"
                    submit
                    loading={medicalMutate.fetching}
                    text="ยืนยัน"
                    width="40%"
                  />
                </Stack>
              </Form>
            )}
          </Formik>
        </div>
      </Stack>
      {submitPopupData.type === 'success' ? (
        <AlertComponent.SuccessModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      ) : (
        <AlertComponent.ErrorModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      )}
    </Stack>
  )
}

function BmiModal({ open, onClose }: BmiModalProps) {
  return (
    <ElementComponent.ModalFormV2 open={open} onClose={() => onClose()}>
      <div className="flex flex-col items-center w-screen max-h-[600px] mt-[10px]">
        <HealthCard />
      </div>
    </ElementComponent.ModalFormV2>
  )
}

export default BmiModal

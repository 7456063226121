import React from 'react'
import { useQuery } from 'urql'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Line } from 'react-chartjs-2'
import { date } from 'utils'
//import { SkeletonComponent } from 'components'

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
)

import { OverviewQuery } from 'services/graphql/overview'
import { SkeletonComponent } from 'components'
import * as Pics from 'assets/picture'
var c_danger = new Image()
c_danger.src = Pics.ChartDanger
var c_warning = new Image()
c_warning.src = Pics.ChartWarning
var c_success = new Image()
c_success.src = Pics.ChartSuccess
const colorLevel = {
  0: '#FFCBC3',
  1: '#FFD795',
  2: '#FFE6B9',
  3: '#EDF292',
  4: '#CAEBB1',
}

const colorPoint = {
  0: '#E40009',
  1: '#F19701',
  2: '#FBE101',
  3: '#CDD800',
  4: '#69AE33',
}
const icoPoint = {
  0: c_danger,
  1: c_warning,
  2: c_warning,
  3: c_warning,
  4: c_success,
}
interface LineChartMedicalDataProps {
  daId: string
  thaiName: string
  description?: string
  unitThai: string
}
function LineChartMedicalData({
  daId,
  thaiName,
  description,
  unitThai,
}: LineChartMedicalDataProps) {
  const [medicalTrasactionData] = useQuery(
    OverviewQuery.getMedDataTransactionsBydId(daId),
  )

  const [diseaseCondition] = useQuery(
    OverviewQuery.getDiseaseAttibutesCondition(daId),
  )
  const displayRule = (score?: number, level?: number): any => {
    if (!diseaseCondition.fetching && !diseaseCondition.error) {
      const { getDiseaseAttibuteCondition } = diseaseCondition.data
      try {
        const conditionString = getDiseaseAttibuteCondition.condition
        if (Array.isArray(JSON.parse(conditionString))) {
          const conditions = JSON.parse(conditionString)

          let levels: number = 0

          conditions.forEach((rule) => {
            const { value } = rule

            switch (rule.condition) {
              case '>=':
                if (
                  value.reduce((sum, number) => {
                    const checked = Boolean(score >= number)
                    return sum || checked
                  }, false)
                )
                  levels = rule.level
                break
              case '<=':
                if (
                  value.reduce((sum, number) => {
                    const checked = Boolean(score <= number)
                    return sum || checked
                  }, false)
                )
                  levels = rule.level
                break
              case '>':
                if (
                  value.reduce((sum, number) => {
                    const checked = Boolean(score > number)
                    return sum || checked
                  }, false)
                )
                  levels = rule.level
                break
              case '<':
                if (
                  value.reduce((sum, number) => {
                    const checked = Boolean(score < number)
                    return sum || checked
                  }, false)
                )
                  levels = rule.level
                break
              case '-':
                if (
                  value.reduce((sum, number) => {
                    const checked = Boolean(
                      score >= number[0] && score <= number[1],
                    )
                    return sum || checked
                  }, false)
                )
                  levels = rule.level
                break
              default:
                break
            }
          })

          if (level !== undefined) {
            return conditions.filter((item) => item.level === level)
          } else return { level: levels }
        }
      } catch {
        return { level: 4 }
      }
    }
  }

  const processScale = (data) => {
    const formatData = data.map((item) => item.value)

    let minCal = Math.min(...formatData) * 0.75
    minCal = minCal > 1 ? minCal : 0

    return {
      min: Math.max(minCal, 0),
      max: Math.max(...formatData) * 1.25,
    }
  }

  const preProcessData = (data) => {
    if (data.length === 0) return { labels: [], datasets: [] }

    const datas = data.map((item) => item.value).reverse()
    const labels = data.map((item) => item.createdAt).reverse()

    return {
      labels: labels,
      datasets: [
        {
          data: datas,
          borderColor: 'rgba(0, 0, 0,0.5)',
          backgroundColor: 'rgba(0, 0, 0,0.5)',
          pointRadius: 6,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: datas.map(
            (value) => colorPoint[displayRule(value).level],
          ),
          pointStyle: datas.map((value) => icoPoint[displayRule(value).level]),
        },
      ],
    }
  }

  const renderGraph = (data: any[]) => {
    if (data.length === 0) {
      return (
        <div className="w-full min-h-[300px] flex justify-center items-center">
          <p className="text-gray-main">ไม่มีข้อมูล</p>
        </div>
      )
    }
    const plugin = {
      id: 'customCanvasBackgroundColor',
      beforeDraw: (chart) => {
        const { ctx, chartArea, scales } = chart

        ctx.save()

        Object.keys(colorLevel).forEach((key: string) => {
          const rule = displayRule(null, Number(key))

          rule.forEach((item) => {
            if (item.condition === '>=' || item.condition === '>') {
              genrateBackgroundColor(
                colorLevel[key],
                scales.y.max,
                handleMinMax(item.value[0]),
              )
            } else if (item.condition === '<=' || item.condition === '<') {
              genrateBackgroundColor(
                colorLevel[key],
                handleMinMax(item.value[0]),
                scales.y.min,
              )
            } else if (item.condition === '-') {
              item.value.map((values) => {
                genrateBackgroundColor(
                  colorLevel[key],
                  handleMinMax(values[0]),
                  handleMinMax(values[1]),
                )
              })
            }
          })
        })

        function handleMinMax(value: number): number {
          if (value > scales.y.max) return scales.y.max
          else if (value < scales.y.min) return scales.y.min
          else return Number(value)
        }

        function genrateBackgroundColor(color, max, min) {
          ctx.fillStyle = color
          ctx.fillRect(
            chartArea.left,
            scales.y.getPixelForValue(max),
            chartArea.width,
            scales.y.getPixelForValue(min) - scales.y.getPixelForValue(max),
          )
        }
        // ctx.globalCompositeOperation = 'destination-over'
        // ctx.fillRect(0, 0, chart.width, chart.height)
        ctx.restore()
      },
    }

    return (
      <div className="h-[270px] w-full">
        <Line
          plugins={[plugin]}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              // Cannot use unitThai in y label because still not figure out how to rotate it
              title: {
                display: true,
                text: unitThai,
                align: 'start',
              },
              tooltip: {
                callbacks: {
                  title: function (context) {
                    return date.convertToShowDateForGraph(
                      new Date(context[0].label),
                    )
                  },
                },
              },
            },
            scales: {
              x: {
                title: {
                  color: 'black',
                  display: true,
                  text: 'วันที่',
                  align: 'end',
                },
                ticks: {
                  // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                  callback: function (val) {
                    // Hide every 2nd tick label
                    return date.convertToShowDateCommonEra(
                      date.convertStringToDate(
                        this.getLabelForValue(val as number),
                      ),
                    )
                  },
                },
              },
              y: processScale(data),
            },
          }}
          redraw={true}
          data={preProcessData(data)}
        />
      </div>
    )
  }

  return (
    <div className="h-full">
      {!medicalTrasactionData?.fetching &&
      !medicalTrasactionData?.error &&
      !diseaseCondition?.fetching &&
      !diseaseCondition.error ? (
        <>
          <div className="flex items-center gap-[5px]">
            <span className="font-bold">{thaiName}</span>
            <span className="text-black-lighter text-[13px]">{`( ${description} )`}</span>
          </div>
          <hr className="mt-[10px] mb-[20px]" />
          {renderGraph(medicalTrasactionData.data.getMedDataTransactions)}
        </>
      ) : (
        <SkeletonComponent.ChartSkeleton />
      )}
    </div>
  )
}

export default LineChartMedicalData

const register = `
mutation register ($data: UserCreateInput!) {
  register (data: $data) {
      phoneNumber
      refno
  }
}
`

const verifyRegister = `
mutation verifyRegister ($data: RegisterVerifyOTPInput!) {
  verifyRegister (data: $data) {
      refreshToken
      accessToken
      phoneNumber
      password
  }
}
`

export { register, verifyRegister }

export enum FormType {
  LOGIN = 'LOGIN',
  FORGET = 'FORGET',
  RESET = 'RESET',
  AUTH = 'AUTH',
  SUCCESS = 'SUCCESS',
}

export type LoginDataType = {
  phone: string
  password: string
}

export type ResetDataType = {
  password: string
  confirmPassword: string
}

import React from 'react'

import { ChartCompoent } from 'components'

import { Stack } from '@mui/material'

interface IAssociateDiseaseCardProps {
  diseaseData: {
    imagePath: string
    medName: string
    thaiName: string
    associateDieaseAttibute: {
      adaId: string
      thaiName: string
      description: string
      unitThai: string
      showFlag: boolean
      type: string
      indexSelect: string
      showPrevoius: boolean
    }[]
  }[]
}

function AssociateDiseaseCard({ diseaseData }: IAssociateDiseaseCardProps) {
  return (
    <>
      {/* Disease Attribute for Associate Disease */}
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        alignItems={'top'}
        className="flex justify-center items-center gap-4"
      >
        {diseaseData.map((associateDisease) => {
          return (
            <Stack
              direction={{ mobile: 'column', laptop: 'row' }}
              alignItems={'top'}
              className="gap-5 mt-9 w-11/12 p-3 laptop:p-[25px] border border-blue-main rounded-xl bg-white-main"
            >
              {/* Associate Disease attribute */}
              <div className="flex justify-center flex-row -mt-9">
                <img
                  src={associateDisease.imagePath}
                  className="w-12 h-12 -mr-3.5 z-10 bg-white-main border border-blue-main rounded-3xl"
                  alt={`icon-${associateDisease.medName}`}
                />
                <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
                  <h5 className="text-white-main mx-3.5">
                    {associateDisease.thaiName}
                  </h5>
                </div>
              </div>
              {/* Associate Disease attribute */}
              {associateDisease.associateDieaseAttibute.map(
                (associateDieaseAttribute) => {
                  return (
                    <>
                      {associateDieaseAttribute.showFlag && (
                        <>
                          {associateDieaseAttribute.type === 'FLOAT' &&
                            !associateDieaseAttribute.showPrevoius && (
                              <ChartCompoent.CardLatestData
                                dieaseData={associateDieaseAttribute}
                              />
                            )}
                          {associateDieaseAttribute.type === 'FLOAT' &&
                            associateDieaseAttribute.showPrevoius && (
                              <div className="gap-[18px] w-full laptop:w-1/2">
                                <ChartCompoent.LineChartAssociateDiseaseMedicalData
                                  adaId={associateDieaseAttribute.adaId}
                                  thaiName={associateDieaseAttribute.thaiName}
                                  description={
                                    associateDieaseAttribute.description
                                  }
                                  unitThai={associateDieaseAttribute.unitThai}
                                />
                              </div>
                            )}

                          {associateDieaseAttribute.type === 'INDEX' && (
                            <ChartCompoent.CardStatusData
                              dieaseData={associateDieaseAttribute}
                            />
                          )}
                        </>
                      )}
                    </>
                  )
                },
              )}
            </Stack>
          )
        })}
      </Stack>
    </>
  )
}

export default AssociateDiseaseCard

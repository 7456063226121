/** URL DR.ASA LINE GROUP */
const LINE_OFFCIAL_GRUOP = 'https://lin.ee/iGOaaWa'
const LINE_GENERAL_CONSULT =
  'https://line.me/ti/g2/L3VPT0QNF4dKDpXHiXdX7AEnQSp48N9g9HIg8Q?utm_source=invitation&utm_medium=link_copy&utm_campaign=default'
const LINE_DIABETES_CONSULT =
  'https://line.me/ti/g2/jZc0jgMLO8_aFGE4mrdTfx89k1GLveBrzMfifg?utm_source=invitation&utm_medium=link_copy&utm_campaign=default'
const LINE_BONE_CONSULT =
  'https://line.me/ti/g2/feXwHW_3kXSuyIJ40AtxiINZgYUus8W1lv59Iw?utm_source=invitation&utm_medium=link_copy&utm_campaign=default'

/** URL DR. SOCIAL */
const FACEBOOK_GROUP = 'https://www.facebook.com/doctorasahealth'
const YOUTUBE_GROUP = 'https://youtube.com/@thefactmed922'
const TIKTOK_GROUP =
  'https://www.tiktok.com/@dr.asatiktok?is_from_webapp=1&sender_device=pc'

export {
  LINE_OFFCIAL_GRUOP,
  LINE_GENERAL_CONSULT,
  LINE_DIABETES_CONSULT,
  LINE_BONE_CONSULT,
  FACEBOOK_GROUP,
  YOUTUBE_GROUP,
  TIKTOK_GROUP,
}

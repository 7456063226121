import { Stack } from '@mui/material'
import { ElementComponent } from 'components'
import * as Pics from 'assets/picture'
import * as SVG from 'assets/svg'
import { OverviewQuery } from 'services/graphql/overview'
import { useQuery } from 'urql'
import DrugMain from './DrugMain'
import { useState } from 'react'
function Drug() {
  const [page, setPage] = useState<number>(0)
  const [image, setImage] = useState<string>('')
  const [medicalQueryData] = useQuery(OverviewQuery.getUserOverviewData())
  const diseaseList: {
    title: string
    imgName: string
  }[] = [
    {
      title: 'โรคเบาหวาน',
      imgName: Pics.ItemDiabetes,
    },
    {
      title: 'โรคไขมันในเลือดสูง',
      imgName: Pics.ItemDys,
    },
    {
      title: 'โรคหัวใจ',
      imgName: Pics.ItemHeart,
    },
    {
      title: 'โรคความดันโลหิตสูง',
      imgName: Pics.ItemHyper,
    },
    {
      title: 'โรคตับ',
      imgName: Pics.ItemKidney,
    },
    {
      title: 'โรคไต',
      imgName: Pics.ItemLiver,
    },
  ]
  return (
    <Stack className="items-center">
      {page === 0 ? (
        <>
          <div className="mt-[16px]">
            <SVG.HeaderDrug />
          </div>
          {!medicalQueryData.fetching && !medicalQueryData.error ? (
            <>
              {medicalQueryData.data?.getUser.UserMedicalDemograhicData.userUnderlyingDiseaseTrasaction.map(
                (item) => (
                  <h5 style={{ color: '#FFF' }}>
                    {diseaseList.map(
                      (sitem) =>
                        sitem.title === item.underlyingDisease.thaiName && (
                          <div
                            onClick={() => {
                              setImage(sitem.imgName)
                              setPage(1)
                            }}
                          >
                            <ElementComponent.Image
                              fullImage={false}
                              src={sitem.imgName}
                              className="mt-4"
                            />
                          </div>
                        ),
                    )}
                  </h5>
                ),
              )}
            </>
          ) : (
            <>ไม่มี</>
          )}
        </>
      ) : (
        <>
          <DrugMain diseaseImage={image} />
        </>
      )}
    </Stack>
  )
}

export default Drug

import React from 'react'
//import { useQuery } from 'urql'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Bar } from 'react-chartjs-2'
import { date } from 'utils'
//import { SkeletonComponent } from 'components'

ChartJS.register(
  LinearScale,
  PointElement,
  BarElement,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
)

//import { OverviewQuery } from 'services/graphql/overview'
import { SkeletonComponent } from 'components'
import * as Pics from 'assets/picture'
var c_danger = new Image()
c_danger.src = Pics.ChartDanger
var c_warning = new Image()
c_warning.src = Pics.ChartWarning
var c_success = new Image()
c_success.src = Pics.ChartSuccess

const getBmiColor = (bmiVal: number) => {
  let color = ''
  if (bmiVal < 18) {
    color = '#02A7AB'
  } else if (bmiVal >= 18 && bmiVal <= 22.9) {
    color = '#1B9B00'
  } else if (bmiVal >= 23 && bmiVal <= 24.9) {
    color = '#FFEFC5'
  } else if (bmiVal >= 25 && bmiVal <= 29.9) {
    color = '#EDB101'
  } else if (bmiVal >= 30 && bmiVal <= 39.9) {
    color = '#FF6801'
  } else if (bmiVal >= 40) {
    color = '#E42A1E'
  }
  return color
}
interface BarChartBmiProps {
  weightTrasactionData
  heightTrasactionData
}
function BarChartBmi({
  weightTrasactionData,
  heightTrasactionData,
}: BarChartBmiProps) {
  const preProcessData = (wdata, hdata) => {
    if (wdata.length === 0) return { labels: [], datasets: [] }
    const datas = []
    const labels = []
    const colors = []
    for (let i = 0; i < wdata.length; i++) {
      datas[i] = (
        wdata[i].value /
        (((hdata[i].value / 100) * hdata[i].value) / 100)
      ).toFixed(2)
      labels[i] = wdata[i].createdAt
      colors[i] = getBmiColor(datas[i])
    }
    datas.reverse()
    labels.reverse()
    colors.reverse()
    return {
      labels: labels,
      datasets: [
        {
          data: datas,
          backgroundColor: colors,
        },
      ],
    }
  }

  const renderGraph = (data: any[], hdata: any[]) => {
    if (data.length === 0) {
      return (
        <div className="w-full min-h-[300px] flex justify-center items-center">
          <p className="text-gray-main">ไม่มีข้อมูล</p>
        </div>
      )
    }
    const plugin = {
      id: 'customCanvasBackgroundColor',
      beforeDraw: (chart) => {
        const { ctx } = chart

        ctx.save()
        ctx.restore()
      },
    }

    return (
      <div className="h-[270px] w-full">
        <Bar
          plugins={[plugin]}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              // Cannot use unitThai in y label because still not figure out how to rotate it
              title: {
                display: true,
                text: 'หน่วย',
                align: 'start',
              },
              tooltip: {
                callbacks: {
                  title: function (context) {
                    return date.convertToShowDateForGraph(
                      new Date(context[0].label),
                    )
                  },
                },
              },
            },
            scales: {
              x: {
                title: {
                  color: 'black',
                  display: true,
                  text: 'วันที่',
                  align: 'end',
                },
                ticks: {
                  // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                  callback: function (val) {
                    // Hide every 2nd tick label
                    return date.convertToShowDateCommonEra(
                      date.convertStringToDate(
                        this.getLabelForValue(val as number),
                      ),
                    )
                  },
                },
              },
            },
          }}
          redraw={true}
          data={preProcessData(data, hdata)}
        />
      </div>
    )
  }

  return (
    <div className="h-full">
      {!weightTrasactionData?.fetching &&
      !weightTrasactionData?.error &&
      !heightTrasactionData?.fetching &&
      !heightTrasactionData.error ? (
        <>
          <div className="flex items-center gap-[5px]">
            <span className="font-bold">BMI</span>
            <span className="text-black-lighter text-[13px]">
              ประวัติองค์ประกอบของร่างกาย
            </span>
          </div>
          <hr className="mt-[10px] mb-[20px]" />
          {renderGraph(
            weightTrasactionData.data.getMedDataTransactions,
            heightTrasactionData.data.getMedDataTransactions,
          )}
        </>
      ) : (
        <SkeletonComponent.ChartSkeleton />
      )}
    </div>
  )
}

export default BarChartBmi

export const getMedicalData = (take: number, skip: number) => {
  const medicalDataSchema = `query getMedicalDatas ($take: Int, $skip: Int, $orderBy: [UserMedicalDataOrderByWithRelationInput!]) {
                              getMedicalDatas(take: $take, skip: $skip, orderBy: $orderBy) {
                                umdId
                                userMedTrasaction {
                                  diseaseAttibute {
                                    daId
                                    medName
                                    thaiName
                                    type
                                  }
                                  value
                                  imagePath
                                  desciption
                                }
                                diagnosisData {
                                  createdAt
                                  doctorUser {
                                    firstname
                                  }
                                }
                                submitUser {
                                  firstname
                                }
                                dataInputFlag
                                createdAt
                                _count {
                                  diagnosisData
                                }
                              }
                            }`

  return {
    query: medicalDataSchema,
    variables: {
      take: take,
      skip: skip,
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}

export const getMedicalDataById = (condition?: any) => {
  const medicalDataSchema = `query getMedicalData ($where: UserMedicalDataWhereInput) {
                              getMedicalData(where: $where) {
                                umdId
                                userMedTrasaction {
                                  diseaseAttibute {
                                    daId
                                    dId
                                    medName
                                    thaiName
                                    type
                                  }
                                  createdAt
                                  value
                                  imagePath
                                  desciption
                                }
                                diagnosisData {
                                  createdAt
                                  diagnosisResult
                                  suggestion
                                  doctorUser {
                                    firstname
                                  }
                                }
                                submitUser {
                                  firstname
                                }
                                dataInputFlag
                                createdAt
                                _count {
                                  diagnosisData
                                }
                              }
                            }`

  return {
    query: medicalDataSchema,
    variables: {
      where: {
        ...condition,
      },
    },
  }
}

export const getSubmitCount = () => {
  const getUserQuery = `query getUser ($where: UserWhereUniqueInput!) {
                          getUser (where: $where) {
                            _count {
                              submitterMedicalData
                              patientMedicalData
                              diagnosisData
                            }
                          }
                        }`

  return {
    query: getUserQuery,
    variables: {
      where: {
        uId: '',
      },
    },
  }
}

export const getDiseaseForDropDown = (condition: any) => {
  const getDisease = `query getDiseases($where: DiseaseWhereInput! $orderBy: [DiseaseOrderByWithRelationInput!]){
                        getDiseases(where: $where, orderBy: $orderBy) {
                          dId
                          thaiName
                        }
                      }`

  return {
    query: getDisease,
    variables: {
      where: {
        ...condition,
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getDisease = (condition: any) => {
  const getDisease = `query getDiseases($where: DiseaseWhereInput! $orderBy: [DiseaseOrderByWithRelationInput!]){
                        getDiseases(where: $where, orderBy: $orderBy) {
                          dId
                          thaiName
                          medName
                          engName
                          type
                          diseaseAttibute {
                            daId
                            medName
                            thaiName
                            engName
                            unitEnglish
                            unitThai
                            type
                            exampleImage
                            minValuePos
                            maxValuePos
                            mandatoryFlag
                          }
                          createdAt
                        }
                      }`

  return {
    query: getDisease,
    variables: {
      where: {
        ...condition,
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getDiseaseAttibute = (condition: any) => {
  const getDiseaseAttibute = `query getDiseasesAttibutes($where: DiseaseAttibuteWhereInput! $orderBy: [DiseaseAttibuteOrderByWithRelationInput!]){
                        getDiseasesAttibutes(where: $where, orderBy: $orderBy) {
                          daId
                          thaiName
                          medName
                          engName
                          unitEnglish
                          unitThai
                          type
                          exampleImage
                          mandatoryFlag
                          minValuePos
                          maxValuePos
                          createdAt
                          inputFlag
                        }
                      }`

  return {
    query: getDiseaseAttibute,
    variables: {
      where: {
        ...condition,
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getUnderlyingDiseases = () => {
  const getUnderlyingDiseases = `query getUnderlyingDiseases($where: UnderlyingDiseaseWhereInput!) {
                                  getUnderlyingDiseases(where: $where) {
                                    udId
                                    medName
                                    thaiName
                                    engName
                                  }
                                }`

  return {
    query: getUnderlyingDiseases,
    variables: {
      where: {},
    },
  }
}

export const getMedicalTrasactions = (condition: any) => {
  const getMedicalTrasactions = `query getMedDataTransactions($where: UserMedTrasactionWhereInput) {
                                  getMedDataTransactions(where: $where) {
                                    umdId
                                    value
                                    imagePath
                                    diseaseAttibute {
                                      thaiName
                                      engName
                                      medName
                                    }
                                  }
                                }`

  return {
    query: getMedicalTrasactions,
    variables: {
      where: {
        ...condition,
      },
    },
  }
}

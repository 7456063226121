import React from 'react'
import { Skeleton } from '@mui/material'

interface AuthFormSkeletonProps {
  length: number
}

export default function AuthFormSkeleton({ length }: AuthFormSkeletonProps) {
  return (
    <div className="flex justify-center laptop:justify-start gap-[20px]">
      {Array(length)
        .fill(0)
        .map((_, index: number) => (
          <Skeleton
            key={`auth-field-${index}`}
            variant="rounded"
            width="50px"
            height="60px"
          />
        ))}
    </div>
  )
}

import React from 'react'

interface UserOptionProps {
  menu: any
  options: string
  onChangeOptions: Function
}

function UserOption({ menu, options, onChangeOptions }: UserOptionProps) {
  return (
    <div
      className="bg-white-main rounded-[10px] fixed w-[300px] h-fit"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <div className=" border-gray-light py-[20px]">
        {Array.isArray(menu) &&
          menu.map((item, index: number) => (
            <>
              <div
                key={`user-option-${index}`}
                className={`flex items-center gap-[10px] cursor-pointer ${
                  options === item.path ? 'text-blue-main' : ''
                } px-[30px] py-[5px] hover:bg-blue-light`}
                onClick={() => onChangeOptions(item.path)}
              >
                <img src={item.icon} />
                <p className="text-[18px]">{item.text}</p>
              </div>
              {Array.isArray(item.sub_menu) &&
                item.sub_menu.map((item_sub, index_sub: number) => (
                  <div
                    key={`user-option-${index_sub}`}
                    className={`flex items-center gap-[10px] cursor-pointer ${
                      options === item_sub.path ? 'text-blue-main' : ''
                    } px-[60px] py-[5px] hover:bg-blue-light`}
                    onClick={() => onChangeOptions(item_sub.path)}
                  >
                    <img src={item_sub.icon} />
                    <p className="text-[18px]">{item_sub.text}</p>
                  </div>
                ))}
            </>
          ))}
      </div>
    </div>
  )
}

export default UserOption

import React, { useEffect, useState } from 'react'
import { Grid, Stack, Table } from '@mui/material'
import { useQuery, useMutation } from 'urql'
import { useRecoilState } from 'recoil'
import { OverviewQuery } from 'services/graphql/overview'
import { UserQuery, UserMutate } from 'services/graphql/userService'
import liff from '@line/liff'
import {
  //DiseaseComponent,
  ElementComponent,
  ModalComponent,
  SkeletonComponent,
  AlertComponent,
  DiseaseComponent,
} from 'components'
import { useUser } from 'stores/recoil'
import { data, date } from 'utils'
import * as SVG from 'assets/svg'
import * as Pics from 'assets/picture'
import { useResponsive } from 'hooks'
import { Link } from 'react-router-dom'
import { route } from 'settings'
import { useNavigate } from 'react-router-dom'
import { MedicalQuery } from 'services/graphql/medicalService'

function HealthCard({
  fetching,
  error,
  cardData,
}: {
  fetching: boolean
  error: any
  cardData: any
}) {
  const navigate = useNavigate()
  const { isMobile } = useResponsive()
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const [imageStateError, setImageStateError] = useState<boolean>(false)
  const [openDiabeteModal, setOpenDiabeteModal] = useState<boolean>(false)
  const [openBmiModal, setOpenBmiModal] = useState<boolean>(false)
  const [openPlanModal, setOpenPlanModal] = useState<boolean>(false)
  const [openBmiHistoryModal, setOpenBmiHistoryModal] = useState<boolean>(false)
  const [defualtDisease, setDefualtDisease] = useState<number>(0) //0 คือเบาหวาน
  const [, executeMutation] = useMutation(UserMutate.updateUser)
  const [isLoaded, setLoaded] = useState<boolean>(false)

  const initLine = () => {
    liff.init({ liffId: '2003394345-zpvOmEQ9' }, () => {
      if (liff.isLoggedIn()) {
        runApp()
      } else {
        //liff.login()
      }
    })
  }
  if (!isLoaded) {
    initLine()
    setLoaded(true)
  }

  const runApp = () => {
    //const idToken = liff.getIDToken()
    //setIdToken(idToken)
    liff.getProfile().then(async (profile) => {
      //alert('in action')
      let submitData = {
        UserAuth: {
          update: {
            lineUId: {
              set: profile.userId,
            },
          },
        },
      }
      await executeMutation({
        data: submitData,
        where: {
          uId: '',
        },
      })
    })
  }

  const [medicalQueryData] = useQuery(
    MedicalQuery.getDisease({
      AND: [
        { type: { equals: 'GENERAL' } },
        { diseaseAttibute: { every: { inputFlag: { equals: true } } } },
      ],
    }),
  )

  const getDiseasesDaidByMedName = (medName: string) => {
    let daid = ''
    if (!medicalQueryData.fetching && !medicalQueryData.error) {
      for (let i = 0; i < medicalQueryData.data.getDiseases.length; i++) {
        if (medicalQueryData.data.getDiseases[i].medName == medName) {
          daid = medicalQueryData.data.getDiseases[i].diseaseAttibute[0].daId
        }
      }
    }
    return daid
  }
  const [weightTrasactionData] = useQuery(
    OverviewQuery.getMedDataTransactionsForBmi(
      getDiseasesDaidByMedName('Weight'),
    ),
  )
  const [heightTrasactionData] = useQuery(
    OverviewQuery.getMedDataTransactionsForBmi(
      getDiseasesDaidByMedName('Height'),
    ),
  )
  const getBmiImage = (bmiVal: number) => {
    let img = ''
    if (bmiVal < 18) {
      img = Pics.Bmi1
    } else if (bmiVal >= 18 && bmiVal <= 22.9) {
      img = Pics.Bmi2
    } else if (bmiVal >= 23 && bmiVal <= 24.9) {
      img = Pics.Bmi3
    } else if (bmiVal >= 25 && bmiVal <= 29.9) {
      img = Pics.Bmi4
    } else if (bmiVal >= 30 && bmiVal <= 39.9) {
      img = Pics.Bmi5
    } else if (bmiVal >= 40) {
      img = Pics.Bmi6
    }
    return img
  }
  const imageOnError = () => {
    setImageStateError(true)
  }
  const mapValue = {
    '0': {
      value: 0.2,
      valueStr: 20,
      color: '#ad2418',
      text: 'ควรปรับปรุง',
      bg: Pics.level1,
    },
    '1': {
      value: 0.4,
      color: '#e9a95a',
      text: 'พอใช้',
      bg: Pics.level2,
    },
    '2': {
      value: 0.6,
      color: '#c7c03e',
      text: 'ปานกลาง',
      bg: Pics.level3,
    },
    '3': {
      value: 0.8,
      color: '#3CE93C',
      text: 'ค่อนข้างดี',
      bg: Pics.level4,
    },
    '4': {
      value: 1.0,
      color: '#06C755',
      text: 'ดีเยี่ยม',
      bg: Pics.level5,
    },
  }
  const [overallHealthScorce] = useQuery(
    OverviewQuery.getHealthStatusOverallDiseaseScorces(),
  )

  const [bloodPressureScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Hypertension', //ความดัน
    ),
  )

  const [diabetesScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Diabetes mellitus (DM)', //โรคเบาหวาน
    ),
  )

  const [bloodFatScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Dyslipidemia', //โรคไขมันในเลือด
    ),
  )

  const [liverScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Chronic Liver Disease/Cirrhosis (CLD)', //โรคตับ
    ),
  )

  const [kidneyScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Chronic Kidney Disease (CKD)', //โรคไต
    ),
  )

  const categorysList: {
    index: number
    title: string
    imgName: string
    data
  }[] = [
    {
      index: 0,
      title: 'เบาหวาน',
      imgName: Pics.I1,
      data: diabetesScorce,
    },
    {
      index: 1,
      title: 'ไขมัน',
      imgName: Pics.I2,
      data: bloodFatScorce,
    },
    {
      index: 2,
      title: 'ความดัน',
      imgName: Pics.I3,
      data: bloodPressureScorce,
    },
    {
      index: 3,
      title: 'ตับ',
      imgName: Pics.I4,
      data: liverScorce,
    },
    {
      index: 4,
      title: 'ไต',
      imgName: Pics.I5,
      data: kidneyScorce,
    },
  ]

  const formatUnderlyingDisease = (
    data: {
      underlyingDisease: {
        thaiName: string
        engName: string
      }
    }[],
  ) => {
    let formatString = ''
    data.forEach((item, index) => {
      if (index === data.length - 1)
        formatString += `${item.underlyingDisease.thaiName}`
      else formatString += `${item.underlyingDisease.thaiName},`
    })
    return formatString
  }

  return (
    <>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px]"
        alignItems={'top'}
      >
        <div className="w-[100%] laptop:w-[50%]  bg-cover">
          <Stack
            direction={{ mobile: 'column', laptop: 'column' }}
            alignItems={'top'}
            className="gap-[18px] items-center"
            style={{
              borderColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
              padding: '25px',
              backgroundColor: '#FFF',
            }}
          >
            <div>
              <Stack
                direction={{ mobile: 'column', laptop: 'column' }}
                className="gap-[18px] items-center"
              >
                <div className="flex items-center gap-[10px]">
                  {!imageStateError && user['imagePath'] ? (
                    <div>
                      <ElementComponent.Image
                        fullImage={false}
                        className="h-[30px] w-[30px] rounded-full bg-cover bg-center"
                        src={user['imagePath']}
                        onError={imageOnError}
                      />
                    </div>
                  ) : (
                    <SVG.AvatarProfile className="h-[50px] w-[50px] rounded-full bg-cover bg-center" />
                  )}
                  <Stack direction={{ mobile: 'column', laptop: 'column' }}>
                    <h5 style={{ color: '#0D5CDD' }}>{user['firstname']}</h5>
                    <div
                      style={{
                        padding: '2px 12px',
                        backgroundColor: '#0D5CDD',
                        borderRadius: '15px',
                        borderWidth: '1px',
                      }}
                    >
                      <h5 style={{ color: '#FFF' }}>
                        HN {data.formatId(user['hnId'].toString())}
                      </h5>
                    </div>
                  </Stack>
                </div>
              </Stack>
            </div>
            <Grid className="mt-[16px] w-[100%]" item mobile={1}>
              {!fetching && !error ? (
                <div className="flex flex-col text-[13px] laptop:text-[14px] text-black-main">
                  <Grid container>
                    <Grid item mobile={5}>
                      <p>
                        <b>ชื่อ :</b>
                      </p>
                    </Grid>
                    <Grid item>
                      <p>{user['firstname']}</p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '5px' }}>
                    <Grid item mobile={5}>
                      <p>
                        <b>อายุ :</b>
                      </p>
                    </Grid>
                    <Grid item>
                      <p>
                        {date.getAge(
                          cardData.UserMedicalDemograhicData.birthdate,
                        )}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '5px' }}>
                    <Grid item mobile={5}>
                      <p>
                        <b>เกิด :</b>
                      </p>
                    </Grid>
                    <Grid item>
                      <p>
                        {date.convertToShowDateThai(
                          cardData.UserMedicalDemograhicData.birthdate,
                        )}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item mobile={5}>
                      <p>
                        <b>เพศ :</b>
                      </p>
                    </Grid>
                    <Grid item>
                      <p>{cardData.UserMedicalDemograhicData.gender}</p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '5px' }}>
                    <Grid item mobile={5}>
                      <p>
                        <b>ภูมิลำเนา:</b>
                      </p>
                    </Grid>
                    <Grid item>
                      <p>{cardData.UserMedicalDemograhicData.province}</p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '5px' }}>
                    <Grid item mobile={5}>
                      <p>
                        <b>แพ้ยา/อาหาร :</b>
                      </p>
                    </Grid>
                    <Grid item>
                      <p>
                        {cardData.UserMedicalDemograhicData.allergic !== ''
                          ? cardData.UserMedicalDemograhicData.allergic
                          : 'ไม่มี'}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '5px' }}>
                    <Grid item mobile={5}>
                      <div
                        className="w-[110px] laptop:w-[130px] "
                        style={{
                          padding: '2px 12px',
                          backgroundColor: '#0D5CDD',
                          borderRadius: '15px',
                          borderWidth: '1px',
                        }}
                      >
                        <p style={{ color: '#FFF' }}>โรคประจำตัว :</p>
                      </div>
                    </Grid>
                    <Grid item mobile={5}>
                      <p>
                        {formatUnderlyingDisease(
                          cardData.UserMedicalDemograhicData
                            .userUnderlyingDiseaseTrasaction,
                        ) !== ''
                          ? formatUnderlyingDisease(
                              cardData.UserMedicalDemograhicData
                                .userUnderlyingDiseaseTrasaction,
                            )
                          : 'ไม่มี'}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '5px' }}>
                    <Grid item mobile={5}>
                      <div
                        className="w-[110px] laptop:w-[130px] "
                        style={{
                          padding: '2px 12px',
                          backgroundColor: '#0D5CDD',
                          borderRadius: '15px',
                          borderWidth: '1px',
                        }}
                      >
                        <p
                          className="text-[12px] laptop:text-[14px]"
                          style={{ color: '#FFF' }}
                        >
                          หน่วยงานที่ดูแล :
                        </p>
                      </div>
                    </Grid>
                    <Grid item mobile={5}>
                      <p>โรงพยาบาลราชพิพัฒน์</p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '5px' }}>
                    <Grid item mobile={5}>
                      <div
                        className="w-[110px] laptop:w-[130px] "
                        style={{
                          padding: '2px 12px',
                          backgroundColor: '#0D5CDD',
                          borderRadius: '15px',
                          borderWidth: '1px',
                        }}
                      >
                        <p style={{ color: '#FFF' }}>แพทย์ประจำตัว :</p>
                      </div>
                    </Grid>
                    <Grid item mobile={5}>
                      <p>นพ. ธนกร ยนต์นิยม</p>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <SkeletonComponent.CardProfileSkeleton />
              )}
            </Grid>
          </Stack>
          {isMobile && (
            <Stack
              direction={{ mobile: 'column', laptop: 'column' }}
              alignItems={'top'}
              className="gap-[18px] items-center mt-[36px]"
              style={{
                borderColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
                backgroundColor: '#FFF',
              }}
            >
              <Stack
                direction={{ mobile: 'row', laptop: 'row' }}
                className="mt-[-15px]"
              >
                <SVG.IconBmi className="w-[48px] h-[48px] mt-[-8px] mr-[-18px] z-[1]" />
                <div
                  style={{
                    padding: '2px 12px',
                    backgroundColor: '#0D5CDD',
                    borderRadius: '15px',
                    borderWidth: '1px',
                    height: '32px',
                  }}
                >
                  <h5 style={{ color: '#FFF', marginLeft: '10px' }}>
                    องค์ประกอบของร่างกาย
                  </h5>
                </div>
              </Stack>
              <Table style={{ width: '100%' }}>
                <tr>
                  <td align={'center'} style={{ width: '48%' }}>
                    <div className=" text-[22px]">
                      <span style={{ color: '#0D5CDD' }}>น้ำหนัก</span>{' '}
                      <span>- kg</span>
                    </div>
                    <div className=" text-[22px]">
                      <span style={{ color: '#0D5CDD' }}>ส่วนสูง</span>{' '}
                      <span>- cm</span>
                    </div>
                  </td>
                  <td rowSpan={2} align={'center'}>
                    <div
                      style={{
                        width: '6px',
                        backgroundColor: '#0BEBFC',
                        borderRadius: '15px',
                        height: '80px',
                      }}
                    ></div>
                  </td>
                  <td align={'center'} style={{ width: '48%' }}>
                    <div className=" text-[28px]">
                      <span style={{ color: '#0D5CDD', alignItems: 'center' }}>
                        BMI
                      </span>{' '}
                    </div>
                    <div className=" text-[22px]">
                      <span> - </span>
                    </div>
                  </td>
                </tr>
              </Table>
              <SVG.BodyBmi className="w-[100%]" />
              <Stack direction={{ mobile: 'row', laptop: 'row' }}>
                <SVG.BtnBmiInput className="m-[8px] w-[50%]" />
                <SVG.BtnBmiHistory className="m-[8px] w-[50%]" />
              </Stack>
            </Stack>
          )}
        </div>
        <div className="w-[100%] laptop:w-[40%]  bg-cover ">
          <Stack
            direction={{ mobile: 'column', laptop: 'column' }}
            alignItems={'top'}
            className="gap-[18px]"
            style={{
              borderColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
            }}
          >
            <div>
              <Stack
                direction={{ mobile: 'column', laptop: 'column' }}
                className="gap-[18px] items-center"
              >
                <div
                  className="mt-[-15px]"
                  style={{
                    padding: '2px 12px',
                    backgroundColor: '#0D5CDD',
                    borderRadius: '15px',
                    borderWidth: '1px',
                  }}
                >
                  <h5 style={{ color: '#FFF' }}>สุขภาพโดยรวมของคุณ</h5>
                </div>

                <Stack
                  direction={{ mobile: 'row', laptop: 'row' }}
                  className="gap-[18px] items-center h-[290px]"
                >
                  <div className="w-[50%] mt-[-30%] laptop:mt-[0px]">
                    <ElementComponent.Image
                      fullImage={true}
                      src={Pics.BtnHumenMan}
                      disableClick
                      className="cursor-default ml-[8px] w-[267px]"
                    />
                    <div className="mt-[-75%] laptop:mt-[-80%] ml-[12%]">
                      {!overallHealthScorce.fetching &&
                      !overallHealthScorce.error ? (
                        <>
                          <ElementComponent.Image
                            fullImage={true}
                            src={
                              overallHealthScorce.data
                                .getHealthStatusOverallDiseaseScorces.length > 0
                                ? mapValue[
                                    Math.round(
                                      overallHealthScorce.data.getHealthStatusOverallDiseaseScorces[0].value.toPrecision(
                                        1,
                                      ),
                                    )
                                  ].bg
                                : Pics.BgNoneData
                            }
                            disableClick
                            className="cursor-default ml-[8px] w-[120px] laptop:w-[150px] "
                          />
                          <h5
                            className="-mt-[60%] laptop:mt-[-55%] ml-[25%]"
                            style={{
                              color: '#130101',
                              fontSize: '20px',
                              fontWeight: 'bold',
                            }}
                          >
                            {overallHealthScorce.data
                              .getHealthStatusOverallDiseaseScorces.length > 0
                              ? mapValue[
                                  overallHealthScorce.data.getHealthStatusOverallDiseaseScorces[0].value.toPrecision(
                                    1,
                                  )
                                ].value * 100
                              : '-'}
                            %
                          </h5>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <h5 style={{ color: '#2C71E1', fontSize: '22px' }}>
                      ผลลัพท์สุขภาพ
                    </h5>
                    {!overallHealthScorce.fetching &&
                    !overallHealthScorce.error ? (
                      <p
                        style={{
                          color:
                            overallHealthScorce.data
                              .getHealthStatusOverallDiseaseScorces.length > 0
                              ? mapValue[
                                  Math.round(
                                    overallHealthScorce.data.getHealthStatusOverallDiseaseScorces[0].value.toPrecision(
                                      1,
                                    ),
                                  )
                                ].color
                              : '#D1D1D1',
                          fontSize: '24px',
                        }}
                      >
                        {overallHealthScorce.data
                          .getHealthStatusOverallDiseaseScorces.length > 0
                          ? mapValue[
                              Math.round(
                                overallHealthScorce.data.getHealthStatusOverallDiseaseScorces[0].value.toPrecision(
                                  1,
                                ),
                              )
                            ].text
                          : 'ไม่มีข้อมูล'}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </Stack>
                {isMobile && (
                  <Stack
                    direction={{ mobile: 'row', laptop: 'row' }}
                    className="gap-[18px] items-center mt-[25%]"
                  >
                    <div className="w-[80%]  items-center">
                      <Link to={route.CREATE_HEALTH_PAGE_URL}>
                        <ElementComponent.Image
                          fullImage={true}
                          src={Pics.BtnAddBlood}
                          disableClick
                          className="w-full h-full cursor-default"
                        />
                      </Link>
                    </div>
                    <div className="w-[80%] items-center">
                      <ElementComponent.Image
                        fullImage={true}
                        disableClick
                        src={Pics.BtnPlan}
                        className=" w-full h-full cursor-default"
                      />
                    </div>
                  </Stack>
                )}
              </Stack>
            </div>
            {isMobile && (
              <>
                <Stack
                  direction={{ mobile: 'column', laptop: 'column' }}
                  alignItems={'top'}
                  className="gap-[18px]  m-[16px]"
                  style={{
                    borderColor: '#0D5CDD',
                    borderRadius: '15px',
                    borderWidth: '1px',
                    backgroundColor: '#FFF',
                  }}
                >
                  <Stack
                    direction={{ mobile: 'row', laptop: 'row' }}
                    className="mt-[-15px] ml-[37%]"
                  >
                    <div
                      style={{
                        padding: '2px 12px',
                        backgroundColor: '#0D5CDD',
                        borderRadius: '15px',
                        borderWidth: '1px',
                        height: '32px',
                      }}
                    >
                      <h5 style={{ color: '#FFF' }}>วางแผนสุขภาพ</h5>
                    </div>
                  </Stack>
                  <Grid
                    container
                    justifyContent="center"
                    columns={{ mobile: 2.2, desktop: 2.2 }}
                    gap="8px"
                    className="mb-[16px]"
                  >
                    {categorysList.map(
                      (item: { title: string; imgName: string; data }) => (
                        <Grid item mobile={1} desktop={1}>
                          <div>
                            {item.title == 'เบาหวาน' ? (
                              <div
                                onClick={() =>
                                  !isMobile
                                    ? setOpenDiabeteModal(true)
                                    : navigate(route.HISTORY_DIABETES_PAGE_URL)
                                }
                              >
                                <DiseaseComponent.DiseaseOverviewItems
                                  title="เบาหวาน"
                                  icon={Pics.I1}
                                  data={diabetesScorce}
                                />
                              </div>
                            ) : (
                              <DiseaseComponent.DiseaseOverviewItems
                                title={item.title}
                                icon={item.imgName}
                                data={item.data}
                              />
                            )}
                          </div>
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Stack>
              </>
            )}
          </Stack>
          {!isMobile && (
            <>
              <Stack
                direction={{ mobile: 'row', laptop: 'row' }}
                className="gap-[18px] items-center mt-[16px]"
              >
                <div className="w-[80%]  items-center">
                  <Link to={route.CREATE_HEALTH_PAGE_URL}>
                    <ElementComponent.Image
                      fullImage={true}
                      src={Pics.BtnAddBloodMobile}
                      disableClick
                      className="w-full h-full cursor-default"
                    />
                  </Link>
                </div>
                <div
                  className="w-[80%] items-center"
                  onClick={() => setOpenPlanModal(true)}
                >
                  <ElementComponent.Image
                    fullImage={true}
                    disableClick
                    src={Pics.BtnPlanMobile}
                    className=" w-full h-full cursor-default"
                  />
                </div>
              </Stack>
              <Stack
                direction={{ mobile: 'row', laptop: 'row' }}
                className="gap-[18px] items-center mt-[16px]"
              >
                <div className="w-[80%]  items-center">
                  <Link to={route.RECOMMEND_HEALTH_PAGE_URL}>
                    <ElementComponent.Image
                      fullImage={true}
                      src={Pics.BtnRecMobile}
                      disableClick
                      className="w-full h-full cursor-default"
                    />
                  </Link>
                </div>
                <div className="w-[80%] items-center">
                  <ElementComponent.Image
                    fullImage={true}
                    disableClick
                    src={Pics.BtnDrugMobile}
                    className=" w-full h-full cursor-default"
                  />
                </div>
              </Stack>
              <Stack
                direction={{ mobile: 'column', laptop: 'column' }}
                alignItems={'top'}
                className="gap-[18px] mt-[24px]"
                style={{
                  borderColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  backgroundColor: '#FFF',
                }}
              >
                <Stack
                  direction={{ mobile: 'row', laptop: 'row' }}
                  className="mt-[-15px] ml-[30%]"
                >
                  <div
                    style={{
                      padding: '2px 12px',
                      backgroundColor: '#0D5CDD',
                      borderRadius: '15px',
                      borderWidth: '1px',
                      height: '32px',
                    }}
                  >
                    <h5 style={{ color: '#FFF' }}>วางแผนสุขภาพ</h5>
                  </div>
                </Stack>
                <Grid
                  container
                  justifyContent="center"
                  columns={{ mobile: 2.2, desktop: 2.2 }}
                  gap="8px"
                  className="mb-[16px]"
                >
                  {categorysList.map(
                    (item: {
                      index: number
                      title: string
                      imgName: string
                      data
                    }) => (
                      <Grid item mobile={1} desktop={1}>
                        {/*<div>
                          {item.title == 'เบาหวาน' ? (
                            <div
                              onClick={() => {
                                !isMobile
                                  ? setOpenDiabeteModal(true)
                                  : navigate(route.HISTORY_DIABETES_PAGE_URL)
                                setDefualtDisease(item.index)
                              }}
                            >
                              <DiseaseComponent.DiseaseOverviewItems
                                title={item.title}
                                icon={item.imgName}
                                data={item.data}
                              />
                            </div>
                          ) : (
                            <DiseaseComponent.DiseaseOverviewItems
                              title={item.title}
                              icon={item.imgName}
                              data={item.data}
                            />
                          )}
                          </div>*/}
                        <div
                          onClick={() => {
                            !isMobile
                              ? setOpenDiabeteModal(true)
                              : navigate(route.HISTORY_DIABETES_PAGE_URL)
                            setDefualtDisease(item.index)
                          }}
                        >
                          <DiseaseComponent.DiseaseOverviewItems
                            title={item.title}
                            icon={item.imgName}
                            data={item.data}
                          />
                        </div>
                      </Grid>
                    ),
                  )}
                </Grid>
              </Stack>
            </>
          )}
          {!isMobile && (
            <Stack
              direction={{ mobile: 'column', laptop: 'column' }}
              alignItems={'top'}
              className="gap-[18px] items-center mt-[36px]"
              style={{
                borderColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
                backgroundColor: '#FFF',
              }}
            >
              <Stack
                direction={{ mobile: 'row', laptop: 'row' }}
                className="mt-[-15px]"
              >
                <SVG.IconBmi className="w-[48px] h-[48px] mt-[-8px] mr-[-18px] z-[1]" />
                <div
                  style={{
                    padding: '2px 12px',
                    backgroundColor: '#0D5CDD',
                    borderRadius: '15px',
                    borderWidth: '1px',
                    height: '32px',
                  }}
                >
                  <h5 style={{ color: '#FFF', marginLeft: '10px' }}>
                    องค์ประกอบของร่างกาย
                  </h5>
                </div>
              </Stack>
              {!weightTrasactionData.fetching &&
              !weightTrasactionData.error &&
              !heightTrasactionData.fetching &&
              !heightTrasactionData.error ? (
                <>
                  <Table style={{ width: '100%' }}>
                    <tr>
                      <td align={'center'} style={{ width: '48%' }}>
                        <div className=" text-[22px]">
                          <span style={{ color: '#0D5CDD' }}>น้ำหนัก</span>{' '}
                          <span>
                            {weightTrasactionData.data.getMedDataTransactions
                              .length > 0
                              ? weightTrasactionData.data
                                  .getMedDataTransactions[0].value
                              : '-'}{' '}
                            kg
                          </span>
                        </div>
                        <div className=" text-[22px]">
                          <span style={{ color: '#0D5CDD' }}>ส่วนสูง</span>{' '}
                          <span>
                            {heightTrasactionData.data.getMedDataTransactions
                              .length > 0
                              ? heightTrasactionData.data
                                  .getMedDataTransactions[0].value
                              : '-'}{' '}
                            cm
                          </span>
                        </div>
                      </td>
                      <td rowSpan={2} align={'center'}>
                        <div
                          style={{
                            width: '6px',
                            backgroundColor: '#0BEBFC',
                            borderRadius: '15px',
                            height: '80px',
                          }}
                        ></div>
                      </td>
                      <td align={'center'} style={{ width: '48%' }}>
                        <div className=" text-[28px]">
                          <span
                            style={{ color: '#0D5CDD', alignItems: 'center' }}
                          >
                            BMI
                          </span>{' '}
                        </div>
                        <div className=" text-[22px]">
                          <span>
                            {' '}
                            {weightTrasactionData.data.getMedDataTransactions
                              .length > 0 &&
                            heightTrasactionData.data.getMedDataTransactions
                              .length > 0
                              ? (
                                  weightTrasactionData.data
                                    .getMedDataTransactions[0].value /
                                  ((heightTrasactionData.data
                                    .getMedDataTransactions[0].value /
                                    100) *
                                    (heightTrasactionData.data
                                      .getMedDataTransactions[0].value /
                                      100))
                                ).toFixed(2)
                              : '-'}{' '}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </Table>
                  <ElementComponent.Image
                    fullImage={true}
                    src={
                      weightTrasactionData.data.getMedDataTransactions.length >
                        0 &&
                      heightTrasactionData.data.getMedDataTransactions.length >
                        0
                        ? getBmiImage(
                            weightTrasactionData.data.getMedDataTransactions[0]
                              .value /
                              ((heightTrasactionData.data
                                .getMedDataTransactions[0].value /
                                100) *
                                (heightTrasactionData.data
                                  .getMedDataTransactions[0].value /
                                  100)),
                          )
                        : Pics.Bmi0
                    }
                    disableClick
                    className="w-[100%]"
                  />
                </>
              ) : (
                <></>
              )}

              <Stack direction={{ mobile: 'row', laptop: 'row' }}>
                <div
                  className="m-[8px] w-[50%]"
                  onClick={() => setOpenBmiModal(true)}
                >
                  <SVG.BtnBmiInput className=" w-[100%]" />
                </div>
                <div
                  className="m-[8px] w-[50%]"
                  onClick={() => setOpenBmiHistoryModal(true)}
                >
                  <SVG.BtnBmiHistory className="w-[100%]" />
                </div>
              </Stack>
            </Stack>
          )}
          <Stack
            direction={{ mobile: 'column', laptop: 'column' }}
            alignItems={'top'}
            className="gap-[18px] items-center mt-[36px]"
            style={{
              borderColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
              padding: '25px',
              backgroundColor: '#FFF',
            }}
          >
            <div>
              <ElementComponent.Image
                fullImage={true}
                src={Pics.DemoGrpHeart}
                disableClick
                className="w-full h-full cursor-default mt-[-15%] laptop:mt-[-13%] ml-[4%]"
              />
            </div>
          </Stack>
        </div>
      </Stack>
      <ModalComponent.DiseaseModal
        open={openDiabeteModal}
        onClose={() => setOpenDiabeteModal(false)}
        defaultDisease={defualtDisease}
      />
      <ModalComponent.BmiModal
        open={openBmiModal}
        onClose={() => setOpenBmiModal(false)}
      />
      <ModalComponent.BmiHistoryModal
        weightTrasactionData={weightTrasactionData}
        heightTrasactionData={heightTrasactionData}
        open={openBmiHistoryModal}
        onClose={() => setOpenBmiHistoryModal(false)}
      />
      <ModalComponent.PlanModal
        open={openPlanModal}
        onClose={() => setOpenPlanModal(false)}
      />
    </>
  )
}

function OverviewPage() {
  const [medicalQueryData] = useQuery(OverviewQuery.getUserOverviewData())
  const [userSettings, excuteUserSettingsQuery] = useQuery(
    UserQuery.getUserSettings(),
  )
  const [, executeUpdateUserSettingsMutation] = useMutation(
    UserMutate.updateUserSettings,
  )
  const [askModalState, setAskModalState] = useState(false)
  const [enterpriseModalState, setEnterpriseModalState] = useState(false)

  useEffect(() => {
    if (userSettings.fetching || userSettings.error) return

    if (
      userSettings?.data?.getUser?.userSettings
        ?.isAskForConnectWithEnterprise === undefined
    ) {
      setAskModalState(true)
      return
    }

    setAskModalState(false)
    return () => {}
  }, [userSettings])

  const handleClickModal = async (confirm: boolean) => {
    setAskModalState(false)

    if (confirm) setEnterpriseModalState(true)

    await executeUpdateUserSettingsMutation({
      data: {
        userSettings: {
          upsert: {
            update: {
              isAskForConnectWithEnterprise: {
                set: confirm,
              },
            },
            create: {
              isAskForConnectWithEnterprise: confirm,
            },
          },
        },
      },
      where: {
        uId: '',
      },
    })
    excuteUserSettingsQuery()
  }

  return (
    <div className="w-full">
      <HealthCard
        fetching={medicalQueryData.fetching}
        error={medicalQueryData.error}
        cardData={medicalQueryData.data?.getUser}
      />

      <AlertComponent.ConfirmModal
        icon="mdi:file-link"
        text={`ระบบสามารถเชื่อมต่อข้อมูลกับสถานพยาบาลได้ ต้องการเชื่อมต่อหรือไหม ?`}
        open={askModalState}
        onClose={() => handleClickModal(false)}
        handleCancel={() => handleClickModal(false)}
        handleConfirm={() => handleClickModal(true)}
        color="text-green-main"
      />
      <ModalComponent.EnterpriseTreatmentModal
        open={enterpriseModalState}
        onClose={() => setEnterpriseModalState(false)}
        enterpriseTreatementOwnPlaceList={[]}
      />
    </div>
  )
}

export default OverviewPage

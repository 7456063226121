import React from 'react'
import { Drawer, Stack, Divider } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'

import { route } from 'settings'

interface UserMenuMobileProps {
  open: boolean
  onClose: Function
}

interface UserMenuProps {
  menu: any
}

const menuList = [
  {
    header: 'ข้อมูลบัตรคนรักสุขภาพ',
    child: [
      {
        title: 'ภาพรวม',
        icon: 'fluent-mdl2:text-document-shared',
        path: route.OVERVIEW_PAGE_URL,
      },
      {
        title: 'ข้อมูลสุขภาพ',
        icon: 'ri:shield-cross-line',
        path: route.HEALTH_PAGE_URL,
      },
    ],
  },
  {
    header: 'บัญชีของฉัน',
    spacificCheck: true,
    child: [
      {
        title: 'ข้อมูลส่วนตัว',
        icon: 'ic:baseline-person',
        path: route.PROFILE_PAGE_URL,
      },
      {
        title: 'เปลี่ยนรหัสผ่าน',
        icon: 'material-symbols:lock',
        path: route.CHANGE_PASSWORD,
      },
    ],
  },
]

function UserMenu({ menu }: UserMenuProps) {
  const location = useLocation()
  const path: string = `${location.pathname}${location.search}`

  return (
    <div className="flex flex-col gap-[15px]">
      <p className="font-bold text-[20px]">{menu.header}</p>

      {menu.child.map((item: any, index: number) => (
        <NavLink
          to={item.path}
          key={`user-memu-${index}`}
          className={({ isActive }) =>
            isActive && (menu.spacificCheck ? item.path === path : true)
              ? 'text-blue-main'
              : ''
          }
        >
          <div className="flex items-center gap-[10px]">
            <Icon icon={item.icon} className="text-[25px]" />
            <p className="text-[20px]">{item.title}</p>
          </div>
        </NavLink>
      ))}
    </div>
  )
}

export default function UserMenuMobile({ open, onClose }: UserMenuMobileProps) {
  const isProfile = Boolean(location.pathname.includes(route.PROFILE_PAGE_URL))
  return (
    <>
      <div className="mt-20"></div>
      <Drawer
        anchor="top"
        open={open}
        onClose={() => onClose()}
        sx={{ zIndex: 1600 }}
      >
        <div className="h-screen">
          <div
            style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, .1)' }}
            className="w-full h-[90px] px-[31px] bg-white-main flex justify-between items-center"
          >
            <p className="cursor-pointer text-[20px] font-bold">
              {isProfile ? 'บัญชีของฉัน' : 'ข้อมูลบัตรคนรักสุขภาพ'}
            </p>
            <Icon
              icon="fluent-mdl2:status-circle-error-x"
              className="text-[30px] cursor-pointer"
              onClick={() => onClose()}
            />
          </div>
          <Stack
            direction="column"
            gap="30px"
            divider={<Divider className="my-[30px]" />}
            className="mt-[30px] px-[20px]"
          >
            {menuList.map((item, index: number) => (
              <UserMenu menu={item} key={`menu-list-${index}`} />
            ))}
            <p className="text-[20px]">ออกจากระบบ</p>
          </Stack>
        </div>
      </Drawer>
    </>
  )
}

export const uploadMedicalImage = `
  mutation createMedicalData(
    $data: UserMedicalDataCreateInput!
    $files: [Upload!]
  ) {
    createMedicalData(data: $data, files: $files) {
      umdId
      imageFile
    }
  }
`

export const creatMedicalData = `
mutation createMedicalData($data: UserMedicalDataCreateInput!, $files: [Upload!]) {
  createMedicalData(data: $data, files: $files) {
    imageFile
  }
}
`

export const creatFoodData = `
mutation createUserFoodData($data: UserFoodDataCreateInput!) {
  createUserFoodData(data: $data) {
    ufId
  }
}
`

import React, { useState } from 'react'

import { Stack } from '@mui/material'
import { useQuery } from 'urql'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import ReactSpeedometer from 'react-d3-speedometer'

import { OverviewQuery } from 'services/graphql/overview'
import { ElementComponent } from 'components'
import NormalDiseaseCard from 'components/modal/NormalDiseaseCard'
import AssociateDiseaseCard from 'components/modal/AssociateDiseaseCard'
import * as Pics from 'assets/picture'

interface DiseaseModalProps {
  open: boolean
  onClose: Function
  defaultDisease: number
}

interface DefaultProps {
  defaultDisease: number
}

function HealthCard({ defaultDisease }: DefaultProps) {
  const mapValue = {
    '0': {
      value: 0.2,
      valueStr: 20,
      color: '#E40009',
    },
    '1': {
      value: 0.4,
      color: '#F19701',
    },
    '2': {
      value: 0.6,
      color: '#FBFF01',
    },
    '3': {
      value: 0.8,
      color: '#CDD801',
    },
    '4': {
      value: 1.0,
      color: '#69ae33',
    },
  }
  const categorysList: { title: string; imgName: string; dId: string }[] = [
    {
      title: 'เบาหวาน',
      imgName: Pics.CategoryIco01,
      dId: 'Diabetes mellitus (DM)',
    },
    {
      title: 'ไขมัน',
      imgName: Pics.CategoryIco02,
      dId: 'Dyslipidemia',
    },
    {
      title: 'ความดัน',
      imgName: Pics.CategoryIco03,
      dId: 'Hypertension',
    },
    {
      title: 'ตับ',
      imgName: Pics.CategoryIco04,
      dId: 'Chronic Liver Disease/Cirrhosis (CLD)',
    },
    {
      title: 'ไต',
      imgName: Pics.CategoryIco05,
      dId: 'Chronic Kidney Disease (CKD)',
    },
    /*{
      title: 'หัวใจ',
      imgName: Pics.CategoryIco06,
    },
    {
      title: 'อ้วน',
      imgName: Pics.CategoryIco07,
    },
    {
      title: 'กระดูก',
      imgName: Pics.CategoryIco08,
    },*/
  ]
  const [selectDisease, setSelectDisease] = useState<string>(
    categorysList[defaultDisease].dId,
  )
  const [diseaseTitle, setDiseaseTitle] = useState<string>(
    categorysList[defaultDisease].title,
  )

  const [diabetesScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(selectDisease),
  )

  const [diabetesData] = useQuery(
    OverviewQuery.getOneDiseaseData(selectDisease),
  )

  const [diseaseData] = useQuery(OverviewQuery.getOneDiseaseData(selectDisease))

  return (
    <>
      <Stack
        direction={{ mobile: 'column', laptop: 'column' }}
        className="gap-5 bg-white-main rounded-[15px] p-[8px]"
        alignItems={'top'}
      >
        {/* Overall score of the diease */}
        <Stack
          direction={{ mobile: 'column', laptop: 'row' }}
          className="gap-5"
          alignItems={'top'}
        >
          <div className="w-[100%] laptop:w-[40%]  bg-cover ">
            <Stack
              direction={{ mobile: 'column', laptop: 'column' }}
              alignItems={'top'}
              className="gap-5 mt-8 bg-white-main border border-blue-main rounded-xl"
            >
              <div>
                <Stack
                  direction={{ mobile: 'column', laptop: 'column' }}
                  className="gap-[18px] items-center"
                >
                  <div className="-mt-3.5 bg-blue-main border border-transparent rounded-xl">
                    <h5 className="text-white-main mx-3.5">
                      สุขภาพโดยรวมของโรค{diseaseTitle}
                    </h5>
                  </div>

                  {!diabetesScorce.fetching &&
                  !diabetesScorce.error &&
                  diabetesScorce.data.getHealthStatusDiseaseScorces.length >
                    0 ? (
                    <div className="h-48">
                      <ReactSpeedometer
                        maxSegmentLabels={0}
                        segments={1000}
                        maxValue={100}
                        value={
                          mapValue[
                            diabetesScorce.data.getHealthStatusDiseaseScorces[0].value.toPrecision(
                              1,
                            )
                          ].value * 100
                        }
                        textColor={'#000'}
                        currentValueText={'${value}%'}
                      />
                    </div>
                  ) : (
                    <ReactSpeedometer
                      maxSegmentLabels={0}
                      segments={1000}
                      maxValue={100}
                      value={0}
                      textColor={'#000'}
                      currentValueText={'ไม่มีข้อมูล'}
                    />
                  )}
                </Stack>
              </div>
            </Stack>
            <Stack
              direction={{ mobile: 'column', laptop: 'column' }}
              alignItems={'top'}
              className="gap-5 items-center mt-9 bg-white-main border border-blue-main rounded-xl"
            >
              <div className="-mt-3.5 bg-blue-main border border-transparent rounded-xl">
                <h5 className="text-white-main mx-3.5">เลือกโรคดูผลเลือด</h5>
              </div>
              <div>
                <Swiper
                  className="w-[300px] laptop:w-[400px]  bg-cover"
                  slidesPerView={4}
                  spaceBetween={1}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Navigation, Pagination]}
                >
                  {categorysList.map(
                    (item: { title: string; imgName: string; dId: string }) => (
                      <SwiperSlide
                        className="mb-[18px]"
                        onClick={() => {
                          setSelectDisease(item.dId)
                          setDiseaseTitle(item.title)
                        }}
                      >
                        <ElementComponent.Image
                          fullImage={false}
                          src={item.imgName}
                          className="h-[90px]"
                        />
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
              </div>
            </Stack>
          </div>
        </Stack>
        {/* Normal Disease */}
        {!diabetesData.fetching && !diabetesData.error ? (
          <>
            {/* Disease attribute */}
            <NormalDiseaseCard diseaseData={diseaseData.data.getDiseases[0]} />
            {/* Associate Disease */}
            {diabetesData?.data?.getDiseases[0]?.associateDiease?.length >
              0 && (
              <>
                {/* Title Associate Disease */}
                <Stack
                  direction={{ mobile: 'row', laptop: 'row' }}
                  className="items-center mt-4 justify-center"
                >
                  <div className="-mt-3.5 bg-blue-main border border-transparent rounded-xl">
                    <h5 className="text-white-main mx-3.5">
                      {`ตรวจคัดกรองภาวะแทรกซ้อน${diabetesData.data.getDiseases[0].thaiName}`}
                    </h5>
                  </div>
                </Stack>
                {/* Disease Attribute for Associate Disease */}
                <AssociateDiseaseCard
                  diseaseData={diabetesData.data.getDiseases[0].associateDiease}
                />
              </>
            )}
          </>
        ) : (
          'Loading'
        )}
      </Stack>
    </>
  )
}

function DiseaseModal({ open, onClose, defaultDisease }: DiseaseModalProps) {
  return (
    <ElementComponent.ModalFormV2 open={open} onClose={() => onClose()}>
      <div className="flex flex-col items-center w-screen max-h-[600px]">
        <HealthCard defaultDisease={defaultDisease} />
      </div>
    </ElementComponent.ModalFormV2>
  )
}

export default DiseaseModal

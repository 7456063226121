import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Stack } from '@mui/material'
import { Icon } from '@iconify/react'

import { ProfileType } from 'types'
import { route } from 'settings'
import { useResponsive } from 'hooks'

import { UserProfile, ChangePassword } from 'pages/profile/profileOption'

interface ProfileMenuProps {
  options: ProfileType.ProfileOption
  onChangeOptions: Function
}

function ProfileMenu({ options, onChangeOptions }: ProfileMenuProps) {
  return (
    <div
      className="bg-white-main rounded-[10px] fixed w-[300px] z-10 h-fit"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <p className="font-bold text-[18px] p-[28px]">บัญชีของฉัน</p>

      <div className="border-t-[1px] border-gray-light py-[20px]">
        <div
          className={`flex items-center gap-[10px] cursor-pointer ${
            options === ProfileType.ProfileOption.PROFILE && 'text-blue-main'
          } px-[30px] py-[10px] hover:bg-blue-light`}
          onClick={() => onChangeOptions(ProfileType.ProfileOption.PROFILE)}
        >
          <Icon icon="ic:baseline-person" className="text-[18px]" />
          <p className="text-[18px]">ข้อมูลส่วนตัว</p>
        </div>
        <div
          className={`flex items-center gap-[10px] mt-[5px] cursor-pointer ${
            options === ProfileType.ProfileOption.RESETPASSWORD &&
            'text-blue-main'
          } px-[30px] py-[10px] hover:bg-blue-light`}
          onClick={() =>
            onChangeOptions(ProfileType.ProfileOption.RESETPASSWORD)
          }
        >
          <Icon icon="material-symbols:lock" className="text-[18px]" />
          <p className="text-[18px]">เปลี่ยนรหัสผ่าน</p>
        </div>
      </div>
    </div>
  )
}

export default function ProfilePage() {
  const { isLaptop } = useResponsive()
  const location = useLocation()

  const [options, setOptions] = useState<ProfileType.ProfileOption>(
    ProfileType.ProfileOption.PROFILE,
  )

  useEffect(() => {
    if (location.pathname === route.PROFILE_PAGE_URL) {
      if (location.search === '') setOptions(ProfileType.ProfileOption.PROFILE)
      else setOptions(ProfileType.ProfileOption.RESETPASSWORD)
    }
  }, [location])

  const onChange = (optionSelected: ProfileType.ProfileOption) => {
    setOptions(optionSelected)
  }

  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'row' }}
      className="bg-white-dark h-full flex mt-[-90px] laptop:mt-0 laptop:pl-[50px] pt-[60px] pb-[90px]"
    >
      {isLaptop && <ProfileMenu options={options} onChangeOptions={onChange} />}

      <div className="laptop:ml-[345px] w-full px-[18px] pt-0 laptop:pl-0 laptop:pr-[50px]">
        {options === ProfileType.ProfileOption.PROFILE ? (
          <UserProfile />
        ) : (
          <ChangePassword />
        )}
      </div>
    </Stack>
  )
}

import { Grid } from '@mui/material'
import { ElementComponent } from 'components'

const mapStringValue = {
  '0': {
    value: 20,
    color: '#BD0202',
  },
  '1': {
    value: 40,
    color: '#F4A648',
  },
  '2': {
    value: 60,
    color: '#F2EB49',
  },
  '3': {
    value: 80,
    color: '#3CE93C',
  },
  '4': {
    value: 100,
    color: '#06C755',
  },
}
interface DiseaseItemComponentProps {
  title: string
  icon: string
  className?: string
  data
}

function DiseaseItemComponent({
  title,
  icon,
  className = 'w-[100%] pl-[8px] pt-[4px]',
  data,
}: DiseaseItemComponentProps) {
  return (
    <div
      className={className}
      style={{
        borderColor: '#0D5CDD',
        borderRadius: '15px',
        borderWidth: '1px',
        backgroundColor: '#FFF',
      }}
    >
      <Grid
        container
        justifyContent="center"
        columns={{ mobile: 2.2, desktop: 2.2 }}
        gap="8px"
      >
        <Grid item mobile={1} desktop={1}>
          {title}
          {!data.fetching &&
          !data.error &&
          data.data.getHealthStatusDiseaseScorces.length > 0 ? (
            <>
              <p
                style={{
                  fontSize: '24px',
                  color:
                    mapStringValue[
                      Math.round(
                        data.data.getHealthStatusDiseaseScorces[0].value.toPrecision(
                          1,
                        ),
                      )
                    ].color,
                }}
              >
                {
                  mapStringValue[
                    Math.round(
                      data.data.getHealthStatusDiseaseScorces[0].value.toPrecision(
                        1,
                      ),
                    )
                  ].value
                }{' '}
                %
              </p>
            </>
          ) : (
            <p style={{ fontSize: '24px' }}>- %</p>
          )}
        </Grid>
        <Grid item mobile={1} desktop={1}>
          <ElementComponent.Image
            fullImage={true}
            src={icon}
            disableClick
            className="cursor-default w-[70px]"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default DiseaseItemComponent

import React from 'react'
import { Stack } from '@mui/material'

import { BannerV2ImgRight, SectorBackground } from 'assets/picture'
import { BannerV2TextLeft, BannerV2BtnRegister } from 'assets/svg'

function Banner() {
  return (
    <Stack
      direction={{ mobile: 'row', laptop: 'row' }}
      spacing={{ mobile: '30px' }}
      className="items-center laptop:justify-between px-[20px] laptop:px-[80px] mb-[100px]"
      style={{
        backgroundColor: '#FFFFFF',
        backgroundImage: `url(${SectorBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
      }}
    >
      <div className="h-full mt-[65px] laptop:mb-[50px] laptop:mt-[0px] laptop:ml-[50px] text-white-main">
        <div className="flex flex-col items-center laptop:text-start text-center laptop:items-start">
          <div className="flex items-center gap-[10px] mb-[35px]">
            <div className=" w-[155px] laptop:w-[385px]  mt-[30px]">
              <BannerV2TextLeft className="w-full h-full" />
            </div>
          </div>
          <div className="mb-[25px] desktop:ml-[60%] laptop:ml-[60%]">
            <a href="/register">
              <BannerV2BtnRegister style={{ width: '160px' }} />
            </a>
          </div>
        </div>
      </div>

      <div className="relative w-[100%] laptop:w-[380px] desktop:w-[660px] laptop:pt-0">
        <img src={BannerV2ImgRight} style={{ width: '100%' }} />
      </div>
    </Stack>
  )
}

export default Banner

import React from 'react'
import { Typography, Stack } from '@mui/material'
import * as SVG from 'assets/svg'
import { BtnPersonalDocter, SectorBackground } from 'assets/picture'
import { ElementComponent } from 'components'

function PeronalDocter() {
  return (
    <div
      className="text-center h-auto  pb-[100px] bg-white-main"
      style={{
        backgroundColor: '#FFFFFF',
        backgroundImage: `url(${SectorBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
      }}
    >
      <div className="flex justify-center p-[20px]">
        <div className="w-full max-w-[700px] laptop:max-w-[900px]">
          <Typography variant="subtitle1">
            <p
              className="font-bold mb-[20px]"
              style={{ color: '#0D5CDD' }}
              id="personaldoctor"
            >
              Personal doctor
            </p>
          </Typography>
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
            วางแผนการดูแลสุขภาพกับแพทย์ผู้เชี่ยวชาญ
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: '16px' }}>
            อุ่นใจใกล้หมอ เสมือนมีหมอประจำตัวร่วมปรึกษา
            และวางแผนการดูเเลสุขภาพเเบบตัวต่อตัวกับแพทย์ผู้เชี่ยวชาญเพื่อการดูเเลตัวเองได้อย่างถูกต้อง
            เเละ ปลอดภัยมากขึ้น
          </Typography>
        </div>
      </div>

      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px] px-[30px]  justify-center items-center"
      >
        <div className="w-[90%] laptop:w-[40%]  bg-cover">
          <ElementComponent.Image
            fullImage={true}
            src={BtnPersonalDocter}
            disableClick
            className="rounded-[15px] w-full h-full cursor-move"
          />
        </div>
      </Stack>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px] px-[30px]  justify-center items-center"
      >
        <a href="#">
          <SVG.BtnReadMore />
        </a>
      </Stack>
    </div>
  )
}

export default PeronalDocter

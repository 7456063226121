export const createEnterpriseTreatmentPlaceTransaction = `
  mutation createEnterpriseTreatmentPlaceTransaction($data: EnterpriseTreatmentPlaceTransactionCreateInput!) {
    createEnterpriseTreatmentPlaceTransaction(data: $data) {
      etptId
    }
  }
`

export const updateEnterpriseTreatmentPlaceTransaction = `
  mutation updateEnterpriseTreatmentPlaceTransaction($data: EnterpriseTreatmentPlaceTransactionUpdateInput!,  $where: EnterpriseTreatmentPlaceTransactionWhereUniqueInput!) {
    updateEnterpriseTreatmentPlaceTransaction(data: $data, where: $where) {
      etptId
    }
  }
`

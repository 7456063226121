import { Stack } from '@mui/material'

import * as Pics from 'assets/picture'
import { useEffect, useState } from 'react'
interface DiseaseItemComponentProps {
  title: string
  score: number
}
function SymptomItem({ title, score }: DiseaseItemComponentProps) {
  let [items, setItems] = useState([])
  const subDiseaseValue = {
    โรคเบาหวานลงเท้า: {
      title: 'โรคเบาหวานลงเท้า',
      detail:
        'โรคเบาหวานลงเท้า คือ ภาวะแทรกซ้อนจากโรคเบาหวาน ที่ทำลายระบบประสาทส่วนปลายทำให้เสื่อมอย่างรวมเร็ว มีอาการ',
      syms: [
        'ชาปลายมือ ปลายเท้า',
        'ปวดแสบร้อนที่มือและเท้า',
        'เท้าแห้ง แตกผิดปกติ',
        'แผลหายช้า',
      ],
      img: Pics.Ico0001,
    },
    โรคเบาหวานลงไต: {
      title: 'โรคเบาหวานลงไต',
      detail:
        'โรคเบาหวานลงไต คือ ภาวะแทรกซ้อนของโรคเบาหวาน ที่ควบคุมได้ไม่ดีและระยะเวลานาน ทำให้เกิดการเปลี่ยนแปลงของสรีระไต ทำให้การทำงานของไตแย่ลง มีอาการ',
      syms: [
        'ปัสสาวะเป็นฟองมาก',
        'บวมบริเวณข้อเท้าหรือใบหน้า',
        'มีความดันโลหิตสูงหรือควบคุมความดันโลหิตด้วยยายากขึ้น',
      ],
      img: Pics.Ico0002,
    },
    โรคเบาหวานขึ้นตา: {
      title: 'โรคเบาหวานขึ้นตา',
      detail:
        'โรคเบาหวานขึ้นตา คือ ภาวะเทรกซ้อนของโรคเบาหวาน ที่โดยเกิดจากการมีระดับน้ำตาลในเลือดสูงเป็นเวลานาน ส่งผลให้หลอดเลือดที่จอตาได้รับความเสียหาย มีอาการ',
      syms: [
        'มองเห็นจุดหรือเส้นสีดำคล้ายหยากไย่ลอยไปมา',
        'มองเห็นภาพบิดเบี้ยว',
        'ตามัว การมองเห็นแย่ลง',
        'แยกแยะสีได้ยากขึ้น',
        'เห็นภาพมืดเป็นบางจุด',
      ],
      img: Pics.Ico0003,
    },
    ภาวะเลือดเป็นกรด: {
      title: 'ภาวะเลือดเป็นกรด',
      detail:
        'ภาวะเลือดเป็นกรด คือ การมีระดับน้ำตาลในเลือด สูง ร่วมกับภาวะเลือดเป็นกรดจากการที่มีสาร คีโตนสะสมในเลือด มีอาการ',
      syms: [
        'คลื่นไส้ อาเจียน',
        'คอแห้ง หิวน้ำบ่อย',
        'ปัสสาวะบ่อย',
        'ลมหายใจมีกลิ่นหวาน',
        'หายใจหอบเหนื่อยและลึก',
      ],
      img: Pics.Ico0004,
    },
    โรคหลอดเลือดสมองตีบ: {
      title: 'โรคหลอดเลือดสมองตีบ/แตก',
      detail:
        'โรคหลอดเลือดสมองตีบ/แตก คือ โรคที่เกิดจากการที่เลือดไปเลี้ยงสมองไม่เพียงพอทำให้ขาดเลือด หรือความดันเลือดสูง จนทำให้หลอดเลือดในสมองแตก เกิดเลือดคลั่งในสมอง มีอาการ',
      syms: [
        'หน้าเบี้ยว ปากเบี้ยว',
        'แขน ขา อ่อนแรงครึ่งซีก',
        'ชา รับรูเความรู้สึกน้องลงครึ่งซีก',
        'ปวดศีรษะอย่างรุนแรง',
        'ซึมหรือหมดสติอย่างเฉียบพลัน',
      ],
      img: Pics.Ico0005,
    },
    โรคหัวใจขาดเลือด: {
      title: 'โรคหัวใจขาดเลือด',
      detail:
        'โรคหัวใจขาดเลือด คือ โรคที่เกิดจากการอุดตันของหลอดเลือดบริเวณหัวใจ เช่นจาก ไขมันหรือลิ่มเลือด ทำให้กล้ามเนื้อหัวใจขาดเลือดเฉียบพลัน มีอาการ',
      syms: [
        'เจ็บแน่นหน้าอกข้างซ้าย ร้าวไปกรามหรือไหล่ซ้าย',
        'ใจสั่น เหงื่อออก',
        'หายใจลำบาก',
        'เวียนศีรษะ คลื่นไส้ อาเจียน',
      ],
      img: Pics.Ico0006,
    },
    โรคหลอดเลือดส่วนปลาย: {
      title: 'โรคหลอดเลือดส่วนปลาย',
      detail:
        'โรคหลอดเลือดส่วนปลาย คือ โรคที่เกิดจากการอุดตันของหลอดเลือดแดงที่ไปเลี้ยงแขนหรือขา เช่นจาก ไขมันหรือลิ่มเลือด ทำให้เลือดไปเลี้ยงน้อยลง มีอาการ',
      syms: [
        'ปวดขามากขึ้นเวลาเดิน',
        'เท้าหรือมือสีคล้ำขึ้น',
        'ผิวหนังรอบแผลสีคล้ำขึ้น หายช้า',
        'ผิวหนังเย็น ซีด ',
      ],
      img: Pics.Ico0007,
    },
    ภาวะเลือดออกทางเดินอาหาร: {
      title: 'ภาวะเลือดออกทางเดินอาหาร',
      detail:
        'ภาวะเลือดออกทางเดินอาหาร คือ ภาวะแทรกซ้อนของหลายโรค ที่ทำให้หลอดเลือดบริเวณทางเดินอาหารแตกและเกิดเลือดออกทางเดินอาหาร มีอาการ',
      syms: [
        'อาเจียนเป็นเลือด',
        'ถ่ายดำหรือถ่ายมีเลือดปน',
        'คลื่นไส้ อาเจียน',
        'ผิวหนังเย็น ซีดทั่วร่างกาย',
      ],
      img: Pics.Ico0008,
    },
    ภาวะหัวใจเต้นผิดจังหวะ: {
      title: 'ภาวะหัวใจเต้นผิดจังหวะ',
      detail:
        'ภาวะหัวใจเต้นผิดจังหวะ คือ ความผิดปกติของกระแสไฟฟ้าในหัวใจ หรือไฟฟ้า ในหัวใจลัดวงจร มีอาการ',
      syms: [
        'ใจสั่นริ้วๆ',
        'เจ็บแน่นหน้าอก',
        'เป็นลม หมดสติ อ่อนเพลีย',
        'คลำชีพจรได้ไม่สม่ำเสมอ',
      ],
      img: Pics.Ico0009,
    },
  }
  useEffect(() => {
    if (title === 'โรคเบาหวาน') {
      if (score > 70) {
        const item = [
          subDiseaseValue['โรคเบาหวานลงเท้า'],
          subDiseaseValue['โรคเบาหวานลงไต'],
          subDiseaseValue['โรคเบาหวานขึ้นตา'],
        ]
        setItems(item)
      } else {
        const item = [
          subDiseaseValue['โรคเบาหวานลงเท้า'],
          subDiseaseValue['โรคเบาหวานลงไต'],
          subDiseaseValue['โรคเบาหวานขึ้นตา'],
          subDiseaseValue['ภาวะเลือดเป็นกรด'],
        ]
        setItems(item)
      }
    }

    if (title === 'โรคความดันโลหิตสูง') {
      if (score < 70) {
        const item = [
          subDiseaseValue['โรคหลอดเลือดสมองตีบ'],
          subDiseaseValue['โรคหัวใจขาดเลือด'],
        ]
        setItems(item)
      }
    }

    if (title === 'โรคไขมันในเลือดสูง') {
      if (score < 60) {
        const item = [
          subDiseaseValue['โรคหลอดเลือดสมองตีบ'],
          subDiseaseValue['โรคหัวใจขาดเลือด'],
          subDiseaseValue['โรคหลอดเลือดส่วนปลาย'],
        ]
        setItems(item)
      } else {
        const item = [subDiseaseValue['โรคหลอดเลือดส่วนปลาย']]
        setItems(item)
      }
    }

    if (title === 'โรคตับ') {
      if (score < 50) {
        const item = [subDiseaseValue['ภาวะเลือดออกทางเดินอาหาร']]
        setItems(item)
      }
    }

    if (title === 'โรคไต') {
      if (score < 60) {
        const item = [subDiseaseValue['ภาวะหัวใจเต้นผิดจังหวะ']]
        setItems(item)
      }
    }
  }, [items])

  return (
    <div className="mt-8">
      {items.map((itemDetail) => (
        <Stack
          direction={{ mobile: 'column', laptop: 'column' }}
          alignItems={'top'}
          className="gap-[18px] items-center mt-[36px]"
          style={{
            borderColor: '#0D5CDD',
            borderRadius: '15px',
            borderWidth: '1px',
            backgroundColor: '#FFF',
          }}
        >
          <Stack
            direction={{ mobile: 'row', laptop: 'row' }}
            className="mt-[-15px]"
          >
            <img
              src={itemDetail.img}
              style={{
                borderColor: '#0D5CDD',
                borderRadius: '45px',
                borderWidth: '1px',
                backgroundColor: '#FFF',
              }}
              className="w-[48px] h-[48px] mt-[-8px] mr-[-18px] z-[2]"
            />

            <div
              style={{
                padding: '2px 12px',
                backgroundColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
                height: '32px',
              }}
            >
              <h5 style={{ color: '#FFF', marginLeft: '10px' }}>
                {itemDetail.title}
              </h5>
            </div>
          </Stack>
          <div className=" p-[8px]">
            <b>
              <p className="text-black-main mb-[8px]">{itemDetail.detail}</p>
              {itemDetail.syms.map((itemSyms) => (
                <p className="text-black-main">• {itemSyms}</p>
              ))}
            </b>
          </div>
        </Stack>
      ))}
    </div>
  )
}

export default SymptomItem

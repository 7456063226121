export enum RegiseterStatus {
  REGISTER = 'REGISTER',
  AUTH = 'AUTH',
  SUCCESS = 'SUCCESS',
}

export type ProvinceListType = {
  id: number
  name: string
}

export type DistrictType = {
  name: string
}

export type RegisterForm = {
  prefix: string
  firstname: string
  lastname: string
  gender: string
  birthdate: Date | string
  phone: string
  province: string
  district: string
  password: string
  confirmPassword: string
}

export type ProvineType = {
  nameTH: string
  nameEN: string
}
export interface FormProps {
  form: any
  disable?: any
  isLoading: boolean
}

export type RegisterErrorMessage = {
  title: string
  description: string
}

import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'urql'
import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { ElementComponent, AlertComponent } from 'components'
import {
  EnterprisePlaceQuery,
  EnterprisePlaceMutate,
} from 'services/graphql/enterprisePlace'

interface EnterpriseTreatmentFormInterface {
  ercId: string
  citizenId: string
}

interface EnterpriseTreatementOwnPlaceListInterface {
  etptId: string
  ercId: string
  citizenId: string
}

interface EnterpriseTreatmentModalInterface {
  open: boolean
  onClose: Function
  enterpriseTreatementOwnPlaceList: EnterpriseTreatementOwnPlaceListInterface[]
}

const validationSchema = yup.object({
  ercId: yup.string().required('กรุณาเลือกค่าสถานพยาบาล'),
  citizenId: yup
    .string()
    .length(13, 'กรุณากรอกเลขบัตรประชาชน 13 หลัก')
    .required('กรุณากรอกเลขบัตรประชาชน'),
})

function EnterpriseTreatmentModal({
  open,
  onClose,
  enterpriseTreatementOwnPlaceList,
}: Readonly<EnterpriseTreatmentModalInterface>) {
  const [enterprisePlaceOption, setEnterprisePlaceOption] = useState<
    ElementComponent.SelectBar.OptionSelectType[]
  >([])

  const [enterprisePlaceQuery] = useQuery(
    EnterprisePlaceQuery.getEnterpriseTreatmentPlaces(),
  )
  const [, executeCreateEnterpriseTreatmentPlaceTransactionMutation] =
    useMutation(EnterprisePlaceMutate.createEnterpriseTreatmentPlaceTransaction)
  const [, executeUpdateEnterpriseTreatmentPlaceTransactionMutation] =
    useMutation(EnterprisePlaceMutate.updateEnterpriseTreatmentPlaceTransaction)

  const [successModalState, setSuccessModalState] = useState(false)

  const handleClose = () => {
    onClose()
  }

  const onSubmit = async (value: EnterpriseTreatmentFormInterface) => {
    const isExist = enterpriseTreatementOwnPlaceList.find(
      (item) => item.ercId === value.ercId,
    )

    if (!isExist) {
      await executeCreateEnterpriseTreatmentPlaceTransactionMutation({
        data: {
          user: {
            connect: {
              uId: '',
            },
          },
          citizenId: value.citizenId,
          enterprise_hnId: '',
          enterpriseTreatmentPlace: {
            connect: {
              ercId: value.ercId,
            },
          },
        },
      })
    } else {
      const etptId = isExist.etptId
      await executeUpdateEnterpriseTreatmentPlaceTransactionMutation({
        where: {
          etptId: etptId,
        },
        data: {
          citizenId: {
            set: value.citizenId,
          },
        },
      })
    }
    handleClose()
    setSuccessModalState(true)
  }

  useEffect(() => {
    ;(async () => {
      if (!enterprisePlaceQuery.fetching) {
        const { getEnterpriseTreatmentPlaces } = await enterprisePlaceQuery.data
        setEnterprisePlaceOption(
          getEnterpriseTreatmentPlaces.map((item: any) => ({
            key: item.ercId,
            value: item.thaiName,
          })),
        )
      }
    })()
  }, [enterprisePlaceQuery])

  useEffect(() => {
    if (enterprisePlaceOption.length === 0) return

    if (
      enterpriseTreatementOwnPlaceList.length === 1 &&
      enterpriseTreatementOwnPlaceList[0].ercId === ''
    )
      enterpriseTreatementOwnPlaceList[0].ercId = enterprisePlaceOption[0].key
  }, [enterprisePlaceOption])

  return (
    <>
      <ElementComponent.ModalForm open={open} onClose={() => handleClose()}>
        {!enterprisePlaceQuery.fetching && enterprisePlaceOption.length > 0 ? (
          <div className="flex flex-col items-center w-screen max-w-[550px] max-h-[700px]">
            <Formik
              initialValues={
                enterpriseTreatementOwnPlaceList.length > 0
                  ? enterpriseTreatementOwnPlaceList[0]
                  : {
                      ercId: enterprisePlaceOption[0].key,
                      citizenId: '',
                    }
              }
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit} className="w-full">
                  <div className="p-[20px]">
                    <p className="text-[20px] font-bold text-start w-full">
                      กรอกรายละเอียดข้อมูล
                    </p>
                  </div>

                  <hr className="mb-[20px]" />

                  <div className="px-[22px] pt-[20px] pb-[40px] gap-8">
                    <div className="flex flex-col gap-8 mb-3">
                      <ElementComponent.SelectBar.SelectBar
                        focusSpacing={false}
                        isLoading={enterprisePlaceQuery.fetching}
                        name="enterpriseTreatmentPlace"
                        label="สถานพยายาล"
                        placeholder="สถานพยายาล"
                        width="w-full"
                        data={
                          formik.values?.ercId || enterprisePlaceOption[0].key
                        }
                        option={enterprisePlaceOption}
                        onChange={(value: string) => {
                          const citizenId =
                            enterpriseTreatementOwnPlaceList.find(
                              (item) => item.ercId === value,
                            )?.citizenId

                          formik.setFieldValue('citizenId', citizenId || '')
                          formik.setFieldValue('ercId', value)
                        }}
                        errorText={formik.errors.ercId}
                        error={
                          formik.touched.ercId && Boolean(formik.errors.ercId)
                        }
                      />

                      <ElementComponent.InputBar
                        isLoading={false}
                        label="เลขบัตรประชาชน"
                        placeholder="เลขบัตรประชาชน"
                        name="citizenId"
                        focusSpacing={false}
                        height="52px"
                        width={'w-full'}
                        value={formik.values?.citizenId || ''}
                        errorText={formik.errors.citizenId}
                        error={
                          formik.touched.citizenId &&
                          Boolean(formik.errors.citizenId)
                        }
                        onChange={(text: string) =>
                          formik.setFieldValue('citizenId', text)
                        }
                      />
                    </div>

                    <ElementComponent.Button
                      id="create-new-specific-diease-submit"
                      submit
                      text="ยืนยัน"
                      width="100%"
                      height="52px"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          'Loading...'
        )}
      </ElementComponent.ModalForm>
      <AlertComponent.SuccessModal
        title={`เชื่อมต่อข้อมูลสำเร็จ`}
        description={'กรุณารอการตรวจสอบข้อมูลจากเจ้าหน้าที่ 1 ถึง 2 วันทำการ'}
        open={successModalState}
        onClose={() => setSuccessModalState(false)}
      />
    </>
  )
}

export default EnterpriseTreatmentModal

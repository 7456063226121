import { atom } from 'recoil'

const user = atom({
  key: 'user',
  default: {
    firstname: '',
    imagePath: '',
    hnId: 0,
    uId: '',
  },
})

export { user }

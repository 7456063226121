import React from 'react'
import { Typography, Stack } from '@mui/material'
import * as SVG from 'assets/svg'
import {
  ImgHealthRecord,
  MenuMobile1,
  MenuMobile2,
  MenuMobile3,
  MenuMobile4,
} from 'assets/picture'
import { ElementComponent } from 'components'
import { useResponsive } from 'hooks'

const menuMobileList: { imgName: string; link: string }[] = [
  {
    imgName: MenuMobile1,
    link: '/overview',
  },
  {
    imgName: MenuMobile2,
    link: '/overview',
  },
  {
    imgName: MenuMobile3,
    link: '/overview',
  },
  {
    imgName: MenuMobile4,
    link: '/overview',
  },
]
function HealthRecord() {
  const { isLaptop } = useResponsive()
  return (
    <div className="text-center h-auto pt-[60px] pb-[100px] bg-white-main p-[20px]">
      {isLaptop ? (
        <div></div>
      ) : (
        <Stack
          direction={{ mobile: 'row' }}
          className="gap-[18px] mt-[-70px] mb-[20px] justify-center items-center"
        >
          {menuMobileList.map((item: { imgName: string; link: string }) => (
            <a href={item.link}>
              <ElementComponent.Image
                fullImage={true}
                src={item.imgName}
                disableClick
              />
            </a>
          ))}
        </Stack>
      )}
      <div className="flex justify-center">
        <div
          id="healthrecord"
          className="w-full max-w-[700px] laptop:max-w-[900px]"
        >
          <Typography variant="subtitle1">
            <p
              className="font-bold mb-[20px]"
              style={{ color: '#0D5CDD', fontFamily: 'SukhumvitSet-Text' }}
            >
              Health Record & Personal Advice
            </p>
          </Typography>
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
            สมุดสุขภาพคู่ใจ ที่จะทำให้คุณรู้จักสุขภาพตัวเองได้ดียิ่งขึ้น
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: '16px' }}>
            สมุดสุขภาพคู่ใจที่สามารถบันทึกผลตรวจสุขภาพของคุณ
            ในเเต่ละครั้งเเละนำมาวิเคราะห์ผลด้วยระบบ AI
            ที่คิดค้นโดยเเพทย์ผู้เชียวชาญ เพื่อวางเเผนการดูเเลสุขภาพ ปรับ Health
            and Lifestyle ให้เหมาะสมกับตัวคุณ
          </Typography>
        </div>
      </div>

      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px] px-[30px]  justify-center items-center"
      >
        <div className="w-[90%] laptop:w-[40%]  bg-cover">
          <ElementComponent.Image
            fullImage={true}
            src={ImgHealthRecord}
            disableClick
            className="rounded-[15px] w-full h-full cursor-move"
          />
        </div>
      </Stack>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px] px-[30px]  justify-center items-center"
      >
        <a href="#">
          <SVG.BtnReadMore />
        </a>
      </Stack>
    </div>
  )
}

export default HealthRecord

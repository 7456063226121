export const updateUser = `
  mutation updateUserInfo ($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserInfo (data: $data, where: $where) {
      uId
      prefix
      firstname
      lastname
      hnId
      imagePath
      UserAuth {
        phoneNumber
      }
      UserMedicalDemograhicData {
        birthdate
        gender
        district
        province
      }
    }
  }
`

export const changePassword = `
  mutation changePassword ($data: UsersChangePasswordInput!) {
    changePassword (data: $data) {
        phoneNumber
        password
    }
  }
`

export const updateUserSettings = `
  mutation updateUserInfo ($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserInfo (data: $data, where: $where) {
        uId
    }
  }
`

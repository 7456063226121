import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useRecoilState } from 'recoil'
import { useMutation } from 'urql'
import { Menu, MenuItem, Skeleton } from '@mui/material'

import { DrawerComponent, ElementComponent } from 'components'
import { AuthMutate } from 'services/graphql/authService'
import { LogoMobile, AvatarProfile } from 'assets/svg'
import { appSettings, route } from 'settings'
import { general } from 'utils'
import { useUser, useSystem } from 'stores/recoil'

interface UserMenuListProps {
  open: boolean
  anchorEl: HTMLElement | null
  onCloseMenu: Function
}
function MainMenuList({ open, anchorEl, onCloseMenu }: UserMenuListProps) {
  const userMenuList: { title: string; path: string; icon: string }[] = [
    {
      title: 'สมุดสุขภาพ',
      path: route.OVERVIEW_PAGE_URL,
      icon: 'system-uicons:document-justified',
    },
    {
      title: 'วางแผนสุขภาพ',
      path: route.HEALTH_PAGE_URL,
      icon: 'ri:shield-cross-line',
    },
    {
      title: 'ชุมชนใกล้หมอ',
      path: '',
      icon: 'ri:shield-cross-line',
    },
  ]

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={() => onCloseMenu()}
      className="mt-[10px]"
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {userMenuList.map(
        (
          item: { title: string; path: string; icon: string },
          index: number,
        ) => (
          <NavLink
            key={`user-menu-${index}`}
            className={({ isActive }) =>
              isActive
                ? 'text-blue-main transition-all ease-in-out'
                : 'transition-all ease-in-out text-black-main'
            }
            to={item.path}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <MenuItem onClick={() => onCloseMenu()} sx={{ width: '100%' }}>
              <Icon icon={item.icon} className="text-[25px] mr-[10px]" />
              <p>{item.title}</p>
            </MenuItem>
          </NavLink>
        ),
      )}
      <MenuItem>
        <div className="flex items-center gap-[10px]">
          <Icon icon="emojione:flag-for-thailand" className="text-[24px]" />
          <p>ภาษาไทย</p>
        </div>
      </MenuItem>
    </Menu>
  )
}

function UserMenuList({ open, anchorEl, onCloseMenu }: UserMenuListProps) {
  const [, executeMutation] = useMutation(AuthMutate.logout)

  const userMenuList: { title: string; path: string; icon: string }[] = [
    {
      title: 'สมุดสุขภาพ',
      path: route.OVERVIEW_PAGE_URL,
      icon: 'system-uicons:document-justified',
    },
    {
      title: 'วางแผนสุขภาพ',
      path: route.OVERVIEW_PAGE_URL,
      icon: 'ri:shield-cross-line',
    },
    {
      title: 'ชุมชนใกล้หมอ',
      path: route.LANDING_PAGE_URL,
      icon: 'ri:shield-cross-line',
    },
    {
      title: 'บัญชีของฉัน',
      path: route.PROFILE_PAGE_URL,
      icon: 'ic:baseline-person',
    },
  ]

  const logoutUser = async () => {
    await executeMutation({
      data: {
        uId: '',
        refresh_token: general.cookie.getCookie(appSettings.TOKEN_KEY),
      },
    })
    general.auth.logout()
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={() => onCloseMenu()}
      className="mt-[10px]"
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {userMenuList.map(
        (
          item: { title: string; path: string; icon: string },
          index: number,
        ) => (
          <NavLink
            key={`user-menu-${index}`}
            className={({ isActive }) =>
              isActive
                ? 'text-blue-main transition-all ease-in-out'
                : 'transition-all ease-in-out text-black-main'
            }
            style={{ display: 'flex', alignItems: 'center' }}
            to={item.path}
            onClick={() => onCloseMenu()}
          >
            <MenuItem sx={{ width: '100%' }}>
              <Icon icon={item.icon} className="text-[25px] mr-[5px]" />
              <p>{item.title}</p>
            </MenuItem>
          </NavLink>
        ),
      )}

      <MenuItem onClick={logoutUser}>
        <div className="flex items-center gap-[10px]">
          <Icon
            icon="material-symbols:logout-rounded"
            className="text-[25px]"
          />
          <p>ออกจากระบบ</p>
        </div>
      </MenuItem>
      <MenuItem>
        <div className="flex items-center gap-[10px]">
          <Icon icon="emojione:flag-for-thailand" className="text-[24px]" />
          <p>ภาษาไทย</p>
        </div>
      </MenuItem>
    </Menu>
  )
}

function NavbarMobile() {
  const location = useLocation()
  const isProfile = Boolean(location.pathname.includes(route.PROFILE_PAGE_URL))
  const isOverview = Boolean(
    location.pathname.includes(route.OVERVIEW_PAGE_URL),
  )
  const isHealthData = Boolean(
    location.pathname.includes(route.HEALTH_PAGE_URL),
  )

  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const [imageStateError, setImageStateError] = useState<boolean>(false)

  const [openMenu, setOpenMenu] = useRecoilState(
    useSystem.selector.selectorSwipeableDrawerSideMeueStateAtom,
  )

  const [openMainMenu, setMainOpenMenu] = useRecoilState(
    useSystem.selector.selectorSwipeableDrawerSideMeueStateAtom,
  )
  const [openLoginBar, setOpenLoginBar] = useRecoilState(
    useSystem.selector.selectorSwipeableDrawerLoginStateAtom,
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const [anchorMainEl, setMainAnchorEl] = useState<HTMLElement | null>(null)
  const openMain = Boolean(anchorMainEl)

  const imageOnError = () => {
    setImageStateError(true)
  }

  useEffect(() => {
    setOpenMenu(false)
    setMainOpenMenu(false)
    setOpenLoginBar(false)
    setAnchorEl(null)
    setMainAnchorEl(null)
  }, [location])

  const onClickOpenLoginBar = () => {
    if (openLoginBar && openMenu) {
      setOpenMenu(false)
      setMainOpenMenu(false)
      setOpenLoginBar(true)
      return
    }
    if (openMenu) setOpenMenu(false)
    if (openMainMenu) setMainOpenMenu(false)
    setOpenLoginBar(!openLoginBar)
  }

  const renderName = (name: string) => {
    if (name.length > 14) {
      return `${name.substring(0, 14)}...`
    }
    return name
  }

  const renderUserMenu = () => {
    if (!general.auth.isAuthorized())
      return (
        <div className="flex">
          <div className="flex items-center gap-[10px] cursor-pointer mr-[8px]">
            <Link to={route.REGISTER_PAGE_URL}>
              <p
                className="text-[75%] pl-[4px] pr-[4px]"
                style={{
                  backgroundColor: 'red',
                  borderRadius: 15,
                  color: 'white',
                }}
              >
                สมัครสมาชิก
              </p>
            </Link>
          </div>
          <div
            className="flex items-center gap-[5px] cursor-pointer"
            onClick={onClickOpenLoginBar}
          >
            <p className="text-[75%]">เข้าสู่ระบบ</p>
            <Icon icon="bi:person-circle" className="text-[15px]" />
          </div>
          <div
            className="flex items-center gap-[0px] pl-[8px] pr-[px] ml-[8px] cursor-pointer"
            style={{
              borderColor: '#01A6E3',
              borderWidth: '2px',
              borderRadius: '15px',
            }}
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              setMainAnchorEl(event.currentTarget)
            }
          >
            <p className="text-[75%]">เมนู</p>
            <Icon
              icon="ic:baseline-keyboard-arrow-down"
              className="text-[100%] cursor-pointer"
            />
          </div>
          <MainMenuList
            open={openMain}
            anchorEl={anchorMainEl}
            onCloseMenu={() => {
              setMainAnchorEl(null)
              setMainOpenMenu(false)
            }}
          />
        </div>
      )

    if (!user['firstname'])
      return (
        <div className="flex items-center gap-[10px]">
          <div className="flex flex-row gap-2">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="text" width={80} height={20} />
          </div>
        </div>
      )
    return (
      <div className="flex items-center gap-[10px]">
        {!imageStateError && user['imagePath'] ? (
          <div>
            <ElementComponent.Image
              fullImage={false}
              className="h-[40px] w-[40px] laptop:h-[30px] laptop:w-[30px] rounded-full bg-cover bg-center"
              src={user['imagePath']}
              onError={imageOnError}
            />
          </div>
        ) : (
          <AvatarProfile className="h-[30px] w-[30px] rounded-full bg-cover bg-center" />
        )}
        <h1 className="overflow-hidden whitespace-pre text-ellipsis">
          {renderName(user['firstname'])}
        </h1>
        <div
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            setAnchorEl(event.currentTarget)
          }
        >
          <Icon
            icon="ic:baseline-keyboard-arrow-down"
            className="text-[20px] cursor-pointer"
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (user['imagePath']) setImageStateError(false)
  }, [user])

  return (
    <>
      <div
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, .1)' }}
        className="fixed top-0 left-0 z-[1300] w-full h-[90px] px-[20px] laptop:px-[30px] bg-white-main flex justify-between items-center"
      >
        <div className="flex items-center gap-[0px]">
          <Link to={route.LANDING_PAGE_URL}>
            <LogoMobile className="w-[131px] " />
          </Link>
        </div>

        {renderUserMenu()}
      </div>

      <UserMenuList
        open={open}
        anchorEl={anchorEl}
        onCloseMenu={() => {
          setAnchorEl(null)
          setOpenMenu(false)
        }}
      />

      {isProfile || isOverview || isHealthData ? (
        <DrawerComponent.UserProfileBar
          open={openMenu}
          onClose={() => setOpenMenu(false)}
        />
      ) : (
        <DrawerComponent.NavbarMenuBar
          open={openMenu}
          onClose={() => setOpenMenu(false)}
        />
      )}

      <DrawerComponent.LoginBar
        open={openLoginBar && !general.auth.isAuthorized()}
        onClose={() => setOpenLoginBar(false)}
      />
    </>
  )
}

export default NavbarMobile

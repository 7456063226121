const resendOTP = `
  mutation resendOtp ($data: RequsetOtpInput!) {
    resendOtp (data: $data) {
        phoneNumber
        refno
    }
  }
`

const login = `
  mutation login ($data: UsersLoginInput!) {
    login (data: $data) {
      refreshToken
      accessToken
    }
  }
`

const loginWithLine = `
  mutation loginWithLine ($data: UsersLoginWithLine!) {
    loginWithLine (data: $data) {
        refreshToken
        accessToken
    }
  }
`

const loginWithUid = `
mutation loginWithUid ($data: UsersLoginWithUid!) {
  loginWithUid (data: $data) {
      refreshToken
      accessToken
  }
}
`

const logout = `
  mutation logout ($data: UserLogoutInput!) {
    logout (data: $data)
  }
`

const forgetPassword = `
  mutation requestForgetPassword ($data: RequsetOtpInput!) {
    requestForgetPassword (data: $data) {
        phoneNumber
        refno
    }
  }
`

const verifyNewPassword = `
  mutation verifyForgetPassword ($data: RegisterVerifyOTPInput!) {
    verifyForgetPassword (data: $data) {
        token
        phoneNumber
    }
  }
`

const setNewPassword = `
  mutation forgetPasswordSet ($data: ForgetPasswordInput!) {
    forgetPasswordSet (data: $data) {
        refreshToken
        accessToken
        phoneNumber
        password
    }
  }
`

const refreshToken = `
  mutation refreshToken ($data: UserRefreshTokenInput!) {
    refreshToken (data: $data) {
        refreshToken
        accessToken
    }
  }
`

export {
  login,
  logout,
  forgetPassword,
  verifyNewPassword,
  setNewPassword,
  resendOTP,
  refreshToken,
  loginWithLine,
  loginWithUid,
}

interface ButtonProps {
  id: string
  text: string
  textColor?: string
  icon?: string
  submit?: boolean
  loading?: boolean
  shape?: string
  width: string
  height?: string
  style?: string
  disable?: boolean
  onAction?: Function
  backgroundColor?: string
  active?: boolean
}

function ButtonComponent({ text, id, style, onAction, active }: ButtonProps) {
  return (
    <div className={style ?? ''}>
      <div
        id={id}
        className={
          active
            ? 'flex justify-center items-center p-[0px] text-[12px] bg-blue-main text-white-main'
            : 'flex justify-center items-center p-[0px] text-[12px] bg-white-main text-black-main'
        }
        onClick={() => {
          onAction(text)
        }}
        style={{
          borderRadius: '10px',
          borderWidth: '1px',
          borderColor: 'gray',
        }}
      >
        {text}
      </div>
    </div>
  )
}

export default ButtonComponent

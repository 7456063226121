import React from 'react'
import { Typography, Stack } from '@mui/material'
import * as SVG from 'assets/svg'
import { ImgScreening } from 'assets/picture'
import { ElementComponent } from 'components'

function Screening() {
  return (
    <div className="text-center h-auto  pb-[100px] bg-white-main">
      <div className="flex justify-center p-[20px]">
        <div className="w-full max-w-[700px] laptop:max-w-[900px]">
          <Typography variant="subtitle1">
            <p className="font-bold mb-[20px]" style={{ color: '#0D5CDD' }}>
              Screening
            </p>
          </Typography>
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
            ระบบวิเคราะห์พฤติกรรมความเสี่ยงโรคด้วย DR.ASA AI
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: '16px' }}>
            เช็คให้ชัวร์ว่าสุขภาพของคุณยังดีอยู่!
            ระบบวิเคราะห์ความเสี่ยงจากพฤติกรรมการใช้ชีวิตของคุณ
            ที่สามารถหาและประเมินความเสี่ยงในการเป็นโรคต่างๆ
            ที่อาจจะเกิดขึ้นได้ในอนาคต
          </Typography>
        </div>
      </div>

      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px] px-[30px]  justify-center items-center"
      >
        <div className="w-[90%] laptop:w-[40%]  bg-cover">
          <ElementComponent.Image
            fullImage={true}
            src={ImgScreening}
            disableClick
            className="rounded-[15px] w-full h-full cursor-move"
          />
        </div>
      </Stack>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px] px-[30px]  justify-center items-center"
      >
        <a href="#" target="_blank">
          <SVG.BtnReadMore />
        </a>
      </Stack>
    </div>
  )
}

export default Screening

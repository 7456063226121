import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'urql'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { ConnectedFocusError } from 'focus-formik-error'

import { MedicalQuery, MedicalMutate } from 'services/graphql/medicalService'
import { ElementComponent, AlertComponent } from 'components'
import { useResponsive } from 'hooks'

const validationSchema = yup.object({
  problem: yup.string().required('กรุณากรอกโรคที่อยากปรึกษาวันนี้'),
  detail: yup.string().required('กรุณากรอกรายละเอียดอาการ'),
  personalDisease: yup.string(),
  duration: yup.string(),
  time: yup
    .string()
    .matches(/^[0-9]+$/, 'กรุณากรอกจำนวนวันเป็นตัวเลขเท่านั้น')
    .when('duration', (duration, schema) => {
      if (duration) return schema.required(`กรุณากรอกจำนวน${duration}`)
      return schema.notRequired()
    }),
})

const initialFormData = {
  problem: '',
  detail: '',
  personalDisease: '',
  duration: '',
  time: '',
}

const dateConfig: ElementComponent.SelectBar.OptionSelectType[] = [
  { key: 'วัน', value: 'วัน' },
  { key: 'เดือน', value: 'เดือน' },
  { key: 'ปี', value: 'ปี' },
]

function RegisterHealthForm() {
  const { isMobile } = useResponsive()
  const navigate = useNavigate()

  const [images, setImages] = useState([])

  const [diseaseAttributeID, setDiseaseAttributeID] = useState('')
  const [openErrorAlert, setOpenErrorAlert] = useState<boolean>(false)

  const [, executeMedical] = useMutation(MedicalMutate.creatMedicalData)

  const [diseaseAttribute] = useQuery(
    MedicalQuery.getDisease({ AND: [{ medName: { equals: 'Desciption' } }] }),
  )

  const handleUploadBlob = (event) => {
    const { files } = event.target
    if (files.length > 0) setImages([...images, files[0]])
  }

  const removeImage = (index: number) => {
    let newObject = [...images]
    newObject.splice(index, 1)
    setImages(newObject)
  }

  const formatDiscription = (data: any): string => {
    const problem: string =
      data.problem !== '' ? `โรคที่อยากปรึกษา : ${data.problem} \n` : ''
    const detail: string =
      data.detail !== '' ? `รายละเอียดอาการ :  ${data.detail} \n` : ''
    const underlying: string =
      data.personalDisease !== ''
        ? `โรคประจำตัว : ${data.personalDisease} \n`
        : ''
    const duration: string =
      data.time !== ''
        ? `ระยะเวลาที่มีอาการ : ${data.time} ${data.duration} \n`
        : ''

    return problem + detail + underlying + duration
  }

  const submitMedicalData = async (values: any) => {
    await executeMedical({
      data: {
        patientUser: {
          connect: {
            uId: '',
          },
        },
        submitUser: {
          connect: {
            uId: '',
          },
        },
        userMedTrasaction: {
          createMany: {
            data: [
              {
                daId: diseaseAttributeID,
                desciption: formatDiscription(values),
              },
            ],
          },
        },
      },
      files: images,
    }).then(async (res) => {
      if (res.error) {
        setOpenErrorAlert(true)
        return
      }

      navigate({ search: '?upload=success' })
    })
  }

  useEffect(() => {
    ;(async () => {
      if (!diseaseAttribute.fetching) {
        const { getDiseases } = await diseaseAttribute.data

        if (getDiseases?.length > 0) {
          setDiseaseAttributeID(getDiseases?.[0]?.diseaseAttibute?.[0]?.daId)
        }
      }
    })()
  }, [diseaseAttribute])

  return (
    <div>
      <p className="text-blue-main text-[30px] laptop:text-[32px] text-center laptop:text-start">
        มีเรื่องอะไรให้หมอช่วย เล่าให้หมอฟังหน่อย
      </p>

      <div className="w-full laptop:max-w-[470px]">
        <hr className="border-t-[1px] border-dashed border-gray-main mt-[25px] mb-[42px]" />

        <Formik
          initialValues={initialFormData}
          validationSchema={validationSchema}
          enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values) => submitMedicalData(values)}
        >
          {(formik) => (
            <form
              onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit()
              }}
            >
              <ConnectedFocusError />
              <div className="flex flex-col gap-[30px] my-[50px]">
                <ElementComponent.InputBar
                  isLoading={false}
                  label="โรคที่อยากปรึกษาวันนี้"
                  placeholder="โรคที่อยากปรึกษาวันนี้"
                  name="problem"
                  focusSpacing={false}
                  height="52px"
                  width={isMobile ? 'w-full' : 'w-main'}
                  value={formik.values.problem}
                  errorText={formik.errors.problem}
                  error={
                    formik.touched.problem && Boolean(formik.errors.problem)
                  }
                  onChange={(text: string) =>
                    formik.setFieldValue('problem', text)
                  }
                />

                <ElementComponent.TextArea
                  name="detail"
                  label="รายละเอียดอาการ"
                  placeholder="รายละเอียดอาการ"
                  minlines={10}
                  value={formik.values.detail}
                  onChange={(value: string) =>
                    formik.setFieldValue('detail', value)
                  }
                  errorText={formik.errors.detail}
                  error={formik.touched.detail && Boolean(formik.errors.detail)}
                />

                <ElementComponent.InputBar
                  isLoading={false}
                  label="โรคประจำตัว"
                  placeholder="โรคประจำตัว"
                  name="personalDisease"
                  focusSpacing={false}
                  height="52px"
                  width={isMobile ? 'w-full' : 'w-main'}
                  value={formik.values.personalDisease}
                  errorText={formik.errors.personalDisease}
                  error={
                    formik.touched.personalDisease &&
                    Boolean(formik.errors.personalDisease)
                  }
                  onChange={(text: string) =>
                    formik.setFieldValue('personalDisease', text)
                  }
                />

                <div className="flex flex-col gap-[20px]">
                  <ElementComponent.SelectBar.SelectBar
                    label="เลือกระยะเวลาที่มีอาการ"
                    placeholder="เลือกระยะเวลาที่มีอาการ"
                    name="duration"
                    width={isMobile ? 'w-full' : 'w-main'}
                    data={formik.values.duration}
                    option={dateConfig}
                    errorText={formik.errors.duration}
                    error={
                      formik.touched.duration && Boolean(formik.errors.duration)
                    }
                    onChange={(text: string) => {
                      formik.setFieldValue('duration', text)
                      formik.setFieldValue('time', '')
                    }}
                  />

                  {formik.values.duration !== '' && (
                    <ElementComponent.InputBar
                      isLoading={false}
                      height="50px"
                      label={`จำนวน${formik.values.duration}`}
                      placeholder={`จำนวน${formik.values.duration}`}
                      backIcon={<span>{formik.values.duration}</span>}
                      value={formik.values.time}
                      onChange={(value: string) =>
                        formik.setFieldValue('time', value)
                      }
                      error={formik.touched.time && Boolean(formik.errors.time)}
                      errorText={formik.errors.time}
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col justify-start gap-[30px]">
                <p className="text-start text-[16px] font-bold">
                  รูปภาพประกอบ อาการ / ยา / ผล Lab (ถ้ามี)
                </p>

                <div className="flex gap-[15px] flex-wrap">
                  {images !== null &&
                    images.map((item: File, index: number) => (
                      <div
                        key={`image-upload-${index}`}
                        className="w-[140px] h-[140px] relative"
                      >
                        <div
                          onClick={() => removeImage(index)}
                          className="absolute top-0 right-0 p-[5px] border-indigo-900 bg-blue-main hover:brightness-75 cursor-pointer rounded-tr-[10px]"
                        >
                          <Icon
                            icon="mdi:bin"
                            className="h-[25px] w-[25px] text-white-main"
                          />
                        </div>
                        <ElementComponent.Image
                          className="h-full w-full object-cover bg-center rounded-[10px]"
                          src={URL.createObjectURL(item)}
                          alt=""
                        />
                      </div>
                    ))}

                  {images.length !== 3 && (
                    <ElementComponent.ImageButton
                      id="image-upload"
                      width="140px"
                      height="140px"
                      text={
                        Array.isArray(images)
                          ? images.length >= 1
                            ? `${3 - images.length}/3`
                            : undefined
                          : undefined
                      }
                      onChange={handleUploadBlob}
                    />
                  )}
                </div>
              </div>
              <div className="mt-[50px] mb-[80px]">
                <ElementComponent.Button
                  id="consultation-submit-button"
                  submit
                  text="ปรึกษาคุณหมอ"
                  width="100%"
                  height="52px"
                />
              </div>
            </form>
          )}
        </Formik>

        <AlertComponent.ErrorModal
          title="กรอกข้อมูลไม่สำเร็จ"
          description="กรุณาทดลองใหม่อีกครั้ง"
          open={openErrorAlert}
          onClose={() => setOpenErrorAlert(false)}
        />
      </div>
    </div>
  )
}

export default RegisterHealthForm

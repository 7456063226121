import React, { useState, useEffect } from 'react'
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom'

import { ForgetSVG } from 'assets/svg'
import { FormComponent } from 'components'
import { LoginType } from 'types'
import { AuthMutate } from 'services/graphql/authService'
import { appSettings } from 'settings'
import { general } from 'utils'
import { useResponsive } from 'hooks'
import { SuccessProgressPages } from 'pages'

function ForgetPage() {
  const { isMobile } = useResponsive()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [form, setForm] = useState<LoginType.FormType>(
    LoginType.FormType.FORGET,
  )

  const [data, setData] = useState({ refno: '', phoneNumber: '' })

  useEffect(() => {
    if (general.cookie.getCookie(appSettings.FORGET_KEY)) {
      const { requestForgetPassword } = JSON.parse(
        general.cookie.getCookie(appSettings.FORGET_KEY),
      )
      setData({
        refno: requestForgetPassword.refno,
        phoneNumber: requestForgetPassword.phoneNumber,
      })
    }
  }, [form])

  const onChangeFormSuccess = () => {
    setForm(LoginType.FormType.SUCCESS)
    navigate({
      search: createSearchParams({
        forget: 'success',
      }).toString(),
    })
  }

  const Render = () => {
    if (
      form === LoginType.FormType.SUCCESS ||
      searchParams.get('forget') === 'success'
    )
      return <SuccessProgressPages.ForgetSuccessPage />

    return (
      <div className="flex h-full">
        {!isMobile && (
          <div
            style={{ backgroundColor: 'rgba(20, 117, 187, 1)' }}
            className="w-1/2 text-white-main text-center flex flex-col justify-center items-center"
          >
            <ForgetSVG />
            <p className="text-[28px] mt-[60px] p-[20px]">
              DR. ASA PRIMARY CARE PLATFORM
            </p>
            <p className="mx-[130px]">
              “จะรอให้ตัวเองป่วยทำไม ในเมื่อคุณป้องกันโรคของตัวเองได้
              มาออกแบบสุขภาพตัวเองกับหมอประจำตัวด้วย Dr. ASA”
            </p>
          </div>
        )}

        <div className="laptop:w-1/2 w-full text-center laptop:p-[57px] py-[50px] px-[18px] bg-white-dark overflow-auto">
          {form === LoginType.FormType.FORGET && (
            <FormComponent.ForgetForm
              onChangeForm={() => setForm(LoginType.FormType.AUTH)}
            />
          )}
          {form === LoginType.FormType.AUTH && (
            <FormComponent.AuthForm
              title="ลืมรหัสผ่าน"
              description="โปรดใส่หมายเลข OTP ที่คุณได้รับผ่าน SMS ผ่านเบอร์"
              cookie={appSettings.FORGET_KEY}
              refno={data.refno}
              setRefno={setData}
              phoneNumber={data.phoneNumber}
              service={AuthMutate.verifyNewPassword}
              onChangeForm={() => setForm(LoginType.FormType.RESET)}
            />
          )}
          {form === LoginType.FormType.RESET && (
            <FormComponent.ResetForm onChangeForm={onChangeFormSuccess} />
          )}
        </div>
      </div>
    )
  }

  return Render()
}

export default ForgetPage

import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useMutation } from 'urql'
import { Icon } from '@iconify/react'

import { MenuComponent, ElementComponent } from 'components'
import { HealthUpload1, HealthUpload2 } from 'assets/picture'
import { MedicalMutate } from 'services/graphql/medicalService'
import { useResponsive } from 'hooks'
import { route } from 'settings'
import { SuccessProgressPages } from 'pages'

const menuList = [
  { title: 'ข้อมูลสุขภาพ', link: route.HEALTH_PAGE_URL },
  { title: 'สร้างข้อมูลสุขภาพโดยการอัพโหลดรูปผลแลป', link: '' },
]

function UploadImage() {
  const { isMobile } = useResponsive()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [images, setImages] = useState<FileList[]>([])
  const [uploadMutate, executeMutation] = useMutation(
    MedicalMutate.uploadMedicalImage,
  )

  const handleUploadBlob = (event) => {
    const { files } = event.target
    if (files.length > 0) setImages([...images, files[0]])
  }

  const removeImage = (index: number) => {
    let newObject = [...images]
    newObject.splice(index, 1)
    setImages(newObject)
  }

  const renderText = () => {
    if (!Array.isArray(images)) return undefined

    return images.length >= 1 ? `${images.length}/3` : undefined
  }

  const submitImageData = async () => {
    await executeMutation({
      data: {
        patientUser: {
          connect: {
            uId: '',
          },
        },
        submitUser: {
          connect: {
            uId: '',
          },
        },
      },
      files: images,
    }).then(async (res) => {
      if (res.error) {
        return
      }

      navigate({ search: '?upload=success' })
    })
  }

  if (searchParams.get('upload') === 'success') {
    return <SuccessProgressPages.UploadSuccessPage />
  }

  return (
    <div className="w-full laptop:mt-0">
      <MenuComponent.MenuHeaderBar menuList={menuList} />

      <div className="flex items-center gap-[20px] mt-[25px]">
        <Icon icon="bi:heart-pulse-fill" className="text-[24px]" />
        <p className="text-[20px] font-bold">
          สร้างข้อมูลสุขภาพโดยการอัพโหลดรูปผลแลป
        </p>
      </div>

      <div
        className="rounded-[10px] px-[18px] py-[30px] mt-[30px] bg-white-main"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="bg-white-darker py-[13px] px-[17px] rounded-[5px]">
          <p className="font-bold text-[18px]">อัพโหลดรูปภาพ</p>
        </div>

        <div className="p-[10px] mt-[35px] flex flex-col gap-[50px]">
          <div>
            <p className="text-[16px] font-bold mb-[15px]">
              ตัวอย่างรูปที่ถูกต้อง
            </p>
            <Stack
              direction={{ mobile: 'column', laptop: 'row' }}
              gap="15px"
              className="items-center"
            >
              <div className="w-full h-[300px] laptop:w-[300px] laptop:h-[210px]">
                <ElementComponent.Image
                  className="w-full h-full object-cover bg-center rounded-[10px]"
                  src={HealthUpload1}
                  alt=""
                />
              </div>
              <div className="w-full h-[300px] laptop:w-[300px] laptop:h-[210px]">
                <ElementComponent.Image
                  className="w-full h-full object-cover bg-center rounded-[10px]"
                  src={HealthUpload2}
                  alt=""
                />
              </div>
            </Stack>
          </div>

          <div>
            <p className="text-[16px] font-bold mb-[15px]">อัพโหลดรูปภาพ</p>

            <Stack
              direction={{ mobile: 'column', laptop: 'row' }}
              gap="15px"
              className="items-center flex-wrap"
            >
              {images !== null &&
                images.map((item: any, index: number) => (
                  <div
                    key={`image-upload-${index}`}
                    className="w-full h-[300px] laptop:w-[300px] laptop:h-[210px] relative"
                  >
                    <div
                      onClick={() => removeImage(index)}
                      className="absolute top-0 right-0 p-[5px] border-indigo-900 bg-blue-main hover:brightness-75 cursor-pointer rounded-tr-[10px]"
                    >
                      <Icon
                        icon="mdi:bin"
                        className="h-[25px] w-[25px] text-white-main"
                      />
                    </div>
                    <ElementComponent.Image
                      className="w-full h-full object-cover bg-center rounded-[10px]"
                      src={URL.createObjectURL(item)}
                      alt=""
                    />
                  </div>
                ))}

              {images.length !== 3 && (
                <ElementComponent.ImageButton
                  isLoading={uploadMutate.fetching}
                  id="upload-image"
                  width={isMobile ? '100%' : undefined}
                  height={isMobile ? '300px' : undefined}
                  text={renderText()}
                  onChange={handleUploadBlob}
                />
              )}
            </Stack>
          </div>
        </div>
      </div>

      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        justifyContent={{ mobile: 'center', laptop: 'end' }}
        className="mt-[50px]"
      >
        <ElementComponent.Button
          loading={uploadMutate.fetching}
          id="submit-button"
          disable={images.length === 0 || images === null}
          text="ยืนยันส่งข้อมูล"
          width={isMobile ? '100%' : '260px'}
          height="52px"
          onAction={() => submitImageData()}
        />
      </Stack>
    </div>
  )
}

export default UploadImage

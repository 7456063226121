import React from 'react'
import { Typography, Stack } from '@mui/material'
import * as SVG from 'assets/svg'
import { ImgHealthMonitor } from 'assets/picture'
import { ElementComponent } from 'components'

function HealthMonitoring() {
  return (
    <div className="text-center h-auto  pb-[100px] bg-white-main">
      <div className="flex justify-center p-[20px]">
        <div
          id="healthmonitoring"
          className="w-full max-w-[700px] laptop:max-w-[900px]"
        >
          <Typography variant="subtitle1">
            <p className="font-bold mb-[20px]" style={{ color: '#0D5CDD' }}>
              Health monitoring
            </p>
          </Typography>
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
            ระบบวิเคราะห์ผลสุขภาพอัจฉริยะ
            ทีจะทำให้คุณเข้าใจผลตรวจเลือดของคุณได้ง่ายๆ
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: '16px' }}>
            ทีมเเพทย์ของเราได้คิดค้นระบบวิเคราะห์ผลสุขภาพอัจฉริยะ
            ที่สามารถเเปลผลเลือด
            หรือศัพท์ทางการเเพทย์ให้เป็นภาษาที่เข้าใจได้ง่ายๆ
            เหมือนมีหมออาสามาเเปลผลตรวจสุขภาพให้กับคุณ
            รวมถึงช่วยวิเคราะห์ความเสี่ยง
            เเละการป้องกันโรคในอนาคตของคุณได้อีกด้วย
          </Typography>
        </div>
      </div>

      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px] px-[30px]  justify-center items-center"
      >
        <div className="w-[90%] laptop:w-[40%]  bg-cover">
          <ElementComponent.Image
            fullImage={true}
            src={ImgHealthMonitor}
            disableClick
            className="rounded-[15px] w-full h-full cursor-move"
          />
        </div>
      </Stack>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px] px-[30px]  justify-center items-center"
      >
        <a href="#">
          <SVG.BtnReadMore />
        </a>
      </Stack>
    </div>
  )
}

export default HealthMonitoring

const LANDING_PAGE_URL = '/'
const PROFILE_PAGE_URL = '/profile'
const CHANGE_PASSWORD = `${PROFILE_PAGE_URL}?change-password=true`

// => Overview Pages
const OVERVIEW_PAGE_URL = '/overview'
const HEALTH_PAGE_URL = '/health-data'
const UPLOAD_HEALTH_PAGE_URL = '/health-data/upload'
const CREATE_HEALTH_PAGE_URL = '/health-data/create'
const CREATE_HEALTH_V2_PAGE_URL = '/health-data/createv2'
const RECOMMEND_HEALTH_PAGE_URL = '/health-data/recommend'

// => History
const HISTORY_DIABETES_PAGE_URL = '/health-data/diabetes'
const HISTORY_BLOOD_FAT_PAGE_URL = '/health-data/bloodfat'
const HISTORY_BLOOD_PRESSURE_PAGE_URL = '/health-data/bloodpressure'
const HISTORY_LIVER_PAGE_URL = '/health-data/liver'
const HISTORY_KIDNEY_PAGE_URL = '/health-data/kidney'
const HISTORY_HEART_PAGE_URL = '/health-data/heart'
const HISTORY_FAT_PAGE_URL = '/health-data/fat'

// => User data Pages
const USER_HEALTH_DATA_PAGE_URL = '/health-data/:umdId'
const DIAGNOSIS_HEALTH_DATA_PAGE_URL = '/health-data/:umdId/diagnosis'

// => Login and Register Pages
const REGISTER_PAGE_URL = '/register'
const CONSULTING_FORM_PAGE_URL = '/register/consulting'
const LOGIN_PAGE_URL = '/login'
const FORGET_PASSWORD_PAGE_URL = '/login/forget'

const EXPECT_PAGE_URL = '*'

export {
  RECOMMEND_HEALTH_PAGE_URL,
  LANDING_PAGE_URL,
  REGISTER_PAGE_URL,
  CONSULTING_FORM_PAGE_URL,
  LOGIN_PAGE_URL,
  CHANGE_PASSWORD,
  FORGET_PASSWORD_PAGE_URL,
  EXPECT_PAGE_URL,
  PROFILE_PAGE_URL,
  OVERVIEW_PAGE_URL,
  HEALTH_PAGE_URL,
  UPLOAD_HEALTH_PAGE_URL,
  CREATE_HEALTH_PAGE_URL,
  USER_HEALTH_DATA_PAGE_URL,
  DIAGNOSIS_HEALTH_DATA_PAGE_URL,
  HISTORY_DIABETES_PAGE_URL,
  HISTORY_BLOOD_FAT_PAGE_URL,
  HISTORY_BLOOD_PRESSURE_PAGE_URL,
  HISTORY_LIVER_PAGE_URL,
  HISTORY_KIDNEY_PAGE_URL,
  HISTORY_HEART_PAGE_URL,
  HISTORY_FAT_PAGE_URL,
  CREATE_HEALTH_V2_PAGE_URL,
}

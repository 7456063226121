import React from 'react'
import { ElementComponent } from 'components'
import { InputAdornment, Stack, TextField } from '@mui/material'
import * as Pics from 'assets/picture'
import { Icon } from '@iconify/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'

interface FoodModalProps {
  open: boolean
  onClose: Function
  onChoose: Function
}

function FoodModal({ open, onClose, onChoose }: FoodModalProps) {
  const foodList: {
    index: number
    title: string
    kcal: number
    imgName: string
  }[] = [
    {
      index: 0,
      title: 'ข้าวไข่เจียว',
      kcal: 310,
      imgName: Pics.SampleFood,
    },
    {
      index: 1,
      title: 'ข้าวผัดคะน้าหมูกรอบ',
      kcal: 516,
      imgName: Pics.food_1,
    },
    {
      index: 2,
      title: 'ข้าวกระเพราไก่',
      kcal: 554,
      imgName: Pics.food_0,
    },
    {
      index: 3,
      title: 'ข้าวไข่ข้น',
      kcal: 300,
      imgName: Pics.food_2,
    },
    {
      index: 4,
      title: 'ข้าวต้มกุ้ง',
      kcal: 203,
      imgName: Pics.food_3,
    },
  ]

  const drinkList: {
    index: number
    title: string
    kcal: number
    imgName: string
  }[] = [
    {
      index: 0,
      title: 'โอเลี้ยง',
      kcal: 200,
      imgName: Pics.drink_0,
    },
    {
      index: 1,
      title: 'รูทเบียร์',
      kcal: 105,
      imgName: Pics.drink_1,
    },
    {
      index: 2,
      title: 'น้ำกระเจี๊ยบ',
      kcal: 120,
      imgName: Pics.drink_2,
    },
    {
      index: 3,
      title: 'นมเย็น',
      kcal: 425,
      imgName: Pics.drink_3,
    },
  ]

  const dessertsList: {
    index: number
    title: string
    kcal: number
    imgName: string
  }[] = [
    {
      index: 0,
      title: 'ทองหยอด',
      kcal: 340,
      imgName: Pics.des_0,
    },
    {
      index: 1,
      title: 'เค้กกล้วยหอม',
      kcal: 350,
      imgName: Pics.des_1,
    },
    {
      index: 2,
      title: 'เครปเย็น',
      kcal: 450,
      imgName: Pics.des_2,
    },
    {
      index: 3,
      title: 'ข้าวเหนียวสังขยา',
      kcal: 370,
      imgName: Pics.des_3,
    },
  ]
  return (
    <ElementComponent.ModalFormV2 open={open} onClose={() => onClose()}>
      <div
        className="flex flex-col items-center w-screen max-h-[600px] mt-[10px] bg-white-main rounded-[15px]"
        style={{ overflowY: 'auto' }}
      >
        <div
          className="mt-[16px] text-center w-[50%]"
          style={{
            padding: '2px 12px',
            backgroundColor: '#0D5CDD',
            borderRadius: '15px',
            borderWidth: '1px',
          }}
        >
          <h5 style={{ color: '#FFF' }}>เพิ่มรายการอาหาร</h5>
        </div>
        <div className="mt-2">
          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            placeholder="ค้นหา"
            InputProps={{
              className: 'bg-zinc-100',
              sx: { borderRadius: 10 },
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    icon="material-symbols:search-rounded"
                    className="w-[20px] h-[20px]"
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <>
          <Stack direction={{ mobile: 'row', laptop: 'row' }}>
            <div
              className="mt-[16px] text-center"
              style={{
                padding: '2px 12px',
                borderColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
              }}
            >
              <h5 style={{ color: '#000' }}>อาหาร</h5>
            </div>
          </Stack>
          <div className="max-w-[350px]">
            <Swiper
              slidesPerView={3}
              spaceBetween={1}
              observer={true}
              observeParents={true}
              navigation={true}
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
            >
              {foodList.map(
                (item: {
                  index: number
                  title: string
                  kcal: number
                  imgName: string
                }) => (
                  <SwiperSlide className="p-[8px]">
                    <div
                      onClick={() => {
                        onChoose(item)
                      }}
                    >
                      <img
                        src={item.imgName}
                        className="h-[80px] w-[100%]"
                        style={{ objectFit: 'cover', borderRadius: '15px' }}
                      />
                      <p className="text-center mt-1">{item.title}</p>
                      <p className="text-center" style={{ color: '#aaa' }}>
                        {item.kcal} kcal
                      </p>
                    </div>
                  </SwiperSlide>
                ),
              )}
            </Swiper>
          </div>
        </>
        <>
          <Stack direction={{ mobile: 'row', laptop: 'row' }}>
            <div
              className="mt-[16px] text-center"
              style={{
                padding: '2px 12px',
                borderColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
              }}
            >
              <h5 style={{ color: '#000' }}>เครื่องดื่ม</h5>
            </div>
          </Stack>
          <div className="max-w-[350px]">
            <Swiper
              slidesPerView={3}
              spaceBetween={1}
              observer={true}
              observeParents={true}
              navigation={true}
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
            >
              {drinkList.map(
                (item: {
                  index: number
                  title: string
                  kcal: number
                  imgName: string
                }) => (
                  <SwiperSlide
                    className="p-[8px]"
                    onClick={() => {
                      onChoose(item)
                    }}
                  >
                    <img
                      src={item.imgName}
                      className="h-[80px] w-[100%]"
                      style={{ objectFit: 'cover', borderRadius: '15px' }}
                    />
                    <p className="text-center mt-1">{item.title}</p>
                    <p className="text-center" style={{ color: '#aaa' }}>
                      {item.kcal} kcal
                    </p>
                  </SwiperSlide>
                ),
              )}
            </Swiper>
          </div>
        </>
        <>
          <Stack direction={{ mobile: 'row', laptop: 'row' }}>
            <div
              className="mt-[16px] text-center"
              style={{
                padding: '2px 12px',
                borderColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
              }}
            >
              <h5 style={{ color: '#000' }}>ขนมหวาน</h5>
            </div>
          </Stack>
          <div className="max-w-[350px]">
            <Swiper
              slidesPerView={3}
              spaceBetween={1}
              observer={true}
              observeParents={true}
              navigation={true}
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
            >
              {dessertsList.map(
                (item: {
                  index: number
                  title: string
                  kcal: number
                  imgName: string
                }) => (
                  <SwiperSlide
                    className="p-[8px]"
                    onClick={() => {
                      onChoose(item)
                    }}
                  >
                    <img
                      src={item.imgName}
                      className="h-[80px] w-[100%]"
                      style={{ objectFit: 'cover', borderRadius: '15px' }}
                    />
                    <p className="text-center mt-1">{item.title}</p>
                    <p className="text-center" style={{ color: '#aaa' }}>
                      {item.kcal} kcal
                    </p>
                  </SwiperSlide>
                ),
              )}
            </Swiper>
          </div>
        </>
      </div>
    </ElementComponent.ModalFormV2>
  )
}

export default FoodModal

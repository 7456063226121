export const getEnterpriseTreatmentPlaces = () => {
  const query = `
    query getEnterpriseTreatmentPlaces {
        getEnterpriseTreatmentPlaces {
            ercId
            thaiName
        }
    }`

  return {
    query: query,
  }
}

export const getOwnEnterpriseTreatmentPlace = () => {
  const query = `
      query getEnterpriseTreatmentPlaceTransactions($where: EnterpriseTreatmentPlaceTransactionWhereInput) {
        getEnterpriseTreatmentPlaceTransactions(where: $where) {
          etptId
          ercId
          citizenId
        }
    }
  `

  return {
    query: query,
    variables: {
      where: {},
    },
  }
}

import React, { useState } from 'react'
import { useQuery } from 'urql'
import { OverviewQuery } from 'services/graphql/overview'
import {
  //DiseaseComponent,
  ElementComponent,
  ModalComponent,
  RecommendComponent,
} from 'components'
import { useRecoilState } from 'recoil'
import * as Pics from 'assets/picture'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
// import styles bundle
import 'swiper/css/bundle'
import * as SVG from 'assets/svg'

import { useUser } from 'stores/recoil'
import dayjs from 'dayjs'
import { Stack } from '@mui/material'

function HealthCard() {
  const [page, setPage] = useState<number>(0)
  const [openPlanModal, setOpenPlanModal] = useState<boolean>(false)
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  //alert(user['uId'])
  const [patientDiagnoseLastOne] = useQuery(
    OverviewQuery.getDiagnoseLastOne(user['uId']),
  )
  const [patientConsultData] = useQuery(
    OverviewQuery.getConsultOne(user['uId']),
  )

  const mapValue = {
    '0': {
      value: 0.2,
      valueStr: 20,
      color: '#ad2418',
      text: 'ควรปรับปรุง',
      bg: Pics.level1,
    },
    '1': {
      value: 0.4,
      color: '#e9a95a',
      text: 'พอใช้',
      bg: Pics.level2,
    },
    '2': {
      value: 0.6,
      color: '#c7c03e',
      text: 'ปานกลาง',
      bg: Pics.level3,
    },
    '3': {
      value: 0.8,
      color: '#3CE93C',
      text: 'ค่อนข้างดี',
      bg: Pics.level4,
    },
    '4': {
      value: 1.0,
      color: '#06C755',
      text: 'ดีเยี่ยม',
      bg: Pics.level5,
    },
  }
  const [overallHealthScorce] = useQuery(
    OverviewQuery.getHealthStatusOverallDiseaseScorces(),
  )

  return (
    <>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] p-[16px] "
        alignItems={'center'}
      >
        <div className="w-[100%] laptop:w-[40%]  bg-cover ">
          <Stack
            direction={{ mobile: 'column', laptop: 'column' }}
            alignItems={'top'}
            className="gap-[18px]"
            style={{
              borderColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
            }}
          >
            <div>
              <Stack
                direction={{ mobile: 'column', laptop: 'column' }}
                className="gap-[18px] items-center"
              >
                <div
                  className="mt-[-15px]"
                  style={{
                    padding: '2px 12px',
                    backgroundColor: '#0D5CDD',
                    borderRadius: '15px',
                    borderWidth: '1px',
                  }}
                >
                  <h5 style={{ color: '#FFF' }}>สุขภาพโดยรวมของคุณ</h5>
                </div>

                <Stack
                  direction={{ mobile: 'row', laptop: 'row' }}
                  className="gap-[18px] items-center h-[290px]"
                >
                  <div className="w-[50%] mt-[-30%] laptop:mt-[0px]">
                    <ElementComponent.Image
                      fullImage={true}
                      src={Pics.BtnHumenMan}
                      disableClick
                      className="cursor-default ml-[8px] w-[267px]"
                    />
                    <div className="mt-[-75%] laptop:mt-[-80%] ml-[12%]">
                      {!overallHealthScorce.fetching &&
                      !overallHealthScorce.error ? (
                        <>
                          <ElementComponent.Image
                            fullImage={true}
                            src={
                              overallHealthScorce.data
                                .getHealthStatusOverallDiseaseScorces.length > 0
                                ? mapValue[
                                    Math.round(
                                      overallHealthScorce.data.getHealthStatusOverallDiseaseScorces[0].value.toPrecision(
                                        1,
                                      ),
                                    )
                                  ].bg
                                : Pics.BgNoneData
                            }
                            disableClick
                            className="cursor-default ml-[8px] w-[120px] laptop:w-[150px] "
                          />
                          <h5
                            className="mt-[-57%] laptop:mt-[-55%] ml-[28%]"
                            style={{
                              color: '#130101',
                              fontSize: '20px',
                              fontWeight: 'bold',
                            }}
                          >
                            {overallHealthScorce.data
                              .getHealthStatusOverallDiseaseScorces.length > 0
                              ? mapValue[
                                  overallHealthScorce.data.getHealthStatusOverallDiseaseScorces[0].value.toPrecision(
                                    1,
                                  )
                                ].value * 100
                              : '-'}
                            %
                          </h5>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <h5 style={{ color: '#2C71E1', fontSize: '22px' }}>
                      ผลลัพท์สุขภาพ
                    </h5>
                    {!overallHealthScorce.fetching &&
                    !overallHealthScorce.error ? (
                      <p
                        style={{
                          color:
                            overallHealthScorce.data
                              .getHealthStatusOverallDiseaseScorces.length > 0
                              ? mapValue[
                                  Math.round(
                                    overallHealthScorce.data.getHealthStatusOverallDiseaseScorces[0].value.toPrecision(
                                      1,
                                    ),
                                  )
                                ].color
                              : '#D1D1D1',
                          fontSize: '24px',
                        }}
                      >
                        {overallHealthScorce.data
                          .getHealthStatusOverallDiseaseScorces.length > 0
                          ? mapValue[
                              Math.round(
                                overallHealthScorce.data.getHealthStatusOverallDiseaseScorces[0].value.toPrecision(
                                  1,
                                ),
                              )
                            ].text
                          : 'ไม่มีข้อมูล'}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </Stack>
              </Stack>
            </div>
          </Stack>
          <div
            className="mt-[16px] text-center "
            style={{
              padding: '2px 12px',
              backgroundColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
            }}
          >
            <h5 style={{ color: '#FFF' }}>คำแนะนำสุขภาพเฉพาะของคุณ</h5>
          </div>
          <Stack
            direction={{ mobile: 'row', laptop: 'row' }}
            className="gap-[10%] items-center mt-[16px]  pl-[10%]  pr-[10%]"
          >
            <div className="w-[60%]  items-center" onClick={() => setPage(1)}>
              <ElementComponent.Image
                fullImage={true}
                src={Pics.BtnRecFood}
                disableClick
                className=" cursor-default"
              />
            </div>
            <div className="w-[60%] items-center" onClick={() => setPage(2)}>
              <ElementComponent.Image
                fullImage={true}
                disableClick
                src={Pics.BtnRecFit}
                className=" cursor-default"
              />
            </div>
          </Stack>
          <Stack
            direction={{ mobile: 'row', laptop: 'row' }}
            className="gap-[10%] items-center mt-[16px] pl-[10%]  pr-[10%]"
          >
            <div className="w-[60%]  items-center" onClick={() => setPage(3)}>
              <ElementComponent.Image
                fullImage={false}
                src={Pics.BtnRecSym}
                disableClick
                className=" cursor-default"
              />
            </div>
            <div className="w-[60%] items-center" onClick={() => setPage(4)}>
              <ElementComponent.Image
                fullImage={false}
                disableClick
                src={Pics.BtnRecDrug}
                className=" cursor-default"
              />
            </div>
          </Stack>
          {page === 0 && (
            <>
              <div
                className="mt-[16px] text-center w-[40%]"
                style={{
                  padding: '2px 12px',
                  backgroundColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                }}
              >
                <h5 style={{ color: '#FFF' }}>คำแนะนำจากหมอ</h5>
              </div>
              <Stack
                direction={{ mobile: 'column', laptop: 'column' }}
                alignItems={'top'}
                className="gap-[18px] p-[16px] mt-[16px]"
                style={{
                  borderColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                }}
              >
                {!patientDiagnoseLastOne.fetching &&
                !patientDiagnoseLastOne.error ? (
                  <>
                    <b className="text-[18px]">
                      วันที่{' '}
                      {`${dayjs(
                        patientDiagnoseLastOne.data.getDiagnoseLastOne.createdAt.split(
                          'T',
                        )[0],
                      ).format('DD MMMM BBBB')}`}{' '}
                      นพ.ธนกร ยนต์นิยม
                    </b>
                    <p>
                      {patientDiagnoseLastOne.data.getDiagnoseLastOne.recommend}
                    </p>
                  </>
                ) : (
                  <p>ไม่มีข้อมูล</p>
                )}
              </Stack>
              <div
                className="mt-[16px] text-center w-[40%]"
                style={{
                  padding: '2px 12px',
                  backgroundColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                }}
              >
                <h5 style={{ color: '#FFF' }}>วันนัดของคุณ</h5>
              </div>
              <Stack
                direction={{ mobile: 'column', laptop: 'column' }}
                alignItems={'top'}
                className="gap-[18px] p-[16px] mt-[16px]"
                style={{
                  borderColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                }}
              >
                {!patientConsultData.fetching &&
                !patientConsultData.error &&
                patientConsultData.data.getConsultOne ? (
                  <>
                    {' '}
                    <p>
                      วันที่ {patientConsultData.data.getConsultOne.dateConsult}{' '}
                      นัดตรวจ
                    </p>
                    <p>จาก นพ.ธนกร ยนต์นิยม</p>
                  </>
                ) : (
                  <>
                    <p>ไม่มีข้อมูล</p>
                  </>
                )}
              </Stack>
            </>
          )}
          {page === 1 && (
            <>
              <Stack className="items-center">
                <div
                  className="mt-[16px] text-center w-[50%]"
                  style={{
                    padding: '2px 12px',
                    backgroundColor: '#E40009',
                    borderRadius: '15px',
                    borderWidth: '1px',
                  }}
                  onClick={() => setPage(0)}
                >
                  <h5 style={{ color: '#FFF' }}>กลับสู่หน้าหลัก</h5>
                </div>
                <RecommendComponent.Food />
              </Stack>
            </>
          )}
          {page === 2 && (
            <>
              <Stack className="items-center">
                <div
                  className="mt-[16px] text-center w-[50%]"
                  style={{
                    padding: '2px 12px',
                    backgroundColor: '#E40009',
                    borderRadius: '15px',
                    borderWidth: '1px',
                  }}
                  onClick={() => setPage(0)}
                >
                  <h5 style={{ color: '#FFF' }}>กลับสู่หน้าหลัก</h5>
                </div>
                <div className="mt-[16px]">
                  <SVG.HeaderHealth />
                </div>
                <ElementComponent.Image
                  fullImage={false}
                  disableClick
                  src={Pics.SampleVideo}
                  className=" cursor-default mt-4"
                />
              </Stack>
            </>
          )}
          {page === 3 && <RecommendComponent.Symptom />}
          {page === 4 && <RecommendComponent.Drug />}
        </div>
        <div
          className="text-center w-[50%]"
          style={{
            padding: '2px 12px',
            backgroundColor: '#0D5CDD',
            borderRadius: '15px',
            borderWidth: '1px',
          }}
          onClick={() => setOpenPlanModal(true)}
        >
          <h5 style={{ color: '#FFF' }}>จองเวลา ปรึกษาหมอ</h5>
        </div>
      </Stack>
      <ModalComponent.PlanModal
        open={openPlanModal}
        onClose={() => setOpenPlanModal(false)}
      />
    </>
  )
}

function RecommendPage() {
  return (
    <div className="w-full">
      <HealthCard />
    </div>
  )
}

export default RecommendPage

import React, { useState } from 'react'
import { Checkbox } from '@mui/material'
import { useMutation } from 'urql'
import dayjs from 'dayjs'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { ConnectedFocusError } from 'focus-formik-error'
import { useRecoilState } from 'recoil'

import { ModalComponent, AlertComponent, ElementComponent } from 'components'
import { RegisterMutate } from 'services/graphql/registerService'
import { useSystem } from 'stores/recoil'
import { useResponsive } from 'hooks'
import { RegisterType } from 'types'
import { general } from 'utils'
import { appSettings } from 'settings'

import { PersonForm, AddressForm, PasswordForm } from './index'

interface RegisterFormProps {
  onChangeForm: Function
  code?: string
  lineUId: string
}

interface IRegisterSubmit {
  data: {
    prefix: string
    firstname: string
    lastname: string
    UserAuth: {
      create: {
        phoneNumber: string
        password: string
        lineUId: string
      }
    }
    UserMedicalDemograhicData: {
      create: {
        birthdate: Date
        gender: string
        province: string
        district: string
      }
    }
    role: {
      create: {
        name: ''
        UserRolePermissionTrasaction: {
          create: {
            userRolePermission: {}
          }
        }
      }
    }
    EnterpriseTreatmentPlaceTransaction?: {
      create: {
        enterpriseTreatmentPlace: {
          connect: {
            code: string
          }
        }
      }
    }
  }
}

const initialValues: RegisterType.RegisterForm = {
  prefix: '',
  firstname: '',
  lastname: '',
  gender: '',
  birthdate: null,
  phone: '',
  province: '',
  district: '',
  password: '',
  confirmPassword: '',
}

const phoneRegExp = /^(0[689]{1})+([0-9]{8})+$/

const validationSchema = yup.object({
  prefix: yup.string().required('กรุณาเลือกคำนำหน้า'),
  firstname: yup
    .string()
    .max(30, 'กรุณากรอกชื่อจริงไม่เกิน 30 ตัวอักษร')
    .required('กรุณากรอกชื่อจริงไม่เกิน 30 ตัวอักษร'),
  lastname: yup
    .string()
    .max(30, 'กรุณากรอกนามสกุลไม่เกิน 30 ตัวอักษร')
    .required('กรุณากรอกนามสกุลไม่เกิน 30 ตัวอักษร'),
  gender: yup.string().required('กรุณาเลือกเพศ'),
  birthdate: yup
    .date()
    .test(
      'check-date-more-than-today',
      'วันเกิดไม่ควรเป็นวันในอนาคต',
      (value) => {
        if (dayjs().add(543, 'year').isBefore(dayjs(value))) return false
        return true
      },
    )
    .test(
      'check-date-more-less-than-130-year',
      'ต้องขออภัย ระบบไม่รองรับคนที่อายุมากกว่า 130 ปี',
      (value) => {
        if (dayjs(value) < dayjs().subtract(130, 'year')) return false
        return true
      },
    )
    .typeError(
      'รูปแบบของวันที่ไม่ถูกต้อง โปรดระบุในรูปแบบ วัน/เดือน/ปี และ ปีเป็น พศ.',
    )
    .nullable()
    .required('กรุณาเลือกวัน/เดือน/ปี เกิด'),

  phone: yup
    .string()
    .matches(phoneRegExp, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
    .required('กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 ตัวเลข'),
  province: yup.string().required('กรุณาเลือกจังหวัด'),
  district: yup.string().required('กรุณาเลือก อำเภอ/เขต'),
  password: yup
    .string()
    .min(6, 'กรุณากรอกรหัสผ่านที่มีความยาวตั้งแต่ 6 ตัวขึ้นไป')
    .max(32, 'กรุณากรอกรหัสผ่านที่มีความยาวตั้งแต่ 6 ถึง 32 ตัว')
    .required('กรุณาตั้งรหัสผ่านใหม่'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password')],
      'กรุณากรอกยืนยันรหัสผ่านให้ตรงกับรหัสผ่านใหม่',
    )
    .required('กรุณากรอกยืนยันรหัสผ่าน'),
})

function RegisterForm({ onChangeForm, code, lineUId }: RegisterFormProps) {
  const { isMobile } = useResponsive()
  const [, setOpenLoginBar] = useRecoilState(
    useSystem.selector.selectorSwipeableDrawerLoginStateAtom,
  )
  const [openPolicyModal, setOpenPolicyModal] = useState<boolean>(false)
  const [acceptPolicy, setAcceptPolicy] = useState<boolean>(false)
  const [errorModalData, setErrorModalData] = useState({
    title: 'สมัครสมาชิกไม่สำเร็จ',
    description: 'กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง',
    open: false,
  })

  const [registerMutation, executeMutationRegister] = useMutation(
    RegisterMutate.register,
  )

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'สมัครสมาชิกไม่สำเร็จ',
      description: 'กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง',
      open: false,
    })
  }

  const createNewUser = async (values: RegisterType.RegisterForm) => {
    let payload: IRegisterSubmit = {
      data: {
        prefix: values.prefix,
        firstname: values.firstname,
        lastname: values.lastname,
        UserAuth: {
          create: {
            phoneNumber: values.phone,
            password: values.password.trim(),
            lineUId: lineUId,
          },
        },
        UserMedicalDemograhicData: {
          create: {
            birthdate: dayjs(values.birthdate).toDate(),
            gender: values.gender,
            province: values.province,
            district: values.district,
          },
        },
        role: {
          create: {
            name: '',
            UserRolePermissionTrasaction: {
              create: {
                userRolePermission: {},
              },
            },
          },
        },
      },
    }

    if (code)
      payload.data.EnterpriseTreatmentPlaceTransaction = {
        create: {
          enterpriseTreatmentPlace: {
            connect: {
              code: code,
            },
          },
        },
      }

    if (acceptPolicy) {
      const result = await executeMutationRegister(payload)
      const { data, error } = result
      if (error) {
        setErrorModalData({
          ...handleErrorMessage(error?.graphQLErrors[0]?.message),
          open: true,
        })
        return
      }
      general.cookie.setCookie(
        appSettings.REGISTER_KEY,
        JSON.stringify(data),
        dayjs().add(1, 'h').toDate(),
      )
      await onChangeForm(RegisterType.RegiseterStatus.AUTH)

      return
    }
    setOpenPolicyModal(true)
  }

  const handleErrorMessage = (
    text?: string,
  ): RegisterType.RegisterErrorMessage => {
    let errorMessage: RegisterType.RegisterErrorMessage = {
      title: 'สมัครสมาชิกไม่สำเร็จ',
      description: 'กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง',
    }

    if (text === 'This registeration is procressing') {
      return {
        ...errorMessage,
        description: 'เบอร์โทรศัพท์นี้อยู่ในระหว่างลงทะเบียน',
      }
    }
    if (text === 'Gateway response send sms fail.') {
      return {
        title: 'ไม่สามารถทำการส่งรหัส OTP ได้',
        description: 'กรุณาตรวจสอบเบอร์โทรศัพท์อีกครั้ง',
      }
    }
    if (text === 'This phone number is already in use') {
      return {
        ...errorMessage,
        description: 'เบอร์โทรศัพท์นี้ได้ทำการลงทะเบียนเเล้ว',
      }
    }
    return {
      title: 'สมัครสมาชิกไม่สำเร็จ',
      description: 'กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง',
    }
  }

  const onClickLogin = () => {
    if (!registerMutation.fetching) {
      if (isMobile) {
        setOpenLoginBar(true)
        return
      }
      general.http.goto('/login')
    }
  }

  return (
    <div>
      <div className="text-center laptop:text-start">
        <p
          id="register-title-message"
          className="text-[30px] laptop:text-[32px] font-bold mb-[16px] laptop:mb-[20px]"
        >
          สมัครสมาชิก
        </p>
        <p className="text-[19px] laptop:text-[24px]">
          ยินดีต้อนรับสู่ DR. ASA Primary Care Platform
        </p>
      </div>

      <hr className="border-t-[1px] border-dashed border-gray-dark mt-[25px] mb-[48px]" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={createNewUser}
      >
        {(formik) => (
          <Form>
            <ConnectedFocusError />
            <div className="text-start w-full laptop:w-[470px]">
              <PersonForm form={formik} isLoading={registerMutation.fetching} />

              <hr className="border-t-[1px] border-dashed border-gray-dark mt-[48px] mb-[30px]" />
              <AddressForm
                form={formik}
                isLoading={registerMutation.fetching}
              />

              <hr className="border-t-[1px] border-dashed border-gray-dark mt-[48px] mb-[30px]" />
              <PasswordForm
                form={formik}
                isLoading={registerMutation.fetching}
              />

              <div className="flex gap-[13px] items-start mt-[37px] mb-[44px]">
                <Checkbox
                  disabled={registerMutation.fetching}
                  id="aceept-policy-checkbox"
                  checked={acceptPolicy}
                  onChange={() => setAcceptPolicy(!acceptPolicy)}
                  className="h-[25px] w-[25px] cursor-pointer"
                />

                <p>
                  ฉันได้อ่านและยอมรับ
                  <span
                    id="pivarcy-policy-link"
                    className="text-blue-main cursor-pointer"
                    onClick={() =>
                      !registerMutation.fetching && setOpenPolicyModal(true)
                    }
                  >
                    นโยบายความเป็นส่วนตัว
                  </span>
                  และ
                  <span
                    id="term-policy-link"
                    className="text-blue-main cursor-pointer"
                    onClick={() =>
                      !registerMutation.fetching && setOpenPolicyModal(true)
                    }
                  >
                    ข้อกำหนดการใช้
                  </span>
                  ของระบบ DR. ASA Primary Care Platform
                </p>
              </div>

              <ElementComponent.Button
                submit
                loading={registerMutation.fetching}
                id="register-button"
                text="สมัครสมาชิก"
                width="100%"
                height="52px"
              />

              <div className="flex gap-[10px] justify-center mt-[20px]">
                <p>เป็นสมาชิกอยู่แล้ว, </p>
                <p
                  id="login-link"
                  onClick={onClickLogin}
                  className="text-blue-main cursor-pointer"
                >
                  เข้าสู่ระบบ?
                </p>
              </div>

              <ModalComponent.PolicyModal
                open={openPolicyModal}
                policy={acceptPolicy}
                setPolicy={setAcceptPolicy}
                onClose={() => setOpenPolicyModal(false)}
              />
            </div>
          </Form>
        )}
      </Formik>
      <AlertComponent.ErrorModal
        open={errorModalData.open}
        onClose={onCloseErrorModal}
        title={errorModalData.title}
        description={errorModalData.description}
      />
    </div>
  )
}

export default RegisterForm

import React from 'react'

import { OverviewQuery } from 'services/graphql/overview'
import { date } from 'utils'

import { useQuery } from 'urql'

interface CardStatusDataProps {
  dieaseData: {
    adaId: string
    unitThai: string
    indexSelect: string
  }
}

function CardStatusData({ dieaseData }: Readonly<CardStatusDataProps>) {
  const [medicalTrasactionData] = useQuery(
    OverviewQuery.getLatestMedDataTransactionByadaID(dieaseData.adaId),
  )

  const dataMap = JSON.parse(dieaseData.indexSelect)

  const colorMap = {
    0: {
      bgColor: 'bg-red-graph-critical-text',
      textColor: 'text-white-main',
    },
    1: {
      bgColor: 'bg-yellow-graph-warning-text',
      textColor: 'text-black-main',
    },
    2: {
      bgColor: 'bg-yellow-graph-moderate-text',
      textColor: 'text-black-main',
    },
    3: {
      bgColor: 'bg-green-graph-moderate-high-text',
      textColor: 'text-white-main',
    },
    4: {
      bgColor: 'bg-green-graph-normal-text',
      textColor: 'text-white-main',
    },
  }

  const RenderData = () => {
    if (medicalTrasactionData.fetching) return <h1>Loading...</h1>

    if (medicalTrasactionData.error) return <h1>Error</h1>

    if (medicalTrasactionData.data.getMedDataTransactions.length === 0)
      return (
        <div className="flex flex-col justify-center items-center gap-2">
          <h1 className="font-bold">ไม่มีข้อมูล</h1>
          <div className="bg-gray-light border border-transparent rounded-xl">
            <h1 className="px-4 text-black-main">-</h1>
          </div>
        </div>
      )

    const data = medicalTrasactionData.data.getMedDataTransactions

    const createDate =
      data.length !== 0
        ? date.convertToShowDateThai(data[0].createdAt)
        : 'ไม่มีข้อมูล'

    const value = data.length !== 0 ? data[0].value.toString() : '-'

    return (
      <div className="flex flex-col justify-center items-center gap-2">
        <h1 className="font-bold">{createDate}</h1>
        <div
          className={`${
            colorMap[dataMap[value].bgColor].bgColor
          } border border-transparent rounded-xl`}
        >
          <h1 className={`px-4 ${colorMap[dataMap[value].bgColor].textColor}`}>
            {dataMap[value].word}
          </h1>
        </div>
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col items-center gap-2">
      <RenderData />
    </div>
  )
}

export default CardStatusData

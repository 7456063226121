/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@mui/material'
import * as SVG from 'assets/svg'
import { OverviewQuery } from 'services/graphql/overview'
import { useQuery } from 'urql'
import * as Pics from 'assets/picture'
import { RecommendComponent } from 'components'
function Symptom() {
  const mapStringValue = {
    '0': {
      value: 20,
      color: '#BD0202',
    },
    '1': {
      value: 40,
      color: '#F4A648',
    },
    '2': {
      value: 60,
      color: '#F2EB49',
    },
    '3': {
      value: 80,
      color: '#3CE93C',
    },
    '4': {
      value: 100,
      color: '#06C755',
    },
  }
  const [medicalQueryData] = useQuery(OverviewQuery.getUserOverviewData())

  const [bloodPressureScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Hypertension', //ความดัน
    ),
  )

  const [diabetesScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Diabetes mellitus (DM)', //โรคเบาหวาน
    ),
  )

  const [bloodFatScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Dyslipidemia', //โรคไขมันในเลือด
    ),
  )

  const [liverScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Chronic Liver Disease/Cirrhosis (CLD)', //โรคตับ
    ),
  )

  const [kidneyScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(
      'Chronic Kidney Disease (CKD)', //โรคไต
    ),
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const categorysList: {
    index: number
    title: string
    imgName: string
    data
  }[] = [
    {
      index: 0,
      title: 'โรคเบาหวาน',
      imgName: Pics.I1,
      data: diabetesScorce,
    },
    {
      index: 1,
      title: 'โรคไขมันในเลือดสูง',
      imgName: Pics.I2,
      data: bloodFatScorce,
    },
    {
      index: 2,
      title: 'โรคความดันโลหิตสูง',
      imgName: Pics.I3,
      data: bloodPressureScorce,
    },
    {
      index: 3,
      title: 'โรคตับ',
      imgName: Pics.I4,
      data: liverScorce,
    },
    {
      index: 4,
      title: 'โรคไต',
      imgName: Pics.I5,
      data: kidneyScorce,
    },
  ]

  return (
    <>
      <Stack className="items-center">
        <div
          className="mt-[16px] text-center w-[50%]"
          style={{
            padding: '2px 12px',
            backgroundColor: '#E40009',
            borderRadius: '15px',
            borderWidth: '1px',
          }}
        >
          <h5 style={{ color: '#FFF' }}>กลับสู่หน้าหลัก</h5>
        </div>
        <div className="mt-[16px]">
          <SVG.HeaderSym />
        </div>
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-around'}
        className="text-blue-main mt-4 text-[16px]"
      >
        <p>โรคประจำตัวของคุณ</p>
        <Stack direction={'column'}>
          {!medicalQueryData.fetching && !medicalQueryData.error ? (
            <>
              {medicalQueryData.data?.getUser.UserMedicalDemograhicData.userUnderlyingDiseaseTrasaction.map(
                (item) => (
                  <p>•{item.underlyingDisease.thaiName}</p>
                ),
              )}
            </>
          ) : (
            <>ไม่มี</>
          )}
        </Stack>
      </Stack>
      {!medicalQueryData.fetching && !medicalQueryData.error ? (
        <>
          {medicalQueryData.data?.getUser.UserMedicalDemograhicData.userUnderlyingDiseaseTrasaction.map(
            (item) => (
              <h5 style={{ color: '#FFF' }}>
                {categorysList.map(
                  (sitem) =>
                    sitem.title === item.underlyingDisease.thaiName &&
                    !sitem.data.fetching &&
                    !sitem.data.error &&
                    sitem.data.data.getHealthStatusDiseaseScorces.length >
                      0 && (
                      <RecommendComponent.SymptomItem
                        title={sitem.title}
                        score={
                          mapStringValue[
                            Math.round(
                              sitem.data.data.getHealthStatusDiseaseScorces[0].value.toPrecision(
                                1,
                              ),
                            )
                          ].value
                        }
                      />
                    ),
                )}
              </h5>
            ),
          )}
        </>
      ) : (
        <>ไม่มี</>
      )}
    </>
  )
}

export default Symptom

import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { FormComponent } from 'components'
import { ConsultingFormSVG } from 'assets/svg'
import { useResponsive } from 'hooks'
import { general } from 'utils'
import { appSettings } from 'settings'
import { SuccessProgressPages } from 'pages'

function ConsultingPages() {
  const { isLaptop } = useResponsive()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (general.cookie.getCookie(appSettings.CONSULTING_KEY) === '')
      general.http.redirect()
  }, [])

  if (searchParams.get('upload') === 'success') {
    general.cookie.removeCookie(appSettings.CONSULTING_KEY)

    return <SuccessProgressPages.UploadSuccessPage />
  }

  return (
    <div className="flex h-full">
      {isLaptop && (
        <div
          style={{ backgroundColor: 'rgba(20, 117, 187, 1)' }}
          className="w-1/2 text-white-main text-center flex flex-col justify-center items-center"
        >
          <ConsultingFormSVG />
          <p className="text-[28px] mt-[60px] p-[20px]">
            DR. ASA PRIMARY CARE PLATFORM
          </p>
          <p className="mx-[130px]">
            “จะรอให้ตัวเองป่วยทำไม ในเมื่อคุณป้องกันโรคของตัวเองได้
            มาออกแบบสุขภาพตัวเองกับหมอประจำตัวด้วย Dr. ASA”
          </p>
        </div>
      )}

      <div className="w-full laptop:w-1/2 text-center px-[18px] py-[50px] laptop:p-[57px] bg-white-dark overflow-auto">
        <FormComponent.ConsultingForm />
      </div>
    </div>
  )
}

export default ConsultingPages

import React, { useEffect } from 'react'
import { Outlet, useMatch, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useQuery } from 'urql'

import NavbarDesktop from './NavbarDesktop'
import NavbarMobile from './NavbarMobile'

import { Footer } from 'components/footer'
import { route } from 'settings'
import { general } from 'utils'
import { useResponsive } from 'hooks'
import { useUser } from 'stores/recoil'
import { UserQuery } from 'services/graphql/userService'

function MainLayout() {
  const { isLaptop } = useResponsive()
  const location = useLocation()
  const [result] = useQuery(UserQuery.getUserProfile())
  const [, setUser] = useRecoilState(useUser.seletor.seletorUser)

  useEffect(() => {
    ;(async () => {
      if (general.auth.isAuthorized()) {
        const data = await result?.data?.getUser

        if (data) {
          setUser({
            firstname: data.firstname ?? '',
            imagePath: data.imagePath ?? '',
            hnId: data.hnId ?? 0,
            uId: data.uId ?? '',
          })
        }
      }
    })()
  }, [result])

  const hiddenFooter = (): boolean => {
    const login = useMatch(route.LOGIN_PAGE_URL)
    const register = useMatch(route.REGISTER_PAGE_URL)
    const forget = useMatch(route.FORGET_PASSWORD_PAGE_URL)
    const consulting = useMatch(route.CONSULTING_FORM_PAGE_URL)
    const upload = Boolean(
      location.pathname === route.UPLOAD_HEALTH_PAGE_URL &&
        location.search === '?upload=success',
    )
    const createData = Boolean(
      location.pathname === route.CREATE_HEALTH_PAGE_URL &&
        location.search === '?upload=success',
    )

    return (
      Boolean(login) ||
      Boolean(register) ||
      Boolean(forget) ||
      Boolean(consulting) ||
      Boolean(upload) ||
      Boolean(createData)
    )
  }

  return (
    <div>
      <div className="relative min-h-[100vh] min-w-fit">
        {isLaptop ? <NavbarDesktop /> : <NavbarMobile />}
        <div
          className={`pt-[90px]
            ${!hiddenFooter() ? 'pb-[10px]' : ''} 
            ${!hiddenFooter() ? 'h-auto pb-[170px]' : 'h-screen'}`}
        >
          <Outlet />
        </div>
        {!hiddenFooter() && <Footer />}
      </div>
    </div>
  )
}

export default MainLayout

import { Stack } from '@mui/material'
import { ElementComponent } from 'components'
import * as SVG from 'assets/svg'
import { useState } from 'react'
import DrugItem from './DrugItem'
import dayjs from 'dayjs'
import { useRecoilState } from 'recoil'
import { OverviewQuery } from 'services/graphql/overview'
import { useUser } from 'stores/recoil'
import { useQuery } from 'urql'
interface DrugFormProp {
  diseaseImage: string
}
function DrugMain({ diseaseImage }: DrugFormProp) {
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const [patientDiagnoseLastOne] = useQuery(
    OverviewQuery.getDiagnoseLastOne(user['uId']),
  )
  const [drug, setDrug] = useState([
    {
      title: 'Vitamin C',
      detail: 'บำรุงภูมิคุ้มกันให้เเข็งเเรงมากขึ้น',
      subDetail: '1 หน่วย ต่อ 30 วัน',
      price: 1150,
      qty: 0,
    },
    {
      title: 'Vitamin B',
      detail: 'บำรุงภูมิคุ้มกันให้เเข็งเเรงมากขึ้น',
      subDetail: '1 หน่วย ต่อ 30 วัน',
      price: 1150,
      qty: 0,
    },
    {
      title: 'Vitamin A',
      detail: 'บำรุงภูมิคุ้มกันให้เเข็งเเรงมากขึ้น',
      subDetail: '1 หน่วย ต่อ 30 วัน',
      price: 1150,
      qty: 0,
    },
  ])
  return (
    <>
      <Stack className="items-center">
        <div className="mt-[16px]">
          <SVG.HeaderDrug />
        </div>
        <ElementComponent.Image
          fullImage={false}
          disableClick
          src={diseaseImage}
          className=" cursor-default mt-4"
        />
        <p className="mt-4">จากผลการตรวจสุขภาพของคุณเมื่อวันที่</p>
        {!patientDiagnoseLastOne.fetching && !patientDiagnoseLastOne.error ? (
          <>
            <p className="text-[18px] mt-4 text-blue-main">
              วันที่{' '}
              {`${dayjs(
                patientDiagnoseLastOne.data.getDiagnoseLastOne.createdAt.split(
                  'T',
                )[0],
              ).format('DD MMMM BBBB')}`}{' '}
            </p>
          </>
        ) : (
          <p>ไม่มีข้อมูลการพบแพทย์</p>
        )}
        <p className="mt-4">
          สามารถบำรุง พัฒนาร่างกายให้เเข็งเเรงได้มากขึ้น
          โดยการใช้ยาเเละอาหารเสริม ดังนี้
        </p>
      </Stack>

      {drug.map((item, index) => (
        <DrugItem
          data={item}
          index={index}
          drugItemReduceChange={(newItem, indexItem) => {
            const allItem = drug
            allItem[indexItem] = newItem

            setDrug(allItem)

            let price = 0
            drug.map((itemPrice) => {
              price += itemPrice.price * itemPrice.qty
            })
            setTotalPrice(totalPrice - price)
          }}
          drugItemPlusChange={(newItem, indexItem) => {
            const allItem = drug
            allItem[indexItem] = newItem

            setDrug(allItem)

            let price = 0
            drug.map((itemPrice) => {
              price += itemPrice.price * itemPrice.qty
            })
            setTotalPrice(price + totalPrice)
          }}
        />
      ))}

      <Stack className="items-center mt-4 mb-4">
        <Stack direction={'row'}>
          <p className="text-blue-main">ค่าใช้จ่ายรวม</p>
          <p className="ml-2 mr-2">{totalPrice}</p>
          <p className="text-blue-main">บาท</p>
        </Stack>

        <div
          className="mt-[16px] text-center w-[200px]"
          style={{
            padding: '2px 12px',
            backgroundColor: '#0D5CDD',
            borderRadius: '15px',
            borderWidth: '1px',
          }}
        >
          <h5 style={{ color: '#FFF' }}>สั่งซื้ออาหารเสริม</h5>
        </div>
      </Stack>
    </>
  )
}

export default DrugMain

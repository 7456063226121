import { Stack } from '@mui/material'
import * as Pics from 'assets/picture'
import * as SVG from 'assets/svg'
import { useState } from 'react'
interface DrugProp {
  data: any
  drugItemReduceChange: Function
  drugItemPlusChange: Function
  index: number
}
function DrugItem({
  data,
  drugItemReduceChange,
  drugItemPlusChange,
  index,
}: DrugProp) {
  const [qty, setQty] = useState<number>(0)
  const [item, setItem] = useState<any>(data)
  return (
    <Stack direction={'row'} className="p-[6%] text-[14px]">
      <img src={Pics.SampleIcoDrug} className="w-[88px]" />
      <Stack direction={'column'} className="ml-4">
        <p className="text-blue-main">{data.title}</p>
        <p>{data.detail}</p>
        <p className="text-red-main">{data.subDetail}</p>
        <Stack direction={'row'}>
          <div
            className="mt-[-1px]"
            onClick={() => {
              if (qty === 0) return
              setQty(qty - 1)
              const currentItem = item
              currentItem.qty = qty
              setItem(currentItem)
              drugItemReduceChange(currentItem, index)
            }}
          >
            <SVG.BtnDrugReduce />
          </div>
          <p className="ml-2 mr-2">{qty}</p>
          <div
            onClick={() => {
              setQty(qty + 1)
              const currentItem = item
              currentItem.qty = qty
              setItem(currentItem)
              drugItemPlusChange(currentItem, index)
            }}
          >
            <SVG.BtnDrugAdd />
          </div>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default DrugItem

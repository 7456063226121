import React from 'react'

import { LoginForm } from 'components/form'
import { LoginSVG } from 'assets/svg'

function LoginPage() {
  return (
    <div className="flex h-screen">
      <div
        style={{ backgroundColor: 'rgba(20, 117, 187, 1)' }}
        className="w-1/2 text-white-main text-center flex flex-col justify-center items-center"
      >
        <LoginSVG />
        <p className="text-[28px] mt-[60px] p-[20px]">
          DR. ASA PRIMARY CARE PLATFORM
        </p>
        <p className="mx-[130px]">
          “จะรอให้ตัวเองป่วยทำไม ในเมื่อคุณป้องกันโรคของตัวเองได้
          มาออกแบบสุขภาพตัวเองกับหมอประจำตัวด้วย Dr. ASA”
        </p>
      </div>

      <div className="w-1/2 text-center p-[57px] bg-white-dark">
        <LoginForm />
      </div>
    </div>
  )
}

export default LoginPage

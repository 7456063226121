import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useAuth, useSystem } from 'stores/recoil'
import * as Section from './section'
import { AuthMutate } from 'services/graphql/authService'
import { useMutation } from 'urql'
import { appSettings } from 'settings'
import { general } from 'utils'
import liff from '@line/liff'
import { ImgLineConnect } from 'assets/picture'
import { ElementComponent } from 'components'
function MainPage() {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [, setOpenLoginBar] = useRecoilState(
    useSystem.selector.selectorSwipeableDrawerLoginStateAtom,
  )
  const [, executeMutation] = useMutation(AuthMutate.loginWithLine)
  const [, executeLoginUidMutation] = useMutation(AuthMutate.loginWithUid)
  const [, setToken] = useRecoilState(useAuth.selector.selectorToken)
  const [errorModalData, setErrorModalData] = useState({
    title: 'เข้าสู่ระบบไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const loginUser = async (lineId) => {
    try {
      //actions.setStatus(undefined)
      await executeMutation({
        data: {
          lineId: lineId,
        },
      }).then((res) => {
        if (res.error) {
          const error: any = res.error
          const code: number = error.graphQLErrors[0].originalError.code
          if (code === 401) {
            if (general.auth.isAuthorized()) {
              general.http.goto('/overview&lid=' + lineId)
            } else {
              general.http.goto('/register?isLogined=1&lid=' + lineId)
            }
            return
          }
          if (code) {
            setErrorModalData({
              title: 'เข้าสู่ระบบไม่สำเร็จ',
              description: 'กรุณาทดลองใหม่อีกครั้ง',
              open: true,
            })
            return
          }
        }
        general.cookie.setCookie(
          appSettings.TOKEN_KEY,
          res.data.loginWithLine.refreshToken,
        )
        setToken(res.data.loginWithLine.accessToken)
        general.http.redirect()
      })
    } catch (error) {
      setErrorModalData({ ...errorModalData, open: true })
    }
  }

  const loginUserWithUid = async (uId) => {
    try {
      //actions.setStatus(undefined)
      await executeLoginUidMutation({
        data: {
          uId: uId,
        },
      }).then((res) => {
        if (res.error) {
          const error: any = res.error
          const code: number = error.graphQLErrors[0].originalError.code
          if (code === 401) {
            if (general.auth.isAuthorized()) {
              general.http.goto('/overview')
            } else {
              //general.http.goto('/register?isLogined=1&lid=' + lineId)
            }
            return
          }
          if (code) {
            setErrorModalData({
              title: 'เข้าสู่ระบบไม่สำเร็จ',
              description: 'กรุณาทดลองใหม่อีกครั้ง',
              open: true,
            })
            return
          }
        }
        general.cookie.setCookie(
          appSettings.TOKEN_KEY,
          res.data.loginWithUid.refreshToken,
        )
        setToken(res.data.loginWithUid.accessToken)
        general.http.redirect()
      })
    } catch (error) {
      setErrorModalData({ ...errorModalData, open: true })
    }
  }
  const initLine = () => {
    liff.init({ liffId: '2003394345-zpvOmEQ9' }, () => {
      if (liff.isLoggedIn()) {
        runApp()
      } else {
        liff.login()
      }
    })
  }

  const runApp = () => {
    //const idToken = liff.getIDToken()
    //setIdToken(idToken)
    liff.getProfile().then((profile) => {
      loginUser(profile.userId)
    })
  }

  useEffect(() => {
    if (query.get('section') !== null) {
      const element = document.getElementById(`${query.get('section')}`)
      const yOffset = -100
      const y = element?.getBoundingClientRect().top + window.scrollY + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
    if (query.get('login') !== null) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setOpenLoginBar(true)
    }

    if (query.get('line') !== null) {
      initLine()
    }

    if (query.get('juId') !== null) {
      loginUserWithUid(query.get('juId'))
    }
  }, [location.search])

  return (
    <div
      className="w-full"
      style={{
        background: 'white',
      }}
    >
      <Section.Banner />
      <div className="mt-[-70px] tablet:mt-[-100px]">
        <Section.HealthRecord />
        <Section.PersonalDocter />
        <Section.HealthMonitoring />
        <Section.Screening />
        <Section.Howto />
        <Section.Supporter />
      </div>
      <a
        href="https://lin.ee/ACNhtuw"
        style={{ position: 'fixed', bottom: '16px', right: '0px' }}
      >
        <ElementComponent.Image
          fullImage={false}
          src={ImgLineConnect}
          disableClick
        />
      </a>
    </div>
  )
}

export default MainPage

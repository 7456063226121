import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Waypoint } from 'react-waypoint'

import * as Element from 'components/element'
import { LogoSVG } from 'assets/svg'

interface PolicyModalProps {
  open: boolean
  policy: boolean
  setPolicy: Function
  onClose: Function
}

const text = `เราอาจรวบรวมและประมวลผลข้อมูลของคุณดังนี้
ข้อมูลที่คุณให้เรา: เป็นข้อมูลเกี่ยวกับคุณที่คุณมอบให้เราโดย (1) เข้าใช้เว็บไซต์ของเราและเข้าร่วมกิจกรรมต่าง ๆ 
บนเว็บไซต์ของเรา (เช่น เมื่อคุณอาจลงชื่อร่วมรณรงค์หรือบริจาค) (2) กดติดตามเราเพื่อรับข่าวสารจากอีเมล (3) 
ติดต่อเราเพื่อขอข้อมูลหรือความช่วยเหลือ (4) ลงชื่อของคุณในช่องลายเซ็นของเอกสารรณรงค์ (5) เป็นผู้บริจาค
จากช่องทางอื่น ๆ นอกจากทางอินเทอร์เน็ต (6) ร่วมงานรณรงค์กับกรีนพีซ (7) ทำแบบสอบถามในเว็บไซต์นี้ (8) 
ให้ข้อมูลผ่านเว็บไซต์บุคคลที่สามหรือแอปพลิเคชัน โดยคุณยินยอมต่อนโยบายความเป็นส่วนตัวนี้ผ่านเว็บไซต์หรือ
แอปพลิเคชันบุคคลที่สาม โดยข้อมูลนั้นอาจประกอบด้วย ชื่อ ที่อยู่ อีเมล และหมายเลขโทรศัพท์
ข้อมูลเกี่ยวกับคุณที่เว็บไซต์ของเราและระบบอื่นๆ เก็บรวบรวม:
  1. ถ้าคุณเข้าเว็บไซต์บุคคลที่สาม เว็บไซต์ที่รวบรวมและวิเคราะห์ข้อมูล (กูเกิล อนาไลติกส์) จะรวบรวมข้อมูลบางอย่าง
  2. เกี่ยวกับคุณและการเข้าเว็บของคุณ เช่น ประเภทและรุ่นของบราวเซอร์ เวอร์ชั่น และเพจในเว็บไซต์ของเราที่คุณ
  3. เข้าชม อนาไลติกส์จะไม่เก็บรวบรวมหมายเลขประจำเครื่องคอมพิวเตอร์ (IP) ที่ใช้เชื่อมต่ออุปกรณ์ของคุณเข้ากับ
  4. อินเทอร์เน็ต คุณสามารถสอบถามกูเกิล เพื่อให้ดึงข้อมูลของคุณในส่วนที่เกี่ยวข้องกับกรีนพีซ ประเทศไทย ข้อมูล
  5. เหล่านี้สามารถใช้ในการวางแผนการทำงานและช่วยให้เราปรับปรุงเว็บไซต์ให้ตอบสนองผู้ใช้ได้ดีขึ้น
เราอาจรวบรวมและประมวลผลข้อมูลของคุณดังนี้
ข้อมูลที่คุณให้เรา: เป็นข้อมูลเกี่ยวกับคุณที่คุณมอบให้เราโดย (1) เข้าใช้เว็บไซต์ของเราและเข้าร่วมกิจกรรมต่าง ๆ 
บนเว็บไซต์ของเรา (เช่น เมื่อคุณอาจลงชื่อร่วมรณรงค์หรือบริจาค) (2) กดติดตามเราเพื่อรับข่าวสารจากอีเมล (3) 
ติดต่อเราเพื่อขอข้อมูลหรือความช่วยเหลือ (4) ลงชื่อของคุณในช่องลายเซ็นของเอกสารรณรงค์ (5) เป็นผู้บริจาค
จากช่องทางอื่น ๆ นอกจากทางอินเทอร์เน็ต (6) ร่วมงานรณรงค์กับกรีนพีซ (7) ทำแบบสอบถามในเว็บไซต์นี้ (8) 
ให้ข้อมูลผ่านเว็บไซต์บุคคลที่สามหรือแอปพลิเคชัน โดยคุณยินยอมต่อนโยบายความเป็นส่วนตัวนี้ผ่านเว็บไซต์หรือ
แอปพลิเคชันบุคคลที่สาม โดยข้อมูลนั้นอาจประกอบด้วย ชื่อ ที่อยู่ อีเมล และหมายเลขโทรศัพท์`

function PolicyModal({ open, policy, setPolicy, onClose }: PolicyModalProps) {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (policy) setButtonDisabled(false)
  }, [policy])

  return (
    <Element.ModalForm open={open} onClose={() => onClose()}>
      <div className="flex flex-col items-center py-[30px] px-[40px] w-screen max-w-[800px] max-h-[700px]">
        <LogoSVG />
        <p className="mt-[33px] mb-[24px] text-[20px] font-bold">
          นโยบายความเป็นส่วนตัว และ ข้อกำหนดการใช้
        </p>

        <div className="w-full max-w-[785px] h-[400px] p-[20px] bg-white-darker whitespace-pre-line rounded-[10px] text-[16px] overflow-auto">
          <p>{text}</p>
          <Waypoint onEnter={() => setButtonDisabled(false)} />
        </div>

        <div className="w-full mt-[12px] mb-[50px]">
          <span className="text-[16px] text-red-main font-bold">
            หมายเหตุ * กรุณาเลื่อนอ่านจนสุด จึงกดยอมรับค่ะ
          </span>
        </div>

        <div className="max-w-[700px] w-full">
          <Grid container spacing={{ mobile: '10px', laptop: '20px' }}>
            <Grid item mobile>
              <Element.Button
                id="cancel-button"
                text="ยกเลิก"
                width="100%"
                height="50px"
                shape="outlined"
                onAction={() => onClose()}
              />
            </Grid>
            <Grid item mobile>
              <Element.Button
                id="accept-button"
                text="ยอมรับ"
                width="100%"
                height="50px"
                disable={buttonDisabled}
                onAction={() => {
                  setPolicy(true)
                  onClose()
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Element.ModalForm>
  )
}

export default PolicyModal

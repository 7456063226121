import React from 'react'
import { Typography } from '@mui/material'

import {
  Logo2SVG,
  FacebookIconSVG,
  LineIconSVG,
  TiktokIconSVG,
  EmailIconSvg,
  YoutubeIconSvg,
} from 'assets/svg'

import { urls } from 'settings'

function FooterDesktop() {
  return (
    <div className="h-[170px] w-full absolute bottom-0 flex justify-between items-center px-[20px] laptop:px-[50px] py-[20px] bg-black-light text-white-main">
      <div>
        <Logo2SVG className="mb-[22px] mobile:w-15 mobile:h-10 desktop:w-32 desktop:h-16" />
        <h1 className="desktop:text-[15px] mobile:text-[10px]">
          © 2022, All rights reserved
        </h1>
      </div>
      <div>
        <Typography variant="body1">ติดตามเราได้ทาง Social Media</Typography>
        <div className="flex justify-center justify content-center gap-[10px] mt-[0px]">
          <a href="" target="_blank">
            <EmailIconSvg className="desktop:w-10 desktop:h-10 mobile:w-8 mobile:h-8" />
          </a>
          <a href="" target="_blank">
            <YoutubeIconSvg className="desktop:w-12 desktop:h-10 mobile:w-10 mobile:h-8" />
          </a>
          <a href={urls.LINE_OFFCIAL_GRUOP} target="_blank">
            <LineIconSVG className="desktop:w-10 desktop:h-10 mobile:w-8 mobile:h-8" />
          </a>
          <a href={urls.FACEBOOK_GROUP} target="_blank">
            <FacebookIconSVG className="desktop:w-10 desktop:h-10 mobile:w-8 mobile:h-8" />
          </a>
          <a href={urls.TIKTOK_GROUP} target="_blank">
            <TiktokIconSVG className="desktop:w-10 desktop:h-10 mobile:w-8 mobile:h-8" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default FooterDesktop

import React from 'react'
import { Stack } from '@mui/material'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import { useResponsive } from 'hooks'
import { route } from 'settings'
import { MenuComponent } from 'components'
import * as Pics from 'assets/picture'
const menuListOption = {
  menu: [
    {
      text: 'ประวัติสุขภาพ',
      icon: Pics.MenuHistory,
      path: route.OVERVIEW_PAGE_URL,
      sub_menu: [
        {
          text: 'โรคเบาหวาน',
          icon: Pics.IcoHis01,
          path: route.HISTORY_DIABETES_PAGE_URL,
        },
        {
          text: 'โรคไขมันในเลือดสูง',
          icon: Pics.IcoHis02,
          path: route.HISTORY_BLOOD_FAT_PAGE_URL,
        },
        {
          text: 'โรคความดัน',
          icon: Pics.IcoHis03,
          path: route.HISTORY_BLOOD_PRESSURE_PAGE_URL,
        },
        {
          text: 'โรคตับ',
          icon: Pics.IcoHis04,
          path: route.HISTORY_LIVER_PAGE_URL,
        },
        {
          text: 'โรคไต',
          icon: Pics.IcoHis05,
          path: route.HISTORY_KIDNEY_PAGE_URL,
        },
        {
          text: 'โรคหัวใจ',
          icon: Pics.IcoHis06,
          path: route.HISTORY_HEART_PAGE_URL,
        },
        {
          text: 'โรคอ้วน',
          icon: Pics.IcoHis07,
          path: route.HISTORY_FAT_PAGE_URL,
        },
      ],
    },
    {
      text: 'เพิ่มผลเลือด',
      icon: Pics.MenuAddBlood,
      path: route.CREATE_HEALTH_PAGE_URL,
    },
    {
      text: 'วางแผนสุขภาพ',
      icon: Pics.MenuPlan,
      path: route.HEALTH_PAGE_URL,
    },
    {
      text: 'ยา',
      icon: Pics.MenuMedical,
      path: route.HEALTH_PAGE_URL,
    },
    {
      text: 'คำแนะนำสุขภาพ',
      icon: Pics.MenuRecommend,
      path: route.HEALTH_PAGE_URL,
    },
    {
      text: 'คำแนะนำสุขภาพ vip',
      icon: Pics.MenuVip,
      path: route.HEALTH_PAGE_URL,
    },
  ],
}

function UserLayout() {
  const { isLaptop } = useResponsive()
  const navigate = useNavigate()
  const location = useLocation()

  const fullScreen = (): boolean => {
    const uploadSuccess = Boolean(
      location.pathname === route.UPLOAD_HEALTH_PAGE_URL &&
        location.search === '?upload=success',
    )
    const createSuccess = Boolean(
      location.pathname === route.CREATE_HEALTH_PAGE_URL &&
        location.search === '?upload=success',
    )

    return uploadSuccess || createSuccess
  }

  if (fullScreen()) {
    return (
      <div>
        <Outlet />
      </div>
    )
  }

  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'row' }}
      className="bg-white-dark h-full min-h-[80vh] laptop:min-h-[77vh] flex mt-[-90px] laptop:mt-0 laptop:pl-[20px] pt-[60px] pb-[90px]"
    >
      {isLaptop && (
        <MenuComponent.UserOptionBar
          //title={menuListOption.title}
          menu={menuListOption.menu}
          options={location.pathname}
          onChangeOptions={(path: string) => {
            navigate(path)
            window.scrollTo(0, 0)
          }}
        />
      )}

      <div className="w-full px-[20px] pt-0 laptop:pl-0 laptop:pr-[50px] laptop:ml-[345px]">
        <Outlet />
      </div>
    </Stack>
  )
}

export default UserLayout

import React, { useState } from 'react'
import { Grid, Stack } from '@mui/material'
import { useQuery } from 'urql'
import { useRecoilState } from 'recoil'
import { OverviewQuery } from 'services/graphql/overview'
import { ElementComponent, SkeletonComponent, ChartCompoent } from 'components'
import { useUser } from 'stores/recoil'
import { data, date } from 'utils'
import * as SVG from 'assets/svg'
import * as Pics from 'assets/picture'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import ReactSpeedometer from 'react-d3-speedometer'

function HealthCard({
  fetching,
  error,
  cardData,
}: {
  fetching: boolean
  error: any
  cardData: any
}) {
  //const { isMobile } = useResponsive()
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const [imageStateError, setImageStateError] = useState<boolean>(false)
  const imageOnError = () => {
    setImageStateError(true)
  }
  const mapValue = {
    '0': {
      value: 0.2,
      valueStr: 20,
      color: '#E40009',
    },
    '1': {
      value: 0.4,
      color: '#F19701',
    },
    '2': {
      value: 0.6,
      color: '#FBFF01',
    },
    '3': {
      value: 0.8,
      color: '#CDD801',
    },
    '4': {
      value: 1.0,
      color: '#69ae33',
    },
  }
  const categorysList: { title: string; imgName: string; dId: string }[] = [
    {
      title: 'เบาหวาน',
      imgName: Pics.CategoryIco01,
      dId: 'Diabetes mellitus (DM)',
    },
    {
      title: 'ไขมัน',
      imgName: Pics.CategoryIco02,
      dId: 'Dyslipidemia',
    },
    {
      title: 'ความดัน',
      imgName: Pics.CategoryIco03,
      dId: 'Hypertension',
    },
    {
      title: 'ตับ',
      imgName: Pics.CategoryIco04,
      dId: 'Chronic Liver Disease/Cirrhosis (CLD)',
    },
    {
      title: 'ไต',
      imgName: Pics.CategoryIco05,
      dId: 'Chronic Kidney Disease (CKD)',
    },
    /*{
      title: 'หัวใจ',
      imgName: Pics.CategoryIco06,
    },
    {
      title: 'อ้วน',
      imgName: Pics.CategoryIco07,
    },
    {
      title: 'กระดูก',
      imgName: Pics.CategoryIco08,
    },*/
  ]
  const [diseaseScore, setDiseaseScore] = useState<string>(
    'Diabetes mellitus (DM)',
  )
  const [diseaseTitle, setDiseaseTitle] = useState<string>('เบาหวาน')

  const [diabetesScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(diseaseScore),
  )

  const [diabetesLists] = useQuery(
    OverviewQuery.getDiseasesAttibutes(diseaseScore),
  )
  const formatUnderlyingDisease = (
    data: {
      underlyingDisease: {
        thaiName: string
        engName: string
      }
    }[],
  ) => {
    let formatString = ''
    data.forEach((item, index) => {
      if (index === data.length - 1)
        formatString += `${item.underlyingDisease.thaiName}`
      else formatString += `${item.underlyingDisease.thaiName},`
    })
    return formatString
  }
  return (
    <>
      <Stack
        direction={{ mobile: 'column', laptop: 'column' }}
        className="gap-[18px] "
        alignItems={'top'}
      >
        <Stack
          direction={{ mobile: 'column', laptop: 'row' }}
          className="gap-[18px]"
          alignItems={'top'}
        >
          <div className="w-[100%] laptop:w-[50%]  bg-cover">
            <Stack
              direction={{ mobile: 'column', laptop: 'column' }}
              alignItems={'top'}
              className="gap-[18px] items-center"
              style={{
                borderColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
                padding: '25px',
                backgroundColor: '#FFF',
              }}
            >
              <div>
                <Stack
                  direction={{ mobile: 'column', laptop: 'column' }}
                  className="gap-[18px] items-center"
                >
                  <div className="flex items-center gap-[10px]">
                    {!imageStateError && user['imagePath'] ? (
                      <div>
                        <ElementComponent.Image
                          fullImage={false}
                          className="h-[30px] w-[30px] rounded-full bg-cover bg-center"
                          src={user['imagePath']}
                          onError={imageOnError}
                        />
                      </div>
                    ) : (
                      <SVG.AvatarProfile className="h-[50px] w-[50px] rounded-full bg-cover bg-center" />
                    )}
                    <Stack direction={{ mobile: 'column', laptop: 'column' }}>
                      <h5 style={{ color: '#0D5CDD' }}>{user['firstname']}</h5>
                      <div
                        style={{
                          padding: '2px 12px',
                          backgroundColor: '#0D5CDD',
                          borderRadius: '15px',
                          borderWidth: '1px',
                        }}
                      >
                        <h5 style={{ color: '#FFF' }}>
                          HN {data.formatId(user['hnId'].toString())}
                        </h5>
                      </div>
                    </Stack>
                  </div>
                </Stack>
              </div>
              <Grid className="mt-[16px] w-[100%]" item mobile={1}>
                {!fetching && !error ? (
                  <div className="flex flex-col text-[13px] laptop:text-[14px] text-black-main">
                    <Grid container>
                      <Grid item mobile={5}>
                        <p>
                          <b>ชื่อ :</b>
                        </p>
                      </Grid>
                      <Grid item>
                        <p>{user['firstname']}</p>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '5px' }}>
                      <Grid item mobile={5}>
                        <p>
                          <b>อายุ :</b>
                        </p>
                      </Grid>
                      <Grid item>
                        <p>
                          {date.getAge(
                            cardData.UserMedicalDemograhicData.birthdate,
                          )}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '5px' }}>
                      <Grid item mobile={5}>
                        <p>
                          <b>เกิด :</b>
                        </p>
                      </Grid>
                      <Grid item>
                        <p>
                          {date.convertToShowDateThai(
                            cardData.UserMedicalDemograhicData.birthdate,
                          )}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item mobile={5}>
                        <p>
                          <b>เพศ :</b>
                        </p>
                      </Grid>
                      <Grid item>
                        <p>{cardData.UserMedicalDemograhicData.gender}</p>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '5px' }}>
                      <Grid item mobile={5}>
                        <p>
                          <b>ภูมิลำเนา:</b>
                        </p>
                      </Grid>
                      <Grid item>
                        <p>{cardData.UserMedicalDemograhicData.province}</p>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '5px' }}>
                      <Grid item mobile={5}>
                        <p>
                          <b>แพ้ยา/อาหาร :</b>
                        </p>
                      </Grid>
                      <Grid item>
                        <p>
                          {cardData.UserMedicalDemograhicData.allergic !== ''
                            ? cardData.UserMedicalDemograhicData.allergic
                            : 'ไม่มี'}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '5px' }}>
                      <Grid item mobile={5}>
                        <div
                          className="w-[110px] laptop:w-[130px] "
                          style={{
                            padding: '2px 12px',
                            backgroundColor: '#0D5CDD',
                            borderRadius: '15px',
                            borderWidth: '1px',
                          }}
                        >
                          <p style={{ color: '#FFF' }}>โรคประจำตัว :</p>
                        </div>
                      </Grid>
                      <Grid item mobile={5}>
                        <p>
                          {formatUnderlyingDisease(
                            cardData.UserMedicalDemograhicData
                              .userUnderlyingDiseaseTrasaction,
                          ) !== ''
                            ? formatUnderlyingDisease(
                                cardData.UserMedicalDemograhicData
                                  .userUnderlyingDiseaseTrasaction,
                              )
                            : 'ไม่มี'}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '5px' }}>
                      <Grid item mobile={5}>
                        <div
                          className="w-[110px] laptop:w-[130px] "
                          style={{
                            padding: '2px 12px',
                            backgroundColor: '#0D5CDD',
                            borderRadius: '15px',
                            borderWidth: '1px',
                          }}
                        >
                          <p
                            className="text-[12px] laptop:text-[14px]"
                            style={{ color: '#FFF' }}
                          >
                            หน่วยงานที่ดูแล :
                          </p>
                        </div>
                      </Grid>
                      <Grid item mobile={5}>
                        <p>โรงพยาบาลราชพิพัฒน์</p>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '5px' }}>
                      <Grid item mobile={5}>
                        <div
                          className="w-[110px] laptop:w-[130px] "
                          style={{
                            padding: '2px 12px',
                            backgroundColor: '#0D5CDD',
                            borderRadius: '15px',
                            borderWidth: '1px',
                          }}
                        >
                          <p style={{ color: '#FFF' }}>แพทย์ประจำตัว :</p>
                        </div>
                      </Grid>
                      <Grid item mobile={5}>
                        <p>นพ. ธนกร ยินต์นิยม</p>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <SkeletonComponent.CardProfileSkeleton />
                )}
              </Grid>
            </Stack>
          </div>
          <div className="w-[100%] laptop:w-[40%]  bg-cover ">
            <Stack
              direction={{ mobile: 'column', laptop: 'column' }}
              alignItems={'top'}
              className="gap-[18px]"
              style={{
                borderColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
                backgroundColor: '#FFF',
              }}
            >
              <div>
                <Stack
                  direction={{ mobile: 'column', laptop: 'column' }}
                  className="gap-[18px] items-center"
                >
                  <div
                    className="mt-[-15px]"
                    style={{
                      padding: '2px 12px',
                      backgroundColor: '#0D5CDD',
                      borderRadius: '15px',
                      borderWidth: '1px',
                    }}
                  >
                    <h5 style={{ color: '#FFF' }}>
                      สุขภาพโดยรวมของโรค{diseaseTitle}
                    </h5>
                  </div>

                  {!diabetesScorce.fetching &&
                  !diabetesScorce.error &&
                  diabetesScorce.data.getHealthStatusDiseaseScorces.length >
                    0 ? (
                    <div
                      style={{
                        height: '200px',
                      }}
                    >
                      <ReactSpeedometer
                        maxSegmentLabels={0}
                        segments={1000}
                        maxValue={100}
                        value={
                          mapValue[
                            diabetesScorce.data.getHealthStatusDiseaseScorces[0].value.toPrecision(
                              1,
                            )
                          ].value * 100
                        }
                        textColor={'#000'}
                        currentValueText={'${value}%'}
                      />
                    </div>
                  ) : (
                    <ReactSpeedometer
                      maxSegmentLabels={0}
                      segments={1000}
                      maxValue={100}
                      value={0}
                      textColor={'#000'}
                      currentValueText={'ไม่มีข้อมูล'}
                    />
                  )}
                </Stack>
              </div>
            </Stack>
            <Stack
              direction={{ mobile: 'column', laptop: 'column' }}
              alignItems={'top'}
              className="gap-[18px] items-center mt-[36px]"
              style={{
                borderColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
                backgroundColor: '#FFF',
              }}
            >
              <div
                className="mt-[-15px]"
                style={{
                  padding: '2px 12px',
                  backgroundColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                }}
              >
                <h5 style={{ color: '#FFF' }}>เลือกโรคดูผลเลือด</h5>
              </div>
              <div>
                <Swiper
                  className="w-[300px] laptop:w-[400px]  bg-cover"
                  slidesPerView={4}
                  spaceBetween={1}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Navigation, Pagination]}
                >
                  {categorysList.map(
                    (item: { title: string; imgName: string; dId: string }) => (
                      <SwiperSlide
                        className="mb-[18px]"
                        onClick={() => {
                          setDiseaseScore(item.dId)
                          setDiseaseTitle(item.title)
                        }}
                      >
                        <ElementComponent.Image
                          fullImage={false}
                          src={item.imgName}
                          className="h-[90px]"
                        />
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
              </div>
            </Stack>
          </div>
        </Stack>
        <Stack
          direction={{ mobile: 'row', laptop: 'row' }}
          className="items-center mt-[8px]"
          style={{ justifyContent: 'center' }}
        >
          <SVG.IconDiabete className="w-[48px] h-[48px]   mr-[-18px] z-[1]" />
          <div
            style={{
              padding: '2px 12px',
              backgroundColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
              height: '32px',
            }}
          >
            <h5 style={{ color: '#FFF', marginLeft: '10px' }}>
              {diseaseTitle}
            </h5>
          </div>
        </Stack>
        <Stack
          direction={{ mobile: 'column', laptop: 'row' }}
          alignItems={'top'}
          className="gap-[18px] w-[100%] laptop:w-[92%] p-[10px] laptop:p-[25px]"
          style={{
            borderColor: '#0D5CDD',
            borderRadius: '15px',
            borderWidth: '1px',
            backgroundColor: '#FFF',
          }}
        >
          {!diabetesLists.fetching &&
          !diabetesLists.error &&
          diabetesLists.data.getDiseasesAttibutes.length > 0 ? (
            <>
              {diabetesLists.data.getDiseasesAttibutes.map((item) => {
                return (
                  <>
                    <div className="gap-[18px] w-[100%] laptop:w-[50%]">
                      <ChartCompoent.LineChartMedicalData
                        daId={item.daId}
                        thaiName={item.thaiName}
                        description={item.description}
                        unitThai={item.unitThai}
                      />
                    </div>
                  </>
                )
              })}
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </>
  )
}

function DiabetesPage() {
  const [medicalQueryData] = useQuery(OverviewQuery.getUserOverviewData())
  return (
    <div className="w-full">
      <HealthCard
        fetching={medicalQueryData.fetching}
        error={medicalQueryData.error}
        cardData={medicalQueryData.data?.getUser}
      />
    </div>
  )
}

export default DiabetesPage

import React from 'react'
import { Provider } from 'urql'
import { useRecoilState } from 'recoil'

import { Router } from 'router'
import { Theme } from 'theme'
import { useAuth } from 'stores/recoil'
import { urqlClient } from 'services/graphql'

function App() {
  const [token, setToken] = useRecoilState(useAuth.selector.selectorToken)

  return (
    <Theme>
      <Provider value={urqlClient(token, setToken)}>
        <Router />
      </Provider>
    </Theme>
  )
}

export default App

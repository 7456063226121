import React, { useState } from 'react'
import { Grid, Stack } from '@mui/material'
import { useQuery, useMutation } from 'urql'
import { useRecoilState } from 'recoil'
import { OverviewQuery } from 'services/graphql/overview'
import { ElementComponent, SkeletonComponent, AlertComponent } from 'components'
import { useUser } from 'stores/recoil'
import { data, date, general } from 'utils'
import * as SVG from 'assets/svg'
import * as Pics from 'assets/picture'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { SpecificDiseaseFormV2 } from 'components/form/healthForm'
import { Form, Formik } from 'formik'
import dayjs from 'dayjs'
//import { useNavigate } from 'react-router-dom'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'
import * as yup from 'yup'
import useResponsive from 'hooks/useResponsive'
import { Link } from 'react-router-dom'
import { route } from 'settings'
import { Icon } from '@iconify/react'
//const navigate = useNavigate()

function HealthCard({
  fetching,
  error,
  cardData,
}: {
  fetching: boolean
  error: any
  cardData: any
}) {
  const { isMobile } = useResponsive()
  //const navigate = useNavigate()
  const [medicalMutate, executeMedical] = useMutation(
    MedicalMutate.creatMedicalData,
  )
  const [medicalGeneralQueryData] = useQuery(
    MedicalQuery.getDisease({
      AND: [
        { type: { equals: 'GENERAL' } },
        { diseaseAttibute: { every: { inputFlag: { equals: true } } } },
      ],
    }),
  )
  const [validationForm, setValidationForm] = useState<any>({})
  const [formData, setFormData] = useState({})
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const [imageStateError, setImageStateError] = useState<boolean>(false)
  const imageOnError = () => {
    setImageStateError(true)
  }
  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })

  interface DiseaseDataSpeicific {
    disease: {
      dId: string
      thaiName: string
      status: boolean //check is pre-create
      image: string
    }
    date: Date
  }
  const dmDisease: DiseaseDataSpeicific = {
    disease: {
      dId: 'Diabetes mellitus (DM)',
      thaiName: 'เบาหวาน',
      status: false,
      image: Pics.I1,
    },
    date: new Date(),
  }
  const dyslipidemiaDisease: DiseaseDataSpeicific = {
    disease: {
      dId: 'Dyslipidemia',
      thaiName: 'ไขมันในเลือด',
      status: false,
      image: Pics.I2,
    },
    date: new Date(),
  }

  const hypertensionDisease: DiseaseDataSpeicific = {
    disease: {
      dId: 'Hypertension',
      thaiName: 'ความดัน',
      status: false,
      image: Pics.I3,
    },
    date: new Date(),
  }

  const cldDisease: DiseaseDataSpeicific = {
    disease: {
      dId: 'Chronic Liver Disease/Cirrhosis (CLD)',
      thaiName: 'ตับ',
      image: Pics.I4,
      status: false,
    },
    date: new Date(),
  }
  const ckdDisease: DiseaseDataSpeicific = {
    disease: {
      dId: 'Chronic Kidney Disease (CKD)',
      thaiName: 'ไต',
      image: Pics.I5,
      status: false,
    },
    date: new Date(),
  }

  const categorysList: {
    title: string
    imgName: string
    disease: DiseaseDataSpeicific
  }[] = [
    {
      title: 'เบาหวาน',
      imgName: Pics.CategoryIco01,
      disease: dmDisease,
    },
    {
      title: 'ไขมัน',
      imgName: Pics.CategoryIco02,
      disease: dyslipidemiaDisease,
    },
    {
      title: 'ความดัน',
      imgName: Pics.CategoryIco03,
      disease: hypertensionDisease,
    },
    {
      title: 'ตับ',
      imgName: Pics.CategoryIco04,
      disease: cldDisease,
    },
    {
      title: 'ไต',
      imgName: Pics.CategoryIco05,
      disease: ckdDisease,
    },
    /*{
      title: 'หัวใจ',
      imgName: Pics.CategoryIco06,
      disease: dmDisease,
    },
    {
      title: 'อ้วน',
      imgName: Pics.CategoryIco07,
      disease: dmDisease,
    },
    {
      title: 'กระดูก',
      imgName: Pics.CategoryIco08,
      disease: dmDisease,
    },*/
  ]
  const [diseaseSpecific, setDiseaseSpecific] = useState([dmDisease])

  const handleRemoveDiseaseSpecific = (index: number) => {
    let newDiseaseSpecific = [...diseaseSpecific]
    newDiseaseSpecific.splice(index, 1)
    setDiseaseSpecific(newDiseaseSpecific)
  }

  const handleSetDiseaseSpecific = (index: number, status: boolean) => {
    let newDiseaseSpecific = [...diseaseSpecific]
    newDiseaseSpecific[index].disease.status = status
    setDiseaseSpecific(newDiseaseSpecific)
  }

  const handleSubmit = async (values?: any) => {
    const data = Object.keys(values).flatMap((key: any) => {
      if (
        values[key].value !== '' &&
        values[key].value !== null &&
        values[key].value.length !== 0
      ) {
        let result = {
          daId: values[key].daId,
          createdAt: values[key]?.createdAt ?? dayjs().toISOString(),
        }

        if (values[key].type === 'FLOAT')
          result['value'] = Number(values[key].value)
        else if (values[key].type === 'IMAGE' && values[key].value.length > 0)
          result['file'] = values[key].value
        else if (values[key].type === 'TEXT' && values[key].value !== '')
          result['desciption'] = values[key].value

        return result
      }

      return null
    })

    await executeMedical({
      data: {
        patientUser: {
          connect: {
            uId: '',
          },
        },
        submitUser: {
          connect: {
            uId: '',
          },
        },
        dataInputFlag: true,
        userMedTrasaction: {
          createMany: {
            data: data.filter((item) => item !== null),
          },
        },
      },
    })

    if (medicalMutate.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งผลเลือดไม่สำเร็จ',
        description: 'กรุณาลองใหม่อีกครั้ง',
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }

    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งผลเลือดสำเร็จ',
      description: 'กรุณารอวิเคราะห์ผลสักครู่',
      onClose: () => {
        general.http.goto('/overview')
      },
    })
  }
  const formatUnderlyingDisease = (
    data: {
      underlyingDisease: {
        thaiName: string
        engName: string
      }
    }[],
  ) => {
    let formatString = ''
    data.forEach((item, index) => {
      if (index === data.length - 1)
        formatString += `${item.underlyingDisease.thaiName}`
      else formatString += `${item.underlyingDisease.thaiName},`
    })
    return formatString
  }
  return (
    <>
      <Stack
        direction={{ mobile: 'column', laptop: 'column' }}
        className="gap-[18px] "
        alignItems={'top'}
      >
        <Stack
          direction={{ mobile: 'column', laptop: 'row' }}
          className="gap-[18px]"
          alignItems={'top'}
        >
          {isMobile ? (
            <div className="w-[100%] laptop:w-[50%]  bg-cover">
              <Stack
                direction={{ mobile: 'column', laptop: 'column' }}
                alignItems={'top'}
                className="gap-[18px] items-center"
                style={{
                  borderColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  padding: '25px',
                  backgroundColor: '#FFF',
                }}
              >
                <div>
                  <Stack
                    direction={{ mobile: 'column', laptop: 'column' }}
                    className="gap-[18px] items-center"
                  >
                    <div className="flex items-center gap-[10px]">
                      {!imageStateError && user['imagePath'] ? (
                        <div>
                          <ElementComponent.Image
                            fullImage={false}
                            className="h-[30px] w-[30px] rounded-full bg-cover bg-center"
                            src={user['imagePath']}
                            onError={imageOnError}
                          />
                        </div>
                      ) : (
                        <SVG.AvatarProfile className="h-[50px] w-[50px] rounded-full bg-cover bg-center" />
                      )}
                      <Stack direction={{ mobile: 'column', laptop: 'column' }}>
                        <h5 style={{ color: '#0D5CDD' }}>
                          {user['firstname']}
                        </h5>
                        <div
                          style={{
                            padding: '2px 12px',
                            backgroundColor: '#0D5CDD',
                            borderRadius: '15px',
                            borderWidth: '1px',
                          }}
                        >
                          <h5 style={{ color: '#FFF' }}>
                            HN {data.formatId(user['hnId'].toString())}
                          </h5>
                        </div>
                      </Stack>
                    </div>
                  </Stack>
                </div>
                <Grid className="mt-[16px] w-[100%]" item mobile={1}>
                  {!fetching && !error ? (
                    <div className="flex flex-col text-[13px] laptop:text-[14px] text-black-main">
                      <Grid container>
                        <Grid item mobile={5}>
                          <p>
                            <b>ชื่อ :</b>
                          </p>
                        </Grid>
                        <Grid item>
                          <p>{user['firstname']}</p>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: '5px' }}>
                        <Grid item mobile={5}>
                          <p>
                            <b>อายุ :</b>
                          </p>
                        </Grid>
                        <Grid item>
                          <p>
                            {date.getAge(
                              cardData.UserMedicalDemograhicData.birthdate,
                            )}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: '5px' }}>
                        <Grid item mobile={5}>
                          <p>
                            <b>เกิด :</b>
                          </p>
                        </Grid>
                        <Grid item>
                          <p>
                            {date.convertToShowDateThai(
                              cardData.UserMedicalDemograhicData.birthdate,
                            )}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item mobile={5}>
                          <p>
                            <b>เพศ :</b>
                          </p>
                        </Grid>
                        <Grid item>
                          <p>{cardData.UserMedicalDemograhicData.gender}</p>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: '5px' }}>
                        <Grid item mobile={5}>
                          <p>
                            <b>ภูมิลำเนา:</b>
                          </p>
                        </Grid>
                        <Grid item>
                          <p>{cardData.UserMedicalDemograhicData.province}</p>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: '5px' }}>
                        <Grid item mobile={5}>
                          <p>
                            <b>แพ้ยา/อาหาร :</b>
                          </p>
                        </Grid>
                        <Grid item>
                          <p>
                            {cardData.UserMedicalDemograhicData.allergic !== ''
                              ? cardData.UserMedicalDemograhicData.allergic
                              : 'ไม่มี'}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: '5px' }}>
                        <Grid item mobile={5}>
                          <div
                            className="w-[110px] laptop:w-[130px] "
                            style={{
                              padding: '2px 12px',
                              backgroundColor: '#0D5CDD',
                              borderRadius: '15px',
                              borderWidth: '1px',
                            }}
                          >
                            <p style={{ color: '#FFF' }}>ประจำตัว :</p>
                          </div>
                        </Grid>
                        <Grid item mobile={5}>
                          <p>
                            {formatUnderlyingDisease(
                              cardData.UserMedicalDemograhicData
                                .userUnderlyingDiseaseTrasaction,
                            ) !== ''
                              ? formatUnderlyingDisease(
                                  cardData.UserMedicalDemograhicData
                                    .userUnderlyingDiseaseTrasaction,
                                )
                              : 'ไม่มี'}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: '5px' }}>
                        <Grid item mobile={5}>
                          <div
                            className="w-[110px] laptop:w-[130px] "
                            style={{
                              padding: '2px 12px',
                              backgroundColor: '#0D5CDD',
                              borderRadius: '15px',
                              borderWidth: '1px',
                            }}
                          >
                            <p
                              className="text-[12px] laptop:text-[14px]"
                              style={{ color: '#FFF' }}
                            >
                              หน่วยงานที่ดูแล :
                            </p>
                          </div>
                        </Grid>
                        <Grid item mobile={5}>
                          <p>โรงพยาบาลราชพิพัณน์</p>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: '5px' }}>
                        <Grid item mobile={5}>
                          <div
                            className="w-[110px] laptop:w-[130px] "
                            style={{
                              padding: '2px 12px',
                              backgroundColor: '#0D5CDD',
                              borderRadius: '15px',
                              borderWidth: '1px',
                            }}
                          >
                            <p style={{ color: '#FFF' }}>แพทย์ประจำตัว :</p>
                          </div>
                        </Grid>
                        <Grid item mobile={5}>
                          <p>นพ. ธนกร ยินต์นิยม</p>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <SkeletonComponent.CardProfileSkeleton />
                  )}
                </Grid>
              </Stack>
            </div>
          ) : (
            <Link to={route.OVERVIEW_PAGE_URL}>
              <div
                className="mt-[-15px] w-[130px] items-center"
                style={{
                  padding: '2px 12px',
                  backgroundColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  float: 'right',
                }}
              >
                <h5 style={{ color: '#FFF' }}>กลับหน้าประวัติ</h5>
              </div>
            </Link>
          )}
          <div className="w-[100%] laptop:w-[40%]  bg-cover ">
            {isMobile && (
              <Stack
                direction={{ mobile: 'row', laptop: 'row' }}
                className="gap-[18px] items-center"
                style={{
                  borderColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                  backgroundColor: '#FFF',
                  padding: '25px',
                }}
              >
                <div id="howto" className="w-[80%]  items-center">
                  <ElementComponent.Image
                    fullImage={true}
                    src={Pics.BtnAddPic}
                    disableClick
                    className="w-full h-full cursor-default"
                  />
                </div>
                <div className="w-[80%] items-center">
                  <ElementComponent.Image
                    fullImage={true}
                    disableClick
                    src={Pics.BtnBloodInput}
                    className=" w-full h-full cursor-default"
                  />
                </div>
              </Stack>
            )}
            <Stack
              direction={{ mobile: 'column', laptop: 'column' }}
              alignItems={'top'}
              className="gap-[18px] items-center mt-[36px]"
              style={{
                borderColor: '#0D5CDD',
                borderRadius: '15px',
                borderWidth: '1px',
                backgroundColor: '#FFF',
              }}
            >
              <div
                className="mt-[-15px]"
                style={{
                  padding: '2px 12px',
                  backgroundColor: '#0D5CDD',
                  borderRadius: '15px',
                  borderWidth: '1px',
                }}
              >
                <h5 style={{ color: '#FFF' }}>เลือกเพื่อเพิ่มผลเลือด</h5>
              </div>
              <div>
                <Swiper
                  className="w-[300px] laptop:w-[400px]  bg-cover"
                  slidesPerView={4}
                  spaceBetween={1}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Navigation, Pagination]}
                >
                  {categorysList.map(
                    (item: {
                      title: string
                      imgName: string
                      disease: DiseaseDataSpeicific
                    }) => (
                      <SwiperSlide
                        className="mb-[18px]"
                        onClick={() =>
                          setDiseaseSpecific([...diseaseSpecific, item.disease])
                        }
                      >
                        <ElementComponent.Image
                          fullImage={false}
                          src={item.imgName}
                          className="h-[90px]"
                        />
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
              </div>
            </Stack>
          </div>
        </Stack>
      </Stack>
      <Stack className="gap-[18px] ">
        <>
          <Formik
            initialValues={formData}
            enableReinitialize={true}
            validationSchema={validationForm}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Grid
                  container
                  className="w-[100%]"
                  columns={{ mobile: 2.2, desktop: 2 }}
                  gap="8px"
                >
                  {diseaseSpecific.length >= 1 ? (
                    <Grid item mobile={2.2} desktop={1}>
                      {diseaseSpecific.map((value, index: number) =>
                        value.disease.status == false ? (
                          <Stack
                            direction={{ mobile: 'column', laptop: 'column' }}
                            alignItems={'top'}
                            className="gap-[18px] items-center mt-[36px]"
                            style={{
                              borderColor: '#0D5CDD',
                              borderRadius: '15px',
                              borderWidth: '1px',
                              backgroundColor: '#FFF',
                            }}
                          >
                            <div
                              className=" mr-[-85%] mt-[-15px]"
                              style={{
                                padding: '2px',
                                backgroundColor: '#0D5CDD',
                                borderRadius: '15px',
                                borderWidth: '1px',
                              }}
                            >
                              <Icon
                                icon="fluent-mdl2:status-circle-error-x"
                                className="text-[25px] cursor-pointer"
                                style={{ color: '#FFF' }}
                                onClick={() =>
                                  handleRemoveDiseaseSpecific(index)
                                }
                              />
                            </div>
                            <Stack
                              direction={{ mobile: 'row', laptop: 'row' }}
                              className="mt-[-50px]"
                            >
                              {/*<SVG.IconDiabete className="w-[48px] h-[48px] mt-[-8px] mr-[-18px] z-[1]" />*/}
                              <div className="flex justify-center flex-row -mt-2">
                                <img
                                  src={value.disease.image}
                                  className="w-12 h-12 -mr-3.5 z-10 bg-white-main border-transparent rounded-3xl"
                                  alt={`icon-${value.disease.dId}`}
                                />
                                <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
                                  <h5 className="text-white-main mx-3.5">
                                    {value.disease.thaiName}
                                  </h5>
                                </div>
                              </div>
                            </Stack>

                            <SpecificDiseaseFormV2
                              key={`diseaseSpecific-form-${index}`}
                              loading={medicalMutate.fetching}
                              data={value}
                              formData={formik}
                              onRemove={() =>
                                handleRemoveDiseaseSpecific(index)
                              }
                              onSetFormData={(newField: any) => {
                                setFormData({ ...formik.values, ...newField })
                              }}
                              onSetValidateForm={(newSchema: any) =>
                                setValidationForm(
                                  yup.object().shape({
                                    ...validationForm.fields,
                                    ...newSchema,
                                  }),
                                )
                              }
                            />
                            <div
                              className=" m-[8px] flex 
            justify-end "
                            >
                              <ElementComponent.Button
                                id="submit-health-form"
                                style="w-full "
                                text="ส่งผลเลือด"
                                width="100%"
                                onAction={() =>
                                  handleSetDiseaseSpecific(index, true)
                                }
                              />
                            </div>
                          </Stack>
                        ) : (
                          <Grid item mobile={2.2} desktop={1}>
                            <Stack
                              direction={{ mobile: 'row', laptop: 'row' }}
                              alignItems={'top'}
                              className="gap-[4px]  mt-[28px] p-[8px] text-[14px]"
                              style={{
                                borderColor: '#0D5CDD',
                                borderRadius: '15px',
                                borderWidth: '1px',
                                backgroundColor: '#FFF',
                              }}
                            >
                              <div className="flex flex-row">
                                <img
                                  src={value.disease.image}
                                  className="w-12 h-12 -mr-3.5 z-10 bg-white-main border-transparent rounded-3xl"
                                />
                                <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
                                  <h5 className="text-white-main  ml-[14px]">
                                    {value.disease.thaiName}
                                  </h5>
                                </div>
                                <div className="h-8 z-0 p-1 mt-2 -ml-5  border border-blue-main  rounded-xl">
                                  <h5 className="text-blue-main ml-[20px]">
                                    {`${dayjs(value.date).format(
                                      'DD MMMM BBBB',
                                    )}`}
                                  </h5>
                                </div>
                              </div>
                              <div className="flex flex-row mt-[8px]">
                                <div
                                  onClick={() =>
                                    handleSetDiseaseSpecific(index, false)
                                  }
                                >
                                  <SVG.BtnPreEdit className="w-[26px] h-[26px]  " />
                                </div>
                                <div
                                  onClick={() =>
                                    handleRemoveDiseaseSpecific(index)
                                  }
                                >
                                  <SVG.BtnPreDel className="w-[26px] h-[26px] ml-[4px]" />
                                </div>
                                <SVG.BtnPreSuccess className="w-[26px] h-[26px] ml-[4px]" />
                              </div>
                            </Stack>
                          </Grid>
                        ),
                      )}
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                {diseaseSpecific.length >= 1 && (
                  <Stack
                    direction={{ mobile: 'column', laptop: 'column' }}
                    className="items-center pl-[38%] "
                  >
                    <ElementComponent.ButtonCustom
                      id="submit-health-form"
                      style="w-full m-[16px]"
                      backgroundColor="#06C755"
                      submit
                      loading={
                        medicalMutate.fetching ||
                        medicalGeneralQueryData.fetching
                      }
                      text="เสร็จสิ้น"
                      width="40%"
                    />
                  </Stack>
                )}
              </Form>
            )}
          </Formik>
        </>
        {!isMobile && (
          <Stack
            direction={{ mobile: 'row', laptop: 'row' }}
            className="gap-[18px] items-center"
            style={{
              borderColor: '#0D5CDD',
              borderRadius: '15px',
              borderWidth: '1px',
              backgroundColor: '#FFF',
              padding: '25px',
            }}
          >
            <div id="howto" className="w-[80%]  items-center">
              <ElementComponent.Image
                fullImage={true}
                src={Pics.BtnAddPic}
                disableClick
                className="w-full h-full cursor-default"
              />
            </div>
            <div className="w-[80%] items-center">
              <ElementComponent.Image
                fullImage={true}
                disableClick
                src={Pics.BtnBloodInput}
                className=" w-full h-full cursor-default"
              />
            </div>
          </Stack>
        )}
      </Stack>
      {submitPopupData.type === 'success' ? (
        <AlertComponent.SuccessModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      ) : (
        <AlertComponent.ErrorModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      )}
    </>
  )
}

function CreateHealthSectionV2() {
  const [medicalQueryData] = useQuery(OverviewQuery.getUserOverviewData())

  return (
    <div className="w-full">
      <HealthCard
        fetching={medicalQueryData.fetching}
        error={medicalQueryData.error}
        cardData={medicalQueryData.data?.getUser}
      />
    </div>
  )
}

export default CreateHealthSectionV2

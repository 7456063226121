import isObject from 'lodash/isObject'

const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0]
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey])
  }
  return [...keys, firstErrorKey].join('.')
}

export { getFirstErrorKey }

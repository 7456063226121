import React from 'react'

import { Stack } from '@mui/material'
import { ChartCompoent } from 'components'

interface INormalDiseaseCardProps {
  diseaseData: {
    imagePath: string
    medName: string
    thaiName: string
    diseaseAttibute: {
      daId: string
      thaiName: string
      description: string
      unitThai: string
      showFlag: boolean
    }[]
  }
}

function NormalDiseaseCard({ diseaseData }: INormalDiseaseCardProps) {
  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'row' }}
      alignItems={'top'}
      className="gap-5 mt-9 w-full laptop:w-[92%] p-3 laptop:p-[25px] border border-blue-main rounded-xl bg-white-main"
    >
      <div className="flex justify-center flex-row -mt-9">
        <img
          src={diseaseData.imagePath}
          className="w-12 h-12 -mr-3.5 z-10 bg-white-main border-transparent rounded-3xl"
          alt={`icon-${diseaseData.medName}`}
        />
        <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
          <h5 className="text-white-main mx-3.5">{diseaseData.thaiName}</h5>
        </div>
      </div>
      {/* Disease attribute */}
      {diseaseData.diseaseAttibute.map((item) => {
        return (
          <>
            {item.showFlag && (
              <div className="gap-[18px] w-[100%] laptop:w-[50%]">
                <ChartCompoent.LineChartMedicalData
                  daId={item.daId}
                  thaiName={item.thaiName}
                  description={item.description}
                  unitThai={item.unitThai}
                />
              </div>
            )}
          </>
        )
      })}
    </Stack>
  )
}

export default NormalDiseaseCard

import React, { useEffect, useState } from 'react'
import { Menu, MenuItem, Skeleton, Stack } from '@mui/material'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { useMutation } from 'urql'
import { Icon } from '@iconify/react'

import { NavbarType } from 'types'
import { AuthMutate } from 'services/graphql/authService'
import { useUser } from 'stores/recoil'
import { LogoSVG, AvatarProfile } from 'assets/svg'
import { general } from 'utils'
import { appSettings, route } from 'settings'
import { useRecoilState } from 'recoil'
import { ElementComponent } from 'components'

interface MenuItemProps {
  path: string
  text: string
  displayCondition?: boolean
  active: boolean
}

interface UserMenuListProps {
  open: boolean
  anchorEl: HTMLElement | null
  onCloseMenu: Function
}

const menuList: NavbarType.menuListType[] = [
  {
    text: 'สมุดสุขภาพ',
    path: route.OVERVIEW_PAGE_URL,
    active: true,
    auth: true,
    spacificCheck: true,
  },
  {
    text: 'วางแผนสุขภาพ',
    path: route.OVERVIEW_PAGE_URL,
    active: true,
    auth: true,
    spacificCheck: true,
  },
  {
    text: 'ชุมชนใกล้หมอ',
    path: '/?section=healthmonitoring',
    active: true,
    auth: true,
    spacificCheck: true,
  },
  {
    text: 'วิธีใช้',
    path: '/?section=howto',
    active: true,
    auth: true,
    spacificCheck: true,
  },
  /*{ text: 'เข้าสู่ระบบ', path: route.LOGIN_PAGE_URL, active: true },
  { text: 'สมัครสมาชิก', path: route.REGISTER_PAGE_URL, active: true },*/
]

function ListItem({
  path,
  text,
  active,
  displayCondition = true,
}: MenuItemProps) {
  if (active) {
    return (
      <NavLink
        to={path}
        className={({ isActive }) =>
          isActive && displayCondition
            ? 'text-blue-main underline underline-offset-8 transition-all ease-in-out'
            : 'transition-all ease-in-out text-black-main'
        }
      >
        {text}
      </NavLink>
    )
  }

  return (
    <Link to={path} className="transition-all ease-in-out text-black-main">
      {text}
    </Link>
  )
}

function UserMenuList({ open, anchorEl, onCloseMenu }: UserMenuListProps) {
  const [, executeMutation] = useMutation(AuthMutate.logout)

  const userMenuList: { title: string; path: string; icon: string }[] = [
    {
      title: 'สมุดสุขภาพ',
      path: route.OVERVIEW_PAGE_URL,
      icon: 'system-uicons:document-justified',
    },
    {
      title: 'ชุมชนใกล้หมอ',
      path: '',
      icon: 'ri:shield-cross-line',
    },
    {
      title: 'วิธีใช้',
      path: '',
      icon: 'ri:shield-cross-line',
    },
    {
      title: 'บัญชีของฉัน',
      path: route.PROFILE_PAGE_URL,
      icon: 'ic:baseline-person',
    },
  ]

  const logoutUser = async () => {
    await executeMutation({
      data: {
        uId: '',
        refresh_token: general.cookie.getCookie(appSettings.TOKEN_KEY),
      },
    })
    general.auth.logout()
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={() => onCloseMenu()}
      className="mt-[10px]"
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {userMenuList.map(
        (
          item: { title: string; path: string; icon: string },
          index: number,
        ) => (
          <NavLink
            key={`user-menu-${index}`}
            className={({ isActive }) =>
              isActive
                ? 'text-blue-main transition-all ease-in-out'
                : 'transition-all ease-in-out text-black-main'
            }
            to={item.path}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <MenuItem onClick={() => onCloseMenu()} sx={{ width: '100%' }}>
              <Icon icon={item.icon} className="text-[25px] mr-[10px]" />
              <p>{item.title}</p>
            </MenuItem>
          </NavLink>
        ),
      )}

      <MenuItem onClick={logoutUser}>
        <div className="flex items-center gap-[10px]">
          <Icon
            icon="material-symbols:logout-rounded"
            className="text-[25px]"
          />
          <p>ออกจากระบบ</p>
        </div>
      </MenuItem>
    </Menu>
  )
}

function NavbarDesktop() {
  const location = useLocation()
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const [imageStateError, setImageStateError] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const imageOnError = () => {
    setImageStateError(true)
  }

  useEffect(() => {
    if (user['imagePath']) setImageStateError(false)
  }, [user])

  return (
    <div
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, .0)' }}
      className="fixed top-0 left-0 z-30 w-full h-[96px] px-[31px] bg-white-main flex justify-between items-center"
    >
      <Link to="/">
        <LogoSVG className="w-[86px] h-[86px]" />
      </Link>
      <Stack direction={{ laptop: 'column' }} alignItems={'end'}>
        {general.auth.isAuthorized() &&
          (user['firstname'] ? (
            <div className="flex items-center gap-[10px]">
              <h1>{user['firstname']}</h1>
              {!imageStateError && user['imagePath'] ? (
                <div>
                  <ElementComponent.Image
                    fullImage={false}
                    className="h-[30px] w-[30px] rounded-full bg-cover bg-center"
                    src={user['imagePath']}
                    onError={imageOnError}
                  />
                </div>
              ) : (
                <AvatarProfile className="h-[30px] w-[30px] rounded-full bg-cover bg-center" />
              )}
              <div
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  setAnchorEl(event.currentTarget)
                }
              >
                <div
                  className="flex items-center gap-[0px] pl-[14px] pr-[4px] ml-[8px] cursor-pointer"
                  style={{
                    borderColor: '#01A6E3',
                    borderWidth: '2px',
                    borderRadius: '15px',
                  }}
                >
                  <p>เมนู</p>
                  <Icon
                    icon="ic:baseline-keyboard-arrow-down"
                    className="text-[20px] cursor-pointer"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-[10px]">
              <div className="flex flex-row gap-2">
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton variant="text" width={80} height={20} />
              </div>
            </div>
          ))}
        {general.auth.isAuthorized() == false ? (
          <Stack
            direction={{ laptop: 'row' }}
            className="flex items-center gap-[30px] mt-[16px]"
          >
            <div className="flex items-center gap-[10px] cursor-pointer">
              <Link to={route.REGISTER_PAGE_URL}>
                <p
                  className="text-[16px] pl-[8px] pr-[8px]"
                  style={{
                    backgroundColor: 'red',
                    borderRadius: 15,
                    color: 'white',
                  }}
                >
                  สมัครสมาชิก
                </p>
              </Link>
            </div>
            <div>
              <Link
                to={route.LOGIN_PAGE_URL}
                className="flex items-center gap-[10px] cursor-pointer"
              >
                <p className="text-[16px]">เข้าสู่ระบบ</p>
                <Icon icon="bi:person-circle" className="text-[30px]" />
              </Link>
            </div>
          </Stack>
        ) : (
          <></>
        )}

        <div className="flex items-center gap-[30px] mt-[16px]">
          {menuList.map((item: NavbarType.menuListType, index: number) => {
            if (general.auth.isAuthorized()) {
              if (item.auth) {
                return (
                  <ListItem
                    key={`menu-list-${index}`}
                    path={item.path}
                    text={item.text}
                    active={item.active}
                    displayCondition={
                      item.spacificCheck
                        ? item.path === `/${location.search}`
                        : true
                    }
                  />
                )
              } else return null
            }

            return (
              <ListItem
                key={`menu-list-${index}`}
                path={item.path}
                text={item.text}
                active={item.active}
                displayCondition={
                  item.spacificCheck
                    ? item.path === `/${location.search}`
                    : true
                }
              />
            )
          })}

          <UserMenuList
            open={open}
            anchorEl={anchorEl}
            onCloseMenu={() => setAnchorEl(null)}
          />

          <div className="flex items-center gap-[10px]">
            <Icon icon="emojione:flag-for-thailand" className="text-[24px]" />
            <p>ภาษาไทย</p>
            <Icon
              icon="ic:baseline-keyboard-arrow-down"
              className="text-[20px] cursor-pointer"
            />
          </div>
        </div>
      </Stack>
    </div>
  )
}

export default NavbarDesktop
